.cursor-pointer {
  cursor: pointer;
}
.word-wrap {
  white-space: normal !important;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}
.overflow-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fixed { position: fixed; }
.hidden { display: none !important; }
.hide { display: none; }
a.external:after {
  display: inline-block;
  text-decoration: none;
  margin-left: 2px;
  content: "*";
}
tr.inactive {
  color: #666;
  a { color: #666; }
}

// FLEXBOX
.flex { display: flex; }
.inline-flex { display: inline-flex; }

.flex-column  { flex-direction: column; }
.flex-row     { flex-direction: row; }

.items-start    { align-items: flex-start; }
.items-end      { align-items: flex-end; }
.items-center   { align-items: center; }
.items-baseline { align-items: baseline; }
.items-stretch  { align-items: stretch; }

.justify-start   { justify-content: flex-start; }
.justify-end     { justify-content: flex-end; }
.justify-center  { justify-content: center; }
.justify-between { justify-content: space-between; }
.justify-around  { justify-content: space-around; }

.flex-grow   { flex-grow: 1; }

// SPACING
:root {
  --spacing-none: 0;
  --spacing-extra-small: 4px;
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 24px;
  --spacing-extra-large: 32px;
}
.pa0 { padding: var(--spacing-none); }
.pa1 { padding: var(--spacing-extra-small); }
.pa2 { padding: var(--spacing-small); }
.pa3 { padding: var(--spacing-medium); }
.pa4 { padding: var(--spacing-large); }
.pa5 { padding: var(--spacing-extra-large); }

.pl0 { padding-left: var(--spacing-none); }
.pl1 { padding-left: var(--spacing-extra-small); }
.pl2 { padding-left: var(--spacing-small); }
.pl3 { padding-left: var(--spacing-medium); }
.pl4 { padding-left: var(--spacing-large); }
.pl5 { padding-left: var(--spacing-extra-large); }

.pr0 { padding-right: var(--spacing-none); }
.pr1 { padding-right: var(--spacing-extra-small); }
.pr2 { padding-right: var(--spacing-small); }
.pr3 { padding-right: var(--spacing-medium); }
.pr4 { padding-right: var(--spacing-large); }
.pr5 { padding-right: var(--spacing-extra-large); }

.pb0 { padding-bottom: var(--spacing-none); }
.pb1 { padding-bottom: var(--spacing-extra-small); }
.pb2 { padding-bottom: var(--spacing-small); }
.pb3 { padding-bottom: var(--spacing-medium); }
.pb4 { padding-bottom: var(--spacing-large); }
.pb5 { padding-bottom: var(--spacing-extra-large); }

.pt0 { padding-top: var(--spacing-none); }
.pt1 { padding-top: var(--spacing-extra-small); }
.pt2 { padding-top: var(--spacing-small); }
.pt3 { padding-top: var(--spacing-medium); }
.pt4 { padding-top: var(--spacing-large); }
.pt5 { padding-top: var(--spacing-extra-large); }

.ph-m {
  padding-left: 12px;
  padding-right: 12px;
}

.ma0 {  margin: var(--spacing-none); }
.ma1 {  margin: var(--spacing-extra-small); }
.ma2 {  margin: var(--spacing-small); }
.ma3 {  margin: var(--spacing-medium); }
.ma4 {  margin: var(--spacing-large); }
.ma5 {  margin: var(--spacing-extra-large); }

.ml0 {  margin-left: var(--spacing-none); }
.ml1 {  margin-left: var(--spacing-extra-small); }
.ml2 {  margin-left: var(--spacing-small); }
.ml3 {  margin-left: var(--spacing-medium); }
.ml4 {  margin-left: var(--spacing-large); }
.ml5 {  margin-left: var(--spacing-extra-large); }

.mr0 {  margin-right: var(--spacing-none); }
.mr1 {  margin-right: var(--spacing-extra-small); }
.mr2 {  margin-right: var(--spacing-small); }
.mr3 {  margin-right: var(--spacing-medium); }
.mr4 {  margin-right: var(--spacing-large); }
.mr5 {  margin-right: var(--spacing-extra-large); }

.mb0 {  margin-bottom: var(--spacing-none); }
.mb1 {  margin-bottom: var(--spacing-extra-small); }
.mb2 {  margin-bottom: var(--spacing-small); }
.mb3 {  margin-bottom: var(--spacing-medium); }
.mb4 {  margin-bottom: var(--spacing-large); }
.mb5 {  margin-bottom: var(--spacing-extra-large); }

.mt0 {  margin-top: var(--spacing-none); }
.mt1 {  margin-top: var(--spacing-extra-small); }
.mt2 {  margin-top: var(--spacing-small); }
.mt3 {  margin-top: var(--spacing-medium); }
.mt4 {  margin-top: var(--spacing-large); }
.mt5 {  margin-top: var(--spacing-extra-large); }

// FONT SIZE
.f1 { font-size: 3rem; }
.f2 { font-size: 2.25rem; }
.f3 { font-size: 1.5rem; }
.f4 { font-size: 1.25rem; }
.f5 { font-size: 1rem; }
.f6 { font-size: .875rem; }
.f7 { font-size: .75rem; }

// FONT WEIGHT
.fw3    { font-weight: 300; }
.fw4    { font-weight: 400; }
.fw5    { font-weight: 500; }
.fw6    { font-weight: 600; }

// TEXT ALIGN
.tl  { text-align: left; }
.tr  { text-align: right; }
.tc  { text-align: center; }
.tj  { text-align: justify; }
// WHITE SPACE
.nowrap { white-space: nowrap; }
// BORDER
.br1 { border-radius: .125rem; }
// WIDTH
.w-25 {  width:  25%; }
.w-30 {  width:  30%; }
.w-33 {  width:  33%; }
.w-34 {  width:  34%; }
.w-40 {  width:  50%; }
.w-50 {  width:  50%; }
.w-60 {  width:  60%; }
.w-70 {  width:  60%; }
.w-100 {  width:  100%; }
// pixels
.w32 { width: 32px; }
.w48 { width: 48px; }
.w640 { width: 640px !important; }

/* Height Scale */
.h1 { height: 1rem; }
.h2 { height: 2rem; }
.h3 { height: 4rem; }
.h4 { height: 8rem; }
.h5 { height: 16rem; }

// flex align middle
.flex-middle {
  height: 64px;
  padding: 0 8px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.flex-middle-slim {
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.flex-middle-spaced {
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.flex-top-spaced {
  vertical-align: top;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-between;
}

.rotate-90 {
  transform: rotate(90deg);
}

.display-ib {
  display: inline-block;
}

// TEXT TRANSFORM
.ttc { text-transform: capitalize; }
.ttu { text-transform: uppercase; }
