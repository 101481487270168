table.crm-table {
  border: none;
  width: 100%;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  tbody tr:hover {
    background-color: #f6f6f6;
  }
  tbody.border {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  td, th {
    border: none;
    padding: 16px 16px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;
  }
  th {
    color: #606060;
    font-weight: 500;
    font-size: 12px;
  }
  td {
    color: #202020;
    font-size: 14px;
  }
  .r-border {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }
  td.n-cell, th.n-cell {
    text-align: right;
  }
  td.i-cell {
    padding-top: 0;
    padding-bottom: 0;
  }
  tr.inactive td {
    color: #666;
  }
  tr.separator {
    border-bottom: 2px solid rgba(0, 0, 0, 0.22);
    th, td { padding: 12px 16px 4px; }
  }
}
.crm-no-border-at-end > tr:last-child{
  border-bottom: none;
}
.crm-card--header {
  height: 64px;
  padding: 0 16px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.crm-card--header-small {
  height: 56px;
}
.crm-card--header-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
}
.crm-card--content {
  padding: 0 16px;
  margin-bottom: 8px;
}
.crm-table--footer {
  height: 64px;
  padding: 0 16px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.crm-table--footer-nav {
  margin-left: 10px;
  a, a:visited {
    color: #202020;
    text-decoration: none;
  }
}
.crm-table--footer-meta {
  font-size: .85rem;
  box-sizing: border-box;
  display: flex;
  vertical-align: middle;
  align-items: center;
  color: #666;
  margin-left: 30px;
}
.crm-table--page-numbers {
  padding-right: 44px;
}

table.crm-table--dense {
  td, th {
    border: none;
    padding: 0;
    border-radius: 0;
  }
  td {
    padding: 4px 2px;
    .material-icons {
      font-size: 20px;
    }
    a {
      display: block;
      text-align: center;
      padding: 2px 0;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 12px;
      color: #000;
      &.crm-partial--edit-lang {
        color: #000;
        font-weight: 600;
      }
      &.crm-partial--add-lang {
        color: #707070;
        font-weight: normal;
      }
      &.crm-partial--add-product {
        color: #999;
      }
    }
  }
  .r-border {
    padding-left: 16px;
  }
  tbody a:hover {
    background-color: #e6e6e6;
  }
}

table.crm-table--issue {
  border: none;
  width: 100%;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  td, th {
    border: none;
    display: table-cell;
    text-align: left;
    padding: 8px;
    vertical-align: middle;
    border-radius: 0;
  }
  th {
    color: #606060;
    font-weight: 500;
    font-size: 12px;
  }
  td {
    color: #202020;
    font-size: 14px;
    .material-icons {
      font-size: 20px;
    }
  }
}
// ROTATED TABLE HEADERS
th.crm-rotated-cell {
  height: 100px;

  white-space: nowrap;
  // Firefox needs the extra DIV for some reason, otherwise the text disappears if you rotate
  > div {
    transform:
      // Magic Numbers
      translate(23px, 29px)
      // 45 is really 360-45
      rotate(315deg);
    width: 43px;
  }
  > div > span {
    border-bottom: 1px solid #c0c0c0;
    padding: 5px 4px;
  }
}
thead.crm-table-fixed--head {
  height: 100px;
  display: block;
  padding-left: 272px;
  border-bottom: 2px solid #c0c0c0;
  td {
    height: 100px;
    width: 40px;
  }
}
tbody.crm-table-fixed--body {
  display: block;
  overflow-y: auto;
  height: calc(100vh - 195px);
  td {
    width: 38px;
    border-right: 1px solid rgba(0, 0, 0, .12);
  }
  td.crm-table-fixed--first-column {
    width: 250px;
  }
}
