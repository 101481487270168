.crm-chip-category-ro {
  letter-spacing: -0.025rem;
  white-space: nowrap;
  font-size: 0.7rem;
  height: 1rem;
  line-height: 1rem;
  padding: 2px 6px;
  margin: 2px;
  border-radius: 10px;
  background-color: #e0e0e0;
}
.crm-category-grid {
  width: 540px;
  display: grid;
  row-gap: 0.25rem;
  column-gap: 0.5rem;
  grid-template-rows: repeat(9, 1fr);
  grid-auto-flow: column;
}
.crm-category-grid-cell {
  width: auto;
}
.crm-menu-anchor--top-right {
  position: absolute;
  top: 48px;
  right: 0px;
}
.crm-notification-action-menu {
  width: 400px;
  max-height: 600px !important;
}
.crm-error-section {
  background-color: $mdc-theme-secondary;
  color: $mdc-theme-on-secondary;
  padding: 8px 14px;
  border-radius: 2px;
  a, button { color: $mdc-theme-on-secondary; }
}
.crm-notice-section {
  background-color: $mdc-theme-on-primary;
  color: $mdc-theme-on-secondary;
  padding: 8px 14px;
  border-radius: 2px;
  a, button { color: $mdc-theme-on-secondary; }
}

.crm-icon-title {
  height: 32px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
}

.crm-drawer {
  width: $crm-drawer-width-closed;
  position: fixed;
  z-index: 3;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-transition: width 200ms ease-in-out;
  -moz-transition: width 200ms ease-in-out;
  -o-transition: width 200ms ease-in-out;
  transition: width 200ms ease-in-out;
  .mdc-deprecated-list-item__graphic {
    margin-right: 18px;
  }
}
.crm-drawer:hover {
  width: $crm-drawer-width-open;
  overflow-y: auto;
}
.crm-drawer-adjust {
  margin-left: $crm-drawer-width-closed;
}
.crm-top-bar-title {
  font-size: 1.25rem;
  font-weight: 300;
  max-width: 600px;
  max-width: 55vw;
  margin-right: 48px;
}
.crm-tab-bar {
  margin-bottom: 8px;
  .mdc-tab-indicator__content--underline {
    height: 3px;
  }
}
.crm-main {
  background-color: whitesmoke;
  min-height: 800px;
  min-height: calc(100vh - 48px);
}
.crm-red {
  color: $crm-color-alert !important;
}
.crm-grey {
  color: $crm-color-grey !important;
}
.crm-green {
  color: $crm-color-green !important;
}
.crm-student-not-active {
  background-color: #e8e8e8;
}
.crm-menu-count {
  display: inline-block;
  padding-left: 10px;
}

.crm-help--title {
  margin: 0px 0 8px;
  padding-bottom: 2px;
  padding-top: 8px;
  border-bottom: 1px solid #999;
}
.crm-modal-drawer {
  padding: 18px 24px;
  width: 480px;
  left: initial;
  right: 0;
  border-right-width: 0;
  border-left-width: 1px;
  border-right-style: none;
  border-left-style: solid;
  &.mdc-drawer--animate {
    transform: translateX(100%);
  }
  &.mdc-drawer--opening {
    transition-duration: 350ms;
    transform: translateX(0);
  }
  &.mdc-drawer--closing {
    transition-duration: 300ms;
    transform: translateX(100%);
  }
}
.crm-notification--footer {
  padding-left: 30px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.crm-notification--list {
  .mdc-deprecated-list-item {
    font-size: .8rem;
    padding: 0 12px 0 6px;
  }
  .mdc-deprecated-list-item__graphic {
    margin-right: 4px !important;
  }
  .mdc-deprecated-list-item__secondary-text {
    font-size: .75rem;
  }
}
.crm-suppression-progress {
  margin-top: 24px;
  display: none;
}
.crm-search-field {
  height: 40px;
  min-width: 300px;
}
.crm-search-field__icon {
  right: 0px !important;
  bottom: 8px !important;
}
.crm-search-field__input {
  font-size: .85rem;
  font-weight: 500;
  &::-webkit-input-placeholder { font-weight: 400; }
  &::-moz-placeholder { font-weight: 400; }
  &:-ms-input-placeholder { font-weight: 400; }
  &:-moz-placeholder { font-weight: 400; }
  &::placeholder { font-weight: 400; }
}
.crm-global-search__form {
  margin: 0 24px;
  margin-right: 32px;
}
.crm-global-search__input {
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 14rem;
  padding: 4px 12px;
  background: rgba(255,255,255,0.1);
  color: $mdc-theme-on-primary;
  &:focus {
    background: rgba(255,255,255,0.15);
    outline: none;
  }
  &:invalid {
    box-shadow: none;
  }
  &::-webkit-input-placeholder { color: rgba(255,255,255,0.5); }
  &::-moz-placeholder { color: rgba(255,255,255,0.5); }
  &:-ms-input-placeholder { color: rgba(255,255,255,0.5); }
  &:-moz-placeholder { color: rgba(255,255,255,0.5); }
  &::placeholder { color: rgba(255,255,255,0.5); }
}
.crm-grid-row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  height: 60px;
  vertical-align: middle;
  align-items: center;
}
.crm-grid--gutter-48 {
  --mdc-layout-grid-gutter-desktop: 48px;
}

.crm-form--header {
  height: 48px;
  padding: 0 16px;
  border-bottom: 2px solid #eee;
  margin-bottom: 12px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.crm-form--content {
  padding: 0 16px;
  margin-bottom: 10px;
  .mdc-text-field, .mdc-select,
  input[type="text"], select {
    width: 100%;
  }
}
.crm-form--row {
  padding-top: 24px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}

.crm-form--footer {
  height: 64px;
  margin-top: 24px;
  padding: 0 8px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  > .mdc-button {
    margin-right: 24px;
  }
}
.crm-form--footer-even {
  height: 50px;
  margin-top: 10px;
  padding: 0 30px;
  justify-content: space-evenly;
}
.crm-form--buttons {
  height: 56px;
  margin-top: 8px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  > .mdc-button {
    margin-right: 16px;
  }
}
.crm-form--hint {
  padding: 4px 14px;
}
.crm-form--errors {
  padding: 4px 14px;
  background-color: $mdc-theme-secondary;
  color: $mdc-theme-on-secondary;
}
.crm-checkbox-list {
  display: flex;
  flex-direction: column;
  > .mdc-form-field {
    display: flex;
  }
}
.crm-a {
  text-decoration: none;
  &:link,
  &:visited {
    text-decoration: none;
    color: $mdc-theme-primary;
  }
  &:hover {
    color: $mdc-theme-primary;
    text-decoration: underline;
  }
  &:active {
    color: $mdc-theme-primary;
    text-decoration: none;
  }
}
.crm-label--error {
  color: $crm-color-alert !important;
}
.crm-error--message {
  color: $crm-color-alert !important;
}

.crm-menu-items a {
  @include mdc-theme-prop(color, text-primary-on-light);
  text-decoration: none;
}
.crm-learner-list-courses {
  font-size: .75rem;
  font-weight: 400;
  color: rgba(0,0,0,.54);
  color: var(--mdc-theme-text-secondary-on-background,rgba(0,0,0,.54));
  margin-bottom: 8px;
}
.crm-learners-filtered-list {
  padding: 0 4px 8px 8px;
}
.crm-scroll-to-top {
  position: fixed;
  bottom: 24px;
  display: none;
}
.crm-side-form {
  overflow: hidden;
}

.crm-login-form {
  border-radius: 4px;
  padding: 0 40px 40px;
  width: 320px;
}
.crm-fixed--span-3 {
  background-color: #ffffff;
  min-width: 210px;
  width: 280px;
  // drawer = 54
  // padding = 24
  // (100vw - 54px - 24px)/4 - 24px
  width: calc(25vw - 44px);
  min-height: 320px;
  max-height: 600px;
  // 48(top bar) + 2*24px(margin) = 96px
  max-height: calc(100vh - 96px);
  overflow-y: scroll;
}
.crm-fixed--span-4 {
  background-color: #ffffff;
  min-width: 240px;
  width: 340px;
  // drawer = 54
  // padding = 24
  // (100vw - 54px - 24px)/3 - 24px
  width: calc(33vw - 50px);
  min-height: 320px;
  max-height: 600px;
  // 48(top bar) + 2*24px = 96px
  max-height: calc(100vh - 96px);
  overflow-y: inherit;
}
.crm-hero {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  min-height: 560px;
  padding: 24px;
}
.crm-empty-side-card-hint {
  padding: 10px 100px 30px;
}

.crm-email-preview--html,
.crm-email-preview--text,
.crm-email-preview--source {
  padding: 0 16px;
  margin-top: 8px;
  margin-top: 16px;
}
.crm-tabs {
  .crm-tab { display: none; }
  &[data-active="0"] { .crm-tab--0 { display: inherit; } }
  &[data-active="1"] { .crm-tab--1 { display: inherit; } }
  &[data-active="2"] { .crm-tab--2 { display: inherit; } }
  &[data-active="3"] { .crm-tab--3 { display: inherit; } }
}
.crm-email-preview--text {
  white-space: pre-wrap;
}
.crm-email-preview--source {
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 0.875rem;
}
.crm-fold {
  .crm-foldable {
    display: none !important;
  }
}
.crm-color-muted {
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.crm-color-grey {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-text-field--textarea .crm-template-textarea {
  font-size: 0.85rem;
  line-height: 1.4rem;
}

.crm-variant-variations {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  > .crm-variant-variation:last-child {
    margin-left: 12px;
  }
  > .crm-variant-variation:first-child {
    margin-right: 12px;
  }
}
.crm-variant-variation {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}
.crm-email-events dt {
  margin-top: 10px;
  margin-bottom: 4px;
  }
.crm-email-event-detail {
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 400;
  padding-left: 16px;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.crm-uniform-color {
  a {
    @include mdc-theme-prop(color, text-primary-on-light);
    text-decoration: none;
  }
}

.crm-clickable [data-path] {
  cursor: pointer;
}
.crm-border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.crm-issue-closed {
  background-color: #eee;
}
.crm-fab-action {
  background-color: #eee;
  color: #999;
}
.crm-attachment {
  padding: 4px 0;
  .material-icons {
    font-size: 20px;
  }
  a {
    color: $mdc-theme-primary;
    text-decoration: none;
    margin: 0 4px;
  }
  a[data-method="delete"]:hover {
    background-color: #e8e8e8;
    border-radius: 2px;
  }
}

input.crm-invisible {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 1px;
  margin: 0;
  padding: 0;
  opacity: 0;
  overflow: hidden;
}
.mdc-dialog .crm-response-preview-dialog {
  height: calc(100vh - 120px);
  width: 840px;
  margin-left: 40px;
  max-width: calc(100vw - 120px);
  .mdc-dialog__content {
    height: calc(100% - 20px);
    overflow-y: auto;
    overflow-x: auto;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
  }
}
.crm-outbox-list-editable {
  cursor: pointer;
}
.crm-outbox-list--email {
  max-width: 160px;
}
.crm-outbox-list--subject {
  max-width: 320px;
}
.crm-outbox-list--status {
  white-space: nowrap;
  max-width: 80px;
}
.crm-outbox-list--time {
  white-space: nowrap;
  max-width: 100px;
}
.crm-schedule-grid {
  display: grid;
  grid-template-columns: repeat(7, 42px);
  grid-template-rows: repeat(7, 50px);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}
.crm-schedule-date {
  justify-self: stretch;
  align-self: stretch;
  text-align: center;
}
.crm-schedule-date-header {
  align-self: end;
}
.crm-schedule-date-month {
  font-size: 0.75rem;
  font-weight: 400;
  opacity: 0;
}
.crm-schedule-date-month--show {
  opacity: 1;
}
.crm-schedule-date-btn {
  height: 100%;
  flex-direction: column;
  padding: 0;
  min-width: initial;
  width: 100%;
  text-transform: initial;
  &:hover, &:active, &:focus {
    .crm-schedule-date-month {
      opacity: 1;
    }
  }
}
.mdc-button--unelevated .crm-schedule-date-month {
  opacity: 1;
}


.crm-schedule-date-1 { grid-column-start: 1; }
.crm-schedule-date-2 { grid-column-start: 2; }
.crm-schedule-date-3 { grid-column-start: 3; }
.crm-schedule-date-4 { grid-column-start: 4; }
.crm-schedule-date-5 { grid-column-start: 5; }
.crm-schedule-date-6 { grid-column-start: 6; }
.crm-schedule-date-7 { grid-column-start: 7; }

@media screen and (max-width: 992px) {
  .crm-outbox-list--email {
    max-width: 80px;
    }
  .crm-outbox-list--subject {
    max-width: 160px;
  }
}
