@charset "UTF-8";
@import 'normalize.css/normalize.css';
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
ul {
  padding-left: 16px;
}

dl {
  margin: 0 0 16px;
}

dt {
  color: #616161;
  font-size: 13px;
}

dd {
  margin: 0 0 16px;
}

dl.align dt {
  float: left;
  clear: left;
  width: 60px;
  text-align: left;
  font-size: inherit;
}
dl.align dt::after {
  content: ":";
}
dl.align dd {
  margin: 0 0 0 70px;
  padding: 0 0 0.5em 0;
}

dl.align-wide dt {
  width: 140px;
}
dl.align-wide dd {
  margin-left: 150px;
}

dl.xs {
  margin: 0;
}
dl.xs dt {
  color: #666;
  width: 50px;
}
dl.xs dd {
  margin: 0;
  padding: 0;
}

.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  position: absolute;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  -webkit-transform-origin: left top;
  /* @noflip */ /*rtl:ignore*/
  transform-origin: left top;
  line-height: 1.15rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  /* @alternate */
  will-change: transform;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

[dir=rtl] .mdc-floating-label, .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /* @noflip */ /*rtl:ignore*/
  left: auto;
  /* @noflip */ /*rtl:ignore*/
  -webkit-transform-origin: right top;
  /* @noflip */ /*rtl:ignore*/
  transform-origin: right top;
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-floating-label--float-above {
  cursor: auto;
}

.mdc-floating-label--required::after {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 1px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
  content: "*";
}

[dir=rtl] .mdc-floating-label--required, .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-floating-label--required::after, .mdc-floating-label--required[dir=rtl]::after {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 1px;
}

.mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}

.mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-standard 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-106%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-106%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-106%) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-106%) scale(0.75);
  }
}
.mdc-line-ripple::before, .mdc-line-ripple::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-style: solid;
  content: "";
}

.mdc-line-ripple::before {
  border-bottom-width: 1px;
}

.mdc-line-ripple::before {
  z-index: 1;
}

.mdc-line-ripple::after {
  transform: scaleX(0);
  border-bottom-width: 2px;
  opacity: 0;
  z-index: 2;
}

.mdc-line-ripple::after {
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-line-ripple--active::after {
  transform: scaleX(1);
  opacity: 1;
}

.mdc-line-ripple--deactivating::after {
  opacity: 0;
}

.mdc-deprecated-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-deprecated-list:focus {
  outline: none;
}

.mdc-deprecated-list-item {
  height: 48px;
}

.mdc-deprecated-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-deprecated-list-item__graphic {
  background-color: transparent;
}

.mdc-deprecated-list-item__graphic {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-deprecated-list-item__meta {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-deprecated-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__text {
  opacity: 0.38;
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__text,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__primary-text,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__secondary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-deprecated-list-item--selected,
.mdc-deprecated-list-item--activated {
  color: #37474f;
  /* @alternate */
  color: var(--mdc-theme-primary, #37474f);
}

.mdc-deprecated-list-item--selected .mdc-deprecated-list-item__graphic,
.mdc-deprecated-list-item--activated .mdc-deprecated-list-item__graphic {
  color: #37474f;
  /* @alternate */
  color: var(--mdc-theme-primary, #37474f);
}

.mdc-deprecated-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.812rem;
}

.mdc-deprecated-list-item__wrapper {
  display: block;
}

.mdc-deprecated-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 48px;
}

.mdc-deprecated-list-item:focus {
  outline: none;
}

.mdc-deprecated-list-item:not(.mdc-deprecated-list-item--selected):focus::before, .mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-deprecated-list-item:not(.mdc-deprecated-list-item--selected):focus::before, .mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused::before {
    border-color: CanvasText;
  }
}
.mdc-deprecated-list-item.mdc-deprecated-list-item--selected::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px double transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-deprecated-list-item.mdc-deprecated-list-item--selected::before {
    border-color: CanvasText;
  }
}
[dir=rtl] .mdc-deprecated-list-item, .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 56px;
}

[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-item, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 56px;
}

[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 56px;
}

[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 72px;
}

[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-item, .mdc-deprecated-list--image-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 72px;
}

[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-item, .mdc-deprecated-list--video-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 20px;
  height: 20px;
}

[dir=rtl] .mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list-item__graphic {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  object-fit: cover;
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 32px;
  width: 24px;
  height: 24px;
}

[dir=rtl] .mdc-deprecated-list-item__graphic, .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 32px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 32px;
  width: 24px;
  height: 24px;
}

[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 32px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 40px;
  height: 40px;
}

[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 56px;
  height: 56px;
}

[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 100px;
  height: 56px;
}

[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--video-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-item__graphic {
  display: inline-flex;
}

.mdc-deprecated-list-item__meta {
  /* @noflip */ /*rtl:ignore*/
  margin-left: auto;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}

.mdc-deprecated-list-item__meta:not(.material-icons) {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}

.mdc-deprecated-list-item[dir=rtl] .mdc-deprecated-list-item__meta, [dir=rtl] .mdc-deprecated-list-item .mdc-deprecated-list-item__meta {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: auto;
}

.mdc-deprecated-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-deprecated-list-item__text[for] {
  pointer-events: none;
}

.mdc-deprecated-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-deprecated-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-deprecated-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-deprecated-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-deprecated-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item__secondary-text {
  font-size: inherit;
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item {
  height: 40px;
}

.mdc-deprecated-list--two-line .mdc-deprecated-list-item__text {
  align-self: flex-start;
}

.mdc-deprecated-list--two-line .mdc-deprecated-list-item {
  height: 64px;
}

.mdc-deprecated-list--two-line.mdc-deprecated-list--video-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--image-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--avatar-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--icon-list .mdc-deprecated-list-item {
  height: 72px;
}

.mdc-deprecated-list--two-line.mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-deprecated-list--two-line.mdc-deprecated-list--dense .mdc-deprecated-list-item,
.mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item {
  height: 60px;
}

.mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 36px;
  height: 36px;
}

[dir=rtl] .mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item {
  cursor: pointer;
}

a.mdc-deprecated-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-deprecated-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.mdc-deprecated-list-divider {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-deprecated-list-divider--padded {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}

[dir=rtl] .mdc-deprecated-list-divider--padded, .mdc-deprecated-list-divider--padded[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list-divider--inset {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 72px);
}

[dir=rtl] .mdc-deprecated-list-divider--inset, .mdc-deprecated-list-divider--inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list-divider--inset.mdc-deprecated-list-divider--padded {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}

[dir=rtl] .mdc-deprecated-list-divider--inset.mdc-deprecated-list-divider--padded, .mdc-deprecated-list-divider--inset.mdc-deprecated-list-divider--padded[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}

[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}

[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}

[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}

[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 72px);
}

[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}

[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}

[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}

[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 72px);
}

[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}

[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}

[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}

[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 72px);
}

[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}

[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}

[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}

[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 88px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}

[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 88px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 88px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 104px);
}

[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 88px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}

[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}

[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 116px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 116px);
}

[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 116px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 116px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 132px);
}

[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 116px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 0px);
}

[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}

[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list-group .mdc-deprecated-list {
  padding: 0;
}

.mdc-deprecated-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin: calc((3rem - 1.5rem) / 2) 16px;
}

.mdc-list-item__primary-text {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: #37474f;
  /* @alternate */
  color: var(--mdc-theme-primary, #37474f);
}

.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #37474f;
  /* @alternate */
  color: var(--mdc-theme-primary, #37474f);
}

.mdc-deprecated-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-list-divider::after {
    content: "";
    display: block;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: white;
  }
}
.mdc-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
}

.mdc-list:focus {
  outline: none;
}

.mdc-list-item__wrapper {
  display: block;
}

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  align-items: stretch;
  cursor: pointer;
}

.mdc-list-item:focus {
  outline: none;
}

.mdc-list-item.mdc-list-item--with-one-line {
  height: 48px;
}

.mdc-list-item.mdc-list-item--with-two-lines {
  height: 64px;
}

.mdc-list-item.mdc-list-item--with-three-lines {
  height: 88px;
}

.mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__start {
  align-self: center;
  margin-top: 0;
}

.mdc-list-item.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-list-item.mdc-list-item--with-three-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__end {
  align-self: center;
  margin-top: 0;
}

.mdc-list-item.mdc-list-item--with-two-lines .mdc-list-item__end {
  align-self: center;
  margin-top: 0;
}

.mdc-list-item.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-list-item.mdc-list-item--disabled, .mdc-list-item.mdc-list-item--non-interactive {
  cursor: auto;
}

.mdc-list-item:not(.mdc-list-item--selected):focus::before, .mdc-list-item.mdc-ripple-upgraded--background-focused::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-list-item:not(.mdc-list-item--selected):focus::before, .mdc-list-item.mdc-ripple-upgraded--background-focused::before {
    border-color: CanvasText;
  }
}
.mdc-list-item.mdc-list-item--selected::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px double transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-list-item.mdc-list-item--selected::before {
    border-color: CanvasText;
  }
}
.mdc-list-item.mdc-list-item--selected:focus::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-list-item.mdc-list-item--selected:focus::before {
    border-color: CanvasText;
  }
}
a.mdc-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-list-item__start {
  fill: currentColor;
  flex-shrink: 0;
  pointer-events: none;
}

.mdc-list-item__end {
  flex-shrink: 0;
  pointer-events: none;
}

.mdc-list-item__content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
  flex: 1;
  pointer-events: none;
}

.mdc-list-item--with-two-lines .mdc-list-item__content, .mdc-list-item--with-three-lines .mdc-list-item__content {
  align-self: stretch;
}

.mdc-list-item__content[for] {
  pointer-events: none;
}

.mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-list-item--with-two-lines .mdc-list-item__primary-text, .mdc-list-item--with-three-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before, .mdc-list-item--with-three-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after, .mdc-list-item--with-three-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-three-lines .mdc-list-item__secondary-text {
  white-space: normal;
  line-height: 20px;
}

.mdc-list-item--with-overline .mdc-list-item__secondary-text {
  white-space: nowrap;
  line-height: auto;
}

.mdc-list-item__overline-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 0.75rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 500);
  letter-spacing: 0.1666666667em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, 0.1666666667em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, uppercase);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-three-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-three-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-three-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-avatar.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-item--with-leading-avatar.mdc-list-item, .mdc-list-item--with-leading-avatar.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-avatar .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}

[dir=rtl] .mdc-list-item--with-leading-avatar .mdc-list-item__start, .mdc-list-item--with-leading-avatar .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-avatar .mdc-list-item__start {
  width: 40px;
  height: 40px;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line {
  height: 56px;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-avatar .mdc-list-item__start {
  border-radius: 50%;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  width: 24px;
  height: 24px;
}

.mdc-list-item--with-leading-icon.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-item--with-leading-icon.mdc-list-item, .mdc-list-item--with-leading-icon.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 32px;
}

[dir=rtl] .mdc-list-item--with-leading-icon .mdc-list-item__start, .mdc-list-item--with-leading-icon .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 32px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-item--with-leading-thumbnail.mdc-list-item, .mdc-list-item--with-leading-thumbnail.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-thumbnail .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}

[dir=rtl] .mdc-list-item--with-leading-thumbnail .mdc-list-item__start, .mdc-list-item--with-leading-thumbnail .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-thumbnail .mdc-list-item__start {
  width: 40px;
  height: 40px;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-one-line {
  height: 56px;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-image.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-item--with-leading-image.mdc-list-item, .mdc-list-item--with-leading-image.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-image .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}

[dir=rtl] .mdc-list-item--with-leading-image .mdc-list-item__start, .mdc-list-item--with-leading-image .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-image .mdc-list-item__start {
  width: 56px;
  height: 56px;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-one-line {
  height: 72px;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-list-item--with-leading-video.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-item--with-leading-video.mdc-list-item, .mdc-list-item--with-leading-video.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-video .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}

[dir=rtl] .mdc-list-item--with-leading-video .mdc-list-item__start, .mdc-list-item--with-leading-video .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-video .mdc-list-item__start {
  width: 100px;
  height: 56px;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-one-line {
  height: 72px;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-item--with-leading-checkbox.mdc-list-item, .mdc-list-item--with-leading-checkbox.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
}

[dir=rtl] .mdc-list-item--with-leading-checkbox .mdc-list-item__start, .mdc-list-item--with-leading-checkbox .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  width: 40px;
  height: 40px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line {
  height: 56px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-radio.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-item--with-leading-radio.mdc-list-item, .mdc-list-item--with-leading-radio.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-radio .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
}

[dir=rtl] .mdc-list-item--with-leading-radio .mdc-list-item__start, .mdc-list-item--with-leading-radio .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-radio .mdc-list-item__start {
  width: 40px;
  height: 40px;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-one-line {
  height: 56px;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-switch.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-item--with-leading-switch.mdc-list-item, .mdc-list-item--with-leading-switch.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-switch .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}

[dir=rtl] .mdc-list-item--with-leading-switch .mdc-list-item__start, .mdc-list-item--with-leading-switch .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-switch .mdc-list-item__start {
  width: 36px;
  height: 20px;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-one-line {
  height: 56px;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-trailing-icon.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-list-item--with-trailing-icon.mdc-list-item, .mdc-list-item--with-trailing-icon.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}

[dir=rtl] .mdc-list-item--with-trailing-icon .mdc-list-item__end, .mdc-list-item--with-trailing-icon .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  width: 24px;
  height: 24px;
}

.mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 0;
}

.mdc-list-item--with-trailing-meta.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 0;
}

.mdc-list-item--with-trailing-meta.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-list-item--with-trailing-meta.mdc-list-item, .mdc-list-item--with-trailing-meta.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 28px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}

[dir=rtl] .mdc-list-item--with-trailing-meta .mdc-list-item__end, .mdc-list-item--with-trailing-meta .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 28px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-trailing-meta.mdc-list-item--with-three-lines .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-trailing-meta.mdc-list-item--with-three-lines .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}

.mdc-list-item--with-trailing-checkbox.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-list-item--with-trailing-checkbox.mdc-list-item, .mdc-list-item--with-trailing-checkbox.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-checkbox .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}

[dir=rtl] .mdc-list-item--with-trailing-checkbox .mdc-list-item__end, .mdc-list-item--with-trailing-checkbox .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-checkbox .mdc-list-item__end {
  width: 40px;
  height: 40px;
}

.mdc-list-item--with-trailing-checkbox.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-list-item--with-trailing-radio.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-list-item--with-trailing-radio.mdc-list-item, .mdc-list-item--with-trailing-radio.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-radio .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}

[dir=rtl] .mdc-list-item--with-trailing-radio .mdc-list-item__end, .mdc-list-item--with-trailing-radio .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-radio .mdc-list-item__end {
  width: 40px;
  height: 40px;
}

.mdc-list-item--with-trailing-radio.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-list-item--with-trailing-switch.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-list-item--with-trailing-switch.mdc-list-item, .mdc-list-item--with-trailing-switch.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-switch .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}

[dir=rtl] .mdc-list-item--with-trailing-switch .mdc-list-item__end, .mdc-list-item--with-trailing-switch .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-switch .mdc-list-item__end {
  width: 36px;
  height: 20px;
}

.mdc-list-item--with-trailing-switch.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-list-item--with-overline.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-overline.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-overline.mdc-list-item--with-three-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-overline.mdc-list-item--with-three-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}

[dir=rtl] .mdc-list-item, .mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-group .mdc-deprecated-list {
  padding: 0;
}

.mdc-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin: calc((3rem - 1.5rem) / 2) 16px;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mdc-list-divider {
  height: 1px;
}

.mdc-list-divider {
  padding: 0;
  background-clip: content-box;
}

.mdc-list-divider.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-text.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-icon.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-image.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-avatar.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-switch.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-radio.mdc-list-divider--with-leading-inset {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-divider.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-text.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-icon.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-image.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-switch.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-radio.mdc-list-divider--with-leading-inset, .mdc-list-divider.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-text.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-icon.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-image.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-switch.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-radio.mdc-list-divider--with-leading-inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-divider.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-text.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-icon.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-image.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-avatar.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-switch.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-radio.mdc-list-divider--with-trailing-inset {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}

[dir=rtl] .mdc-list-divider.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-text.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-icon.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-image.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-switch.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-radio.mdc-list-divider--with-trailing-inset, .mdc-list-divider.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-text.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-icon.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-image.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-switch.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-radio.mdc-list-divider--with-trailing-inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-divider--with-leading-video.mdc-list-divider--with-leading-inset {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-divider--with-leading-video.mdc-list-divider--with-leading-inset, .mdc-list-divider--with-leading-video.mdc-list-divider--with-leading-inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0px;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-list-divider, .mdc-list-divider[dir=rtl] {
  /*rtl:begin:ignore*/
  padding: 0;
  /*rtl:end:ignore*/
}

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--unbounded .mdc-deprecated-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-activation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:hover .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:hover .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-deprecated-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-deprecated-list-item__ripple::after {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #37474f));
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:hover .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.24);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-list-item__ripple::after {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #37474f));
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:hover .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.24);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::after {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #37474f));
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:hover .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.2);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-list-item__ripple::after {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #37474f));
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:hover .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.2);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-deprecated-list-item--disabled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-deprecated-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, .mdc-deprecated-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-deprecated-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

:not(.mdc-list-item--disabled).mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

:not(.mdc-list-item--disabled).mdc-list-item:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}

:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::after {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #37474f));
}

:not(.mdc-list-item--disabled).mdc-list-item--activated:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}

:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}

:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}

:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.24);
}

:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}

:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::after {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #37474f));
}

:not(.mdc-list-item--disabled).mdc-list-item--selected:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}

:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}

:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}

:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.2);
}

:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-list-item--disabled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-list-item--disabled .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-list-item--disabled .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

.mdc-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-list-item--disabled .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-menu-surface {
  display: none;
  position: absolute;
  box-sizing: border-box;
  max-width: calc(100vw - 32px);
  /* @alternate */
  max-width: var(--mdc-menu-max-width, calc(100vw - 32px));
  max-height: calc(100vh - 32px);
  /* @alternate */
  max-height: var(--mdc-menu-max-height, calc(100vh - 32px));
  margin: 0;
  padding: 0;
  transform: scale(1);
  transform-origin: top left;
  opacity: 0;
  overflow: auto;
  will-change: transform, opacity;
  z-index: 8;
  transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1), height 250ms cubic-bezier(0, 0, 0.2, 1);
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  /* @noflip */ /*rtl:ignore*/
  transform-origin-left: top left;
  /* @noflip */ /*rtl:ignore*/
  transform-origin-right: top right;
}

.mdc-menu-surface:focus {
  outline: none;
}

.mdc-menu-surface--animating-open {
  display: inline-block;
  transform: scale(0.8);
  opacity: 0;
}

.mdc-menu-surface--open {
  display: inline-block;
  transform: scale(1);
  opacity: 1;
}

.mdc-menu-surface--animating-closed {
  display: inline-block;
  opacity: 0;
  transition: opacity 0.075s linear;
}

[dir=rtl] .mdc-menu-surface, .mdc-menu-surface[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform-origin-left: top right;
  /* @noflip */ /*rtl:ignore*/
  transform-origin-right: top left;
  /*rtl:end:ignore*/
}

.mdc-menu-surface--anchor {
  position: relative;
  overflow: visible;
}

.mdc-menu-surface--fixed {
  position: fixed;
}

.mdc-menu-surface--fullwidth {
  width: 100%;
}

.mdc-elevation-overlay {
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
  opacity: 0;
  /* @alternate */
  opacity: var(--mdc-elevation-overlay-opacity, 0);
  transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-elevation-overlay-color, #fff);
}

.mdc-menu {
  min-width: 112px;
  /* @alternate */
  min-width: var(--mdc-menu-min-width, 112px);
}

.mdc-menu .mdc-deprecated-list-item__meta {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-menu .mdc-deprecated-list-item__graphic {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-menu .mdc-menu-item--submenu-open .mdc-deprecated-list-item__ripple::before {
  opacity: 0.04;
}

.mdc-menu .mdc-menu-item--submenu-open .mdc-list-item__ripple::before {
  opacity: 0.04;
}

.mdc-menu .mdc-deprecated-list {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-menu .mdc-deprecated-list,
.mdc-menu .mdc-list {
  /* @alternate */
  position: relative;
}

.mdc-menu .mdc-deprecated-list .mdc-elevation-overlay,
.mdc-menu .mdc-list .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}

.mdc-menu .mdc-deprecated-list-divider {
  margin: 8px 0;
}

.mdc-menu .mdc-deprecated-list-item {
  user-select: none;
}

.mdc-menu .mdc-deprecated-list-item--disabled {
  cursor: auto;
}

.mdc-menu a.mdc-deprecated-list-item .mdc-deprecated-list-item__text,
.mdc-menu a.mdc-deprecated-list-item .mdc-deprecated-list-item__graphic {
  pointer-events: none;
}

.mdc-menu__selection-group {
  padding: 0;
  fill: currentColor;
}

.mdc-menu__selection-group .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 56px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}

[dir=rtl] .mdc-menu__selection-group .mdc-deprecated-list-item, .mdc-menu__selection-group .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 56px;
  /*rtl:end:ignore*/
}

.mdc-menu__selection-group .mdc-menu__selection-group-icon {
  /* @noflip */ /*rtl:ignore*/
  left: 16px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

[dir=rtl] .mdc-menu__selection-group .mdc-menu__selection-group-icon, .mdc-menu__selection-group .mdc-menu__selection-group-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 16px;
  /*rtl:end:ignore*/
}

.mdc-menu-item--selected .mdc-menu__selection-group-icon {
  display: inline;
}

.mdc-notched-outline {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
  pointer-events: none;
}

[dir=rtl] .mdc-notched-outline, .mdc-notched-outline[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
  box-sizing: border-box;
  height: 100%;
  border-top: 1px solid;
  border-bottom: 1px solid;
  pointer-events: none;
}

.mdc-notched-outline__leading {
  /* @noflip */ /*rtl:ignore*/
  border-left: 1px solid;
  /* @noflip */ /*rtl:ignore*/
  border-right: none;
  width: 12px;
}

[dir=rtl] .mdc-notched-outline__leading, .mdc-notched-outline__leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-left: none;
  /* @noflip */ /*rtl:ignore*/
  border-right: 1px solid;
  /*rtl:end:ignore*/
}

.mdc-notched-outline__trailing {
  /* @noflip */ /*rtl:ignore*/
  border-left: none;
  /* @noflip */ /*rtl:ignore*/
  border-right: 1px solid;
  flex-grow: 1;
}

[dir=rtl] .mdc-notched-outline__trailing, .mdc-notched-outline__trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-left: 1px solid;
  /* @noflip */ /*rtl:ignore*/
  border-right: none;
  /*rtl:end:ignore*/
}

.mdc-notched-outline__notch {
  flex: 0 0 auto;
  width: auto;
  max-width: calc(100% - 12px * 2);
}

.mdc-notched-outline .mdc-floating-label {
  display: inline-block;
  position: relative;
  max-width: 100%;
}

.mdc-notched-outline .mdc-floating-label--float-above {
  text-overflow: clip;
}

.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  max-width: 133.3333333333%;
}

.mdc-notched-outline--notched .mdc-notched-outline__notch {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  border-top: none;
}

[dir=rtl] .mdc-notched-outline--notched .mdc-notched-outline__notch, .mdc-notched-outline--notched .mdc-notched-outline__notch[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-notched-outline--no-label .mdc-notched-outline__notch {
  display: none;
}

.mdc-select {
  display: inline-flex;
  position: relative;
}

.mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-select.mdc-select--disabled .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: rgba(55, 71, 79, 0.87);
}

.mdc-select.mdc-select--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-select:not(.mdc-select--disabled) .mdc-select__dropdown-icon {
  fill: rgba(0, 0, 0, 0.54);
}

.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-select__dropdown-icon {
  fill: #37474f;
  /* @alternate */
  fill: var(--mdc-theme-primary, #37474f);
}

.mdc-select.mdc-select--disabled .mdc-select__dropdown-icon {
  fill: rgba(0, 0, 0, 0.38);
}

.mdc-select:not(.mdc-select--disabled) + .mdc-select-helper-text {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-select.mdc-select--disabled + .mdc-select-helper-text {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-select:not(.mdc-select--disabled) .mdc-select__icon {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-select.mdc-select--disabled .mdc-select__icon {
  color: rgba(0, 0, 0, 0.38);
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-select.mdc-select--disabled .mdc-select__selected-text {
    color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-select__dropdown-icon {
    fill: red;
  }
  .mdc-select.mdc-select--disabled .mdc-floating-label {
    color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-notched-outline__leading,
  .mdc-select.mdc-select--disabled .mdc-notched-outline__notch,
  .mdc-select.mdc-select--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-select__icon {
    color: GrayText;
  }
  .mdc-select.mdc-select--disabled + .mdc-select-helper-text {
    color: GrayText;
  }
}
.mdc-select .mdc-floating-label {
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.mdc-select .mdc-select__anchor {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-select .mdc-select__anchor, .mdc-select .mdc-select__anchor[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-select.mdc-select--with-leading-icon .mdc-select__anchor {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-select.mdc-select--with-leading-icon .mdc-select__anchor, .mdc-select.mdc-select--with-leading-icon .mdc-select__anchor[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-select .mdc-select__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.mdc-select .mdc-select__dropdown-icon {
  width: 24px;
  height: 24px;
}

.mdc-select .mdc-select__menu .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}

[dir=rtl] .mdc-select .mdc-select__menu .mdc-deprecated-list-item, .mdc-select .mdc-select__menu .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-select .mdc-select__menu .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
}

[dir=rtl] .mdc-select .mdc-select__menu .mdc-deprecated-list-item__graphic, .mdc-select .mdc-select__menu .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-select__dropdown-icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  display: inline-flex;
  position: relative;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  pointer-events: none;
}

.mdc-select__dropdown-icon .mdc-select__dropdown-icon-active,
.mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  position: absolute;
  top: 0;
  left: 0;
}

.mdc-select__dropdown-icon .mdc-select__dropdown-icon-graphic {
  width: 41.6666666667%;
  height: 20.8333333333%;
}

.mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  opacity: 1;
  transition: opacity 75ms linear 75ms;
}

.mdc-select__dropdown-icon .mdc-select__dropdown-icon-active {
  opacity: 0;
  transition: opacity 75ms linear;
}

[dir=rtl] .mdc-select__dropdown-icon, .mdc-select__dropdown-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-select--activated .mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  opacity: 0;
  transition: opacity 49.5ms linear;
}

.mdc-select--activated .mdc-select__dropdown-icon .mdc-select__dropdown-icon-active {
  opacity: 1;
  transition: opacity 100.5ms linear 49.5ms;
}

.mdc-select__anchor {
  width: 200px;
  min-width: 0;
  flex: 1 1 auto;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  outline: none;
  cursor: pointer;
}

.mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}

.mdc-select__selected-text-container {
  display: flex;
  appearance: none;
  pointer-events: none;
  box-sizing: border-box;
  width: auto;
  min-width: 0;
  flex-grow: 1;
  height: 28px;
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  color: inherit;
}

.mdc-select__selected-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  width: 100%;
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
}

[dir=rtl] .mdc-select__selected-text, .mdc-select__selected-text[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--invalid + .mdc-select-helper-text--validation-msg {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-select__dropdown-icon {
  fill: #b00020;
  /* @alternate */
  fill: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-select__dropdown-icon {
  fill: #b00020;
  /* @alternate */
  fill: var(--mdc-theme-error, #b00020);
}

.mdc-select--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-select--with-leading-icon .mdc-select__menu .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}

[dir=rtl] .mdc-select--with-leading-icon .mdc-select__menu .mdc-deprecated-list-item, .mdc-select--with-leading-icon .mdc-select__menu .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
  /*rtl:end:ignore*/
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-select__menu::before {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    content: "";
    pointer-events: none;
  }
}
@media screen and (forced-colors: active) and (forced-colors: active), screen and (-ms-high-contrast: active) and (forced-colors: active) {
  .mdc-select__menu::before {
    border-color: CanvasText;
  }
}
.mdc-select__menu .mdc-deprecated-list .mdc-select__icon,
.mdc-select__menu .mdc-list .mdc-select__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}

[dir=rtl] .mdc-select__menu .mdc-deprecated-list .mdc-select__icon, [dir=rtl] .mdc-select__menu .mdc-list .mdc-select__icon, .mdc-select__menu .mdc-deprecated-list .mdc-select__icon[dir=rtl], .mdc-select__menu .mdc-list .mdc-select__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected,
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--activated,
.mdc-select__menu .mdc-list .mdc-deprecated-list-item--selected,
.mdc-select__menu .mdc-list .mdc-deprecated-list-item--activated {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-deprecated-list-item__graphic,
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--activated .mdc-deprecated-list-item__graphic,
.mdc-select__menu .mdc-list .mdc-deprecated-list-item--selected .mdc-deprecated-list-item__graphic,
.mdc-select__menu .mdc-list .mdc-deprecated-list-item--activated .mdc-deprecated-list-item__graphic {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-select__menu .mdc-list-item__start {
  display: inline-flex;
  align-items: center;
}

.mdc-select__option {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}

[dir=rtl] .mdc-select__option, .mdc-select__option[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-select__one-line-option.mdc-list-item--with-one-line {
  height: 48px;
}

.mdc-select__two-line-option.mdc-list-item--with-two-lines {
  height: 64px;
}

.mdc-select__two-line-option.mdc-list-item--with-two-lines .mdc-list-item__start {
  margin-top: 20px;
}

.mdc-select__two-line-option.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-select__two-line-option.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-select__two-line-option.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-select__two-line-option.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-select__two-line-option.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 36px;
  content: "";
  vertical-align: 0;
}

.mdc-select__option-with-leading-content {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}

.mdc-select__option-with-leading-content.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-select__option-with-leading-content.mdc-list-item, .mdc-select__option-with-leading-content.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-select__option-with-leading-content .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}

[dir=rtl] .mdc-select__option-with-leading-content .mdc-list-item__start, .mdc-select__option-with-leading-content .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-select__option-with-leading-content .mdc-list-item__start {
  width: 36px;
  height: 24px;
}

[dir=rtl] .mdc-select__option-with-leading-content, .mdc-select__option-with-leading-content[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-select__option-with-meta.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-select__option-with-meta.mdc-list-item, .mdc-select__option-with-meta.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-select__option-with-meta .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
}

[dir=rtl] .mdc-select__option-with-meta .mdc-list-item__end, .mdc-select__option-with-meta .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-select--filled .mdc-select__anchor {
  height: 56px;
  display: flex;
  align-items: baseline;
}

.mdc-select--filled .mdc-select__anchor::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}

.mdc-select--filled.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}

.mdc-select--filled.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text-container {
  height: 100%;
  display: inline-flex;
  align-items: center;
}

.mdc-select--filled.mdc-select--no-label .mdc-select__anchor::before {
  display: none;
}

.mdc-select--filled .mdc-select__anchor {
  border-top-left-radius: 4px;
  /* @alternate */
  border-top-left-radius: var(--mdc-shape-small, 4px);
  border-top-right-radius: 4px;
  /* @alternate */
  border-top-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.mdc-select--filled:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: whitesmoke;
}

.mdc-select--filled.mdc-select--disabled .mdc-select__anchor {
  background-color: #fafafa;
}

.mdc-select--filled:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}

.mdc-select--filled:not(.mdc-select--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}

.mdc-select--filled:not(.mdc-select--disabled) .mdc-line-ripple::after {
  border-bottom-color: #37474f;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-primary, #37474f);
}

.mdc-select--filled.mdc-select--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}

.mdc-select--filled .mdc-floating-label {
  max-width: calc(100% - 64px);
}

.mdc-select--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}

.mdc-select--filled .mdc-menu-surface--is-open-below {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.mdc-select--filled.mdc-select--focused.mdc-line-ripple::after {
  transform: scale(1, 2);
  opacity: 1;
}

.mdc-select--filled .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 16px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}

[dir=rtl] .mdc-select--filled .mdc-floating-label, .mdc-select--filled .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 16px;
  /*rtl:end:ignore*/
}

.mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 48px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}

[dir=rtl] .mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label, .mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 48px;
  /*rtl:end:ignore*/
}

.mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label {
  max-width: calc(100% - 96px);
}

.mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 96px / 0.75);
}

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid:not(.mdc-select--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-line-ripple::after {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--outlined {
  border: none;
}

.mdc-select--outlined .mdc-select__anchor {
  height: 56px;
}

.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}

.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  font-size: 0.75rem;
}

.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}

.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}

.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-56px 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-select-outlined-56px {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
}
.mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
}

[dir=rtl] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    width: max(12px, var(--mdc-shape-small, 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__notch {
    max-width: calc(100% - max(12px, var(--mdc-shape-small, 4px)) * 2);
  }
}
.mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
}

[dir=rtl] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  .mdc-select--outlined .mdc-select__anchor {
    /* @noflip */ /*rtl:ignore*/
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
[dir=rtl] .mdc-select--outlined .mdc-select__anchor, .mdc-select--outlined .mdc-select__anchor[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  [dir=rtl] .mdc-select--outlined .mdc-select__anchor, .mdc-select--outlined .mdc-select__anchor[dir=rtl] {
    /* @noflip */ /*rtl:ignore*/
    padding-right: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-select--outlined + .mdc-select-helper-text {
    /* @noflip */ /*rtl:ignore*/
    margin-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
[dir=rtl] .mdc-select--outlined + .mdc-select-helper-text, .mdc-select--outlined + .mdc-select-helper-text[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  [dir=rtl] .mdc-select--outlined + .mdc-select-helper-text, .mdc-select--outlined + .mdc-select-helper-text[dir=rtl] {
    /* @noflip */ /*rtl:ignore*/
    margin-right: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: transparent;
}

.mdc-select--outlined.mdc-select--disabled .mdc-select__anchor {
  background-color: transparent;
}

.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}

.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}

.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px;
}

.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #37474f;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #37474f);
}

.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}

.mdc-select--outlined .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}

.mdc-select--outlined .mdc-select__anchor {
  display: flex;
  align-items: baseline;
  overflow: visible;
}

.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined 250ms 1;
}

.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}

.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  font-size: 0.75rem;
}

.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}

.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}

.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 1px;
}

.mdc-select--outlined .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}

.mdc-select--outlined .mdc-select__anchor .mdc-select__selected-text-container {
  height: 100%;
  display: inline-flex;
  align-items: center;
}

.mdc-select--outlined .mdc-select__anchor::before {
  display: none;
}

.mdc-select--outlined .mdc-select__selected-text-container {
  display: flex;
  border: none;
  z-index: 1;
  background-color: transparent;
}

.mdc-select--outlined .mdc-select__icon {
  z-index: 2;
}

.mdc-select--outlined .mdc-floating-label {
  line-height: 1.15rem;
  /* @noflip */ /*rtl:ignore*/
  left: 4px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}

[dir=rtl] .mdc-select--outlined .mdc-floating-label, .mdc-select--outlined .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 4px;
  /*rtl:end:ignore*/
}

.mdc-select--outlined.mdc-select--focused .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 2px;
}

.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px;
}

.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 36px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}

[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label, .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 36px;
  /*rtl:end:ignore*/
}

.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above {
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-37.25px) translateX(-32px) scale(1);
}

[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above, .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-37.25px) translateX(32px) scale(1);
  /*rtl:end:ignore*/
}

.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above {
  font-size: 0.75rem;
}

.mdc-select--outlined.mdc-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-34.75px) translateX(-32px) scale(0.75);
}

[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above, [dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-select--outlined.mdc-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl], .mdc-select--outlined.mdc-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-34.75px) translateX(32px) scale(0.75);
  /*rtl:end:ignore*/
}

.mdc-select--outlined.mdc-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}

.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
}
[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon, .mdc-select--outlined.mdc-select--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--shake, .mdc-select--outlined.mdc-select--with-leading-icon[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px-rtl {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
}
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 96px);
}

.mdc-select--outlined .mdc-menu-surface {
  margin-bottom: 8px;
}

.mdc-select--outlined.mdc-select--no-label .mdc-menu-surface,
.mdc-select--outlined .mdc-menu-surface--is-open-below {
  margin-bottom: 0;
}

.mdc-select__anchor {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-select__anchor .mdc-select__ripple::before,
.mdc-select__anchor .mdc-select__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-select__anchor .mdc-select__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-select__anchor .mdc-select__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-select__anchor.mdc-ripple-upgraded--unbounded .mdc-select__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-select__anchor.mdc-ripple-upgraded--foreground-activation .mdc-select__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-select__anchor.mdc-ripple-upgraded--foreground-deactivation .mdc-select__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-select__anchor .mdc-select__ripple::before,
.mdc-select__anchor .mdc-select__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-select__anchor .mdc-select__ripple::before,
.mdc-select__anchor .mdc-select__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}

.mdc-select__anchor:hover .mdc-select__ripple::before, .mdc-select__anchor.mdc-ripple-surface--hover .mdc-select__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-select__anchor.mdc-ripple-upgraded--background-focused .mdc-select__ripple::before, .mdc-select__anchor:not(.mdc-ripple-upgraded):focus .mdc-select__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-select__anchor .mdc-select__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:hover .mdc-deprecated-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:hover .mdc-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-select-helper-text {
  margin: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

[dir=rtl] .mdc-select-helper-text, .mdc-select-helper-text[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-select-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-select-helper-text--validation-msg {
  opacity: 0;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-select--invalid + .mdc-select-helper-text--validation-msg,
.mdc-select-helper-text--validation-msg-persistent {
  opacity: 1;
}

.mdc-select--with-leading-icon .mdc-select__icon {
  display: inline-block;
  box-sizing: border-box;
  border: none;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  flex-shrink: 0;
  align-self: center;
  background-color: transparent;
  fill: currentColor;
}

.mdc-select--with-leading-icon .mdc-select__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
}

[dir=rtl] .mdc-select--with-leading-icon .mdc-select__icon, .mdc-select--with-leading-icon .mdc-select__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-select__icon:not([tabindex]),
.mdc-select__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}

.mdc-card {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  /* @alternate */
  position: relative;
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.mdc-card .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}
.mdc-card::after {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-card::after {
    border-color: CanvasText;
  }
}

.mdc-card--outlined {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
}
.mdc-card--outlined::after {
  border: none;
}

.mdc-card__content {
  border-radius: inherit;
  height: 100%;
}

.mdc-card__media {
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.mdc-card__media::before {
  display: block;
  content: "";
}

.mdc-card__media:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__media:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__media--square::before {
  margin-top: 100%;
}

.mdc-card__media--16-9::before {
  margin-top: 56.25%;
}

.mdc-card__media-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
}

.mdc-card__primary-action {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  outline: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
}

.mdc-card__primary-action:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__primary-action:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px;
}

.mdc-card__actions--full-bleed {
  padding: 0;
}

.mdc-card__action-buttons,
.mdc-card__action-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.mdc-card__action-icons {
  color: rgba(0, 0, 0, 0.6);
  flex-grow: 1;
  justify-content: flex-end;
}

.mdc-card__action-buttons + .mdc-card__action-icons {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}
[dir=rtl] .mdc-card__action-buttons + .mdc-card__action-icons, .mdc-card__action-buttons + .mdc-card__action-icons[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-card__action {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}
.mdc-card__action:focus {
  outline: none;
}

.mdc-card__action--button {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  padding: 0 8px;
}
[dir=rtl] .mdc-card__action--button, .mdc-card__action--button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-card__action--button:last-child {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}
[dir=rtl] .mdc-card__action--button:last-child, .mdc-card__action--button:last-child[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-card__actions--full-bleed .mdc-card__action--button {
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-height: none;
  margin: 0;
  padding: 8px 16px;
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
}
[dir=rtl] .mdc-card__actions--full-bleed .mdc-card__action--button, .mdc-card__actions--full-bleed .mdc-card__action--button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-card__action--icon {
  margin: -6px 0;
  padding: 12px;
}

.mdc-card__action--icon:not(:disabled) {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-card__primary-action {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-card__primary-action .mdc-card__ripple::before,
.mdc-card__primary-action .mdc-card__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-card__primary-action .mdc-card__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-card__primary-action .mdc-card__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-card__primary-action.mdc-ripple-upgraded .mdc-card__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-card__primary-action.mdc-ripple-upgraded .mdc-card__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-card__primary-action.mdc-ripple-upgraded--unbounded .mdc-card__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-card__primary-action.mdc-ripple-upgraded--foreground-activation .mdc-card__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-card__primary-action.mdc-ripple-upgraded--foreground-deactivation .mdc-card__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-card__primary-action .mdc-card__ripple::before,
.mdc-card__primary-action .mdc-card__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-card__primary-action.mdc-ripple-upgraded .mdc-card__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-card__primary-action .mdc-card__ripple::before, .mdc-card__primary-action .mdc-card__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-card__primary-action:hover .mdc-card__ripple::before, .mdc-card__primary-action.mdc-ripple-surface--hover .mdc-card__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-card__primary-action.mdc-ripple-upgraded--background-focused .mdc-card__ripple::before, .mdc-card__primary-action:not(.mdc-ripple-upgraded):focus .mdc-card__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-card__primary-action:not(.mdc-ripple-upgraded) .mdc-card__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-card__primary-action:not(.mdc-ripple-upgraded):active .mdc-card__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-card__primary-action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-card__primary-action .mdc-card__ripple {
  box-sizing: content-box;
  height: 100%;
  overflow: hidden;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}
.mdc-card__primary-action.mdc-ripple-upgraded--background-focused::after, .mdc-card__primary-action:not(.mdc-ripple-upgraded):focus::after {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 5px double transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-card__primary-action.mdc-ripple-upgraded--background-focused::after, .mdc-card__primary-action:not(.mdc-ripple-upgraded):focus::after {
    border-color: CanvasText;
  }
}

.mdc-checkbox {
  padding: calc((40px - 18px) / 2);
  /* @alternate */
  padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  margin: calc((40px - 40px) / 2);
  /* @alternate */
  margin: calc((var(--mdc-checkbox-touch-target-size, 40px) - 40px) / 2);
}
.mdc-checkbox .mdc-checkbox__ripple::before, .mdc-checkbox .mdc-checkbox__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-checkbox:hover .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-ripple-surface--hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-checkbox:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-checkbox:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #ffc107;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #ffc107));
}
.mdc-checkbox.mdc-checkbox--selected:hover .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected.mdc-ripple-surface--hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #ffc107;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #ffc107));
}
.mdc-checkbox .mdc-checkbox__background {
  top: calc((40px - 18px) / 2);
  /* @alternate */
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  left: calc((40px - 18px) / 2);
  /* @alternate */
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}
.mdc-checkbox .mdc-checkbox__native-control {
  top: calc((40px - 40px) / 2);
  /* @alternate */
  top: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  right: calc((40px - 40px) / 2);
  /* @alternate */
  right: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  left: calc((40px - 40px) / 2);
  /* @alternate */
  left: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  width: 40px;
  /* @alternate */
  width: var(--mdc-checkbox-touch-target-size, 40px);
  height: 40px;
  /* @alternate */
  height: var(--mdc-checkbox-touch-target-size, 40px);
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
  background-color: transparent;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
  border-color: #ffc107;
  /* @alternate */
  border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #ffc107));
  background-color: #ffc107;
  /* @alternate */
  background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #ffc107));
}
@keyframes mdc-checkbox-fade-in-background-8A000000FFFFC10700000000FFFFC107 {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
  50% {
    border-color: #ffc107;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #ffc107));
    background-color: #ffc107;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #ffc107));
  }
}
@keyframes mdc-checkbox-fade-out-background-8A000000FFFFC10700000000FFFFC107 {
  0%, 80% {
    border-color: #ffc107;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #ffc107));
    background-color: #ffc107;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #ffc107));
  }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
}
.mdc-checkbox.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-in-background-8A000000FFFFC10700000000FFFFC107;
}
.mdc-checkbox.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-out-background-8A000000FFFFC10700000000FFFFC107;
}
.mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  border-color: var(--mdc-checkbox-disabled-color, rgba(0, 0, 0, 0.38));
  background-color: transparent;
}
.mdc-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true][disabled] ~ .mdc-checkbox__background {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  background-color: var(--mdc-checkbox-disabled-color, rgba(0, 0, 0, 0.38));
}
.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #263238;
  /* @alternate */
  color: var(--mdc-checkbox-ink-color, #263238);
}
.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #263238;
  /* @alternate */
  border-color: var(--mdc-checkbox-ink-color, #263238);
}
.mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #263238;
  /* @alternate */
  color: var(--mdc-checkbox-ink-color, #263238);
}
.mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #263238;
  /* @alternate */
  border-color: var(--mdc-checkbox-ink-color, #263238);
}

.mdc-touch-target-wrapper {
  display: inline;
}

@keyframes mdc-checkbox-unchecked-checked-checkmark-path {
  0%, 50% {
    stroke-dashoffset: 29.7833385;
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
  0%, 68.2% {
    transform: scaleX(0);
  }
  68.2% {
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes mdc-checkbox-checked-unchecked-checkmark-path {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    stroke-dashoffset: 0;
  }
  to {
    opacity: 0;
    stroke-dashoffset: -29.7833385;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-checkmark {
  from {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(45deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-checkmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(45deg);
    opacity: 0;
  }
  to {
    transform: rotate(360deg);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-mixedmark {
  from {
    animation-timing-function: mdc-animation-deceleration-curve-timing-function;
    transform: rotate(-45deg);
    opacity: 0;
  }
  to {
    transform: rotate(0deg);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-mixedmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(315deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    animation-timing-function: linear;
    transform: scaleX(1);
    opacity: 1;
  }
  32.8%, 100% {
    transform: scaleX(0);
    opacity: 0;
  }
}
.mdc-checkbox {
  display: inline-block;
  position: relative;
  flex: 0 0 18px;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom;
}
.mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__focus-ring, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}
@media screen and (forced-colors: active) {
  .mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__focus-ring, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__focus-ring::after, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__focus-ring::after, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__focus-ring::after {
    border-color: CanvasText;
  }
}
@media all and (-ms-high-contrast: none) {
  .mdc-checkbox .mdc-checkbox__focus-ring {
    display: none;
  }
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-checkbox__mixedmark {
    margin: 0 1px;
  }
}
.mdc-checkbox--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-checkbox__background {
  display: inline-flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border: 2px solid currentColor;
  border-radius: 2px;
  background-color: transparent;
  pointer-events: none;
  will-change: background-color, border-color;
  transition: background-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox__checkmark {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-checkbox--upgraded .mdc-checkbox__checkmark {
  opacity: 1;
}

.mdc-checkbox__checkmark-path {
  transition: stroke-dashoffset 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke: currentColor;
  stroke-width: 3.12px;
  stroke-dashoffset: 29.7833385;
  stroke-dasharray: 29.7833385;
}

.mdc-checkbox__mixedmark {
  width: 100%;
  height: 0;
  transform: scaleX(0) rotate(0deg);
  border-width: 1px;
  border-style: solid;
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__background, .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__background {
  animation-duration: 180ms;
  animation-timing-function: linear;
}
.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-unchecked-checked-checkmark-path 180ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-unchecked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-checked-unchecked-checkmark-path 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__checkmark {
  animation: mdc-checkbox-checked-indeterminate-checkmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-checked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__checkmark {
  animation: mdc-checkbox-indeterminate-checked-checkmark 500ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-checked-mixedmark 500ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-unchecked-mixedmark 300ms linear 0s;
  transition: none;
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background {
  transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path {
  stroke-dashoffset: 0;
}

.mdc-checkbox__native-control {
  position: absolute;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;
}
.mdc-checkbox__native-control:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-checkbox--touch {
  margin: calc((48px - 40px) / 2);
  /* @alternate */
  margin: calc((var(--mdc-checkbox-state-layer-size, 48px) - var(--mdc-checkbox-state-layer-size, 40px)) / 2);
}
.mdc-checkbox--touch .mdc-checkbox__native-control {
  top: calc((40px - 48px) / 2);
  /* @alternate */
  top: calc((var(--mdc-checkbox-state-layer-size, 40px) - var(--mdc-checkbox-state-layer-size, 48px)) / 2);
  right: calc((40px - 48px) / 2);
  /* @alternate */
  right: calc((var(--mdc-checkbox-state-layer-size, 40px) - var(--mdc-checkbox-state-layer-size, 48px)) / 2);
  left: calc((40px - 48px) / 2);
  /* @alternate */
  left: calc((var(--mdc-checkbox-state-layer-size, 40px) - var(--mdc-checkbox-state-layer-size, 48px)) / 2);
  width: 48px;
  /* @alternate */
  width: var(--mdc-checkbox-state-layer-size, 48px);
  height: 48px;
  /* @alternate */
  height: var(--mdc-checkbox-state-layer-size, 48px);
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
}
.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(-45deg);
}

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(0deg);
  opacity: 1;
}

.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__background,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__checkmark,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__checkmark-path,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__mixedmark {
  transition: none;
}

.mdc-checkbox {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-checkbox .mdc-checkbox__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-checkbox .mdc-checkbox__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-checkbox.mdc-ripple-upgraded--unbounded .mdc-checkbox__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-checkbox.mdc-ripple-upgraded--foreground-activation .mdc-checkbox__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-checkbox.mdc-ripple-upgraded--foreground-deactivation .mdc-checkbox__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::before,
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-checkbox {
  z-index: 0;
}
.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  z-index: -1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, -1);
}

.mdc-checkbox__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-evolution-chip,
.mdc-evolution-chip__cell,
.mdc-evolution-chip__action {
  display: inline-flex;
  align-items: center;
}

.mdc-evolution-chip {
  position: relative;
  max-width: 100%;
}
.mdc-evolution-chip .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}

.mdc-evolution-chip__cell,
.mdc-evolution-chip__action {
  height: 100%;
}

.mdc-evolution-chip__cell--primary {
  overflow-x: hidden;
}

.mdc-evolution-chip__cell--trailing {
  flex: 1 0 auto;
}

.mdc-evolution-chip__action {
  align-items: center;
  background: none;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  outline: none;
  padding: 0;
  text-decoration: none;
  color: inherit;
}

.mdc-evolution-chip__action--presentational {
  cursor: auto;
}

.mdc-evolution-chip--disabled,
.mdc-evolution-chip__action:disabled {
  pointer-events: none;
}

.mdc-evolution-chip__action--primary {
  overflow-x: hidden;
}

.mdc-evolution-chip__action--trailing {
  position: relative;
  overflow: visible;
}

.mdc-evolution-chip__action--primary:before {
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  pointer-events: none;
  top: 0;
  width: 100%;
  z-index: 1;
}

.mdc-evolution-chip--touch {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mdc-evolution-chip__action-touch {
  position: absolute;
  top: 50%;
  height: 48px;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.mdc-evolution-chip__text-label {
  white-space: nowrap;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mdc-evolution-chip__graphic {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  overflow: hidden;
  pointer-events: none;
  position: relative;
  flex: 1 0 auto;
}

.mdc-evolution-chip__checkmark {
  position: absolute;
  opacity: 0;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
}

.mdc-evolution-chip--selectable:not(.mdc-evolution-chip--selected):not(.mdc-evolution-chip--with-primary-icon) .mdc-evolution-chip__graphic {
  width: 0;
}

.mdc-evolution-chip__checkmark-background {
  opacity: 0;
}

.mdc-evolution-chip__checkmark-svg {
  display: block;
}

.mdc-evolution-chip__checkmark-path {
  stroke-width: 2px;
  stroke-dasharray: 29.7833385;
  stroke-dashoffset: 29.7833385;
  stroke: currentColor;
}

.mdc-evolution-chip--selecting .mdc-evolution-chip__graphic {
  transition: width 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-evolution-chip--selecting .mdc-evolution-chip__checkmark {
  transition: transform 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate(-75%, -50%);
}
.mdc-evolution-chip--selecting .mdc-evolution-chip__checkmark-path {
  transition: stroke-dashoffset 150ms 45ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-evolution-chip--deselecting .mdc-evolution-chip__graphic {
  transition: width 100ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-evolution-chip--deselecting .mdc-evolution-chip__checkmark {
  transition: opacity 50ms 0ms linear, transform 100ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate(-75%, -50%);
}
.mdc-evolution-chip--deselecting .mdc-evolution-chip__checkmark-path {
  stroke-dashoffset: 0;
}

.mdc-evolution-chip--selecting-with-primary-icon .mdc-evolution-chip__icon--primary {
  transition: opacity 75ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-evolution-chip--selecting-with-primary-icon .mdc-evolution-chip__checkmark-path {
  transition: stroke-dashoffset 150ms 75ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-evolution-chip--deselecting-with-primary-icon .mdc-evolution-chip__icon--primary {
  transition: opacity 150ms 75ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-evolution-chip--deselecting-with-primary-icon .mdc-evolution-chip__checkmark {
  transition: opacity 75ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate(-50%, -50%);
}
.mdc-evolution-chip--deselecting-with-primary-icon .mdc-evolution-chip__checkmark-path {
  stroke-dashoffset: 0;
}

.mdc-evolution-chip--selected .mdc-evolution-chip__icon--primary {
  opacity: 0;
}
.mdc-evolution-chip--selected .mdc-evolution-chip__checkmark {
  transform: translate(-50%, -50%);
  opacity: 1;
}
.mdc-evolution-chip--selected .mdc-evolution-chip__checkmark-path {
  stroke-dashoffset: 0;
}

@keyframes mdc-evolution-chip-enter {
  from {
    transform: scale(0.8);
    opacity: 0.4;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.mdc-evolution-chip--enter {
  animation: mdc-evolution-chip-enter 100ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

@keyframes mdc-evolution-chip-exit {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.mdc-evolution-chip--exit {
  animation: mdc-evolution-chip-exit 75ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-evolution-chip--hidden {
  opacity: 0;
  pointer-events: none;
  transition: width 150ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-evolution-chip {
  height: 32px;
  border-radius: 16px;
  background-color: #e0e0e0;
}
.mdc-evolution-chip .mdc-evolution-chip__ripple {
  border-radius: 16px;
}
.mdc-evolution-chip .mdc-evolution-chip__action--primary:before {
  border-radius: 16px;
}
.mdc-evolution-chip .mdc-evolution-chip__icon--primary {
  border-radius: 16px;
}
.mdc-evolution-chip .mdc-evolution-chip__action--primary {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}
[dir=rtl] .mdc-evolution-chip .mdc-evolution-chip__action--primary, .mdc-evolution-chip .mdc-evolution-chip__action--primary[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 6px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 6px;
}
[dir=rtl] .mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic, .mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 6px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 6px;
  /*rtl:end:ignore*/
}

.mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}
[dir=rtl] .mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary, .mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-evolution-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
}
[dir=rtl] .mdc-evolution-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing, .mdc-evolution-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-evolution-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__ripple--trailing {
  /* @noflip */ /*rtl:ignore*/
  left: 8px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .mdc-evolution-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__ripple--trailing, .mdc-evolution-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__ripple--trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 8px;
  /*rtl:end:ignore*/
}

.mdc-evolution-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-evolution-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary, .mdc-evolution-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__graphic {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 6px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 6px;
}
[dir=rtl] .mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__graphic, .mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 6px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 6px;
  /*rtl:end:ignore*/
}

.mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
}
[dir=rtl] .mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing, .mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__ripple--trailing {
  /* @noflip */ /*rtl:ignore*/
  left: 8px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__ripple--trailing, .mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__ripple--trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 8px;
  /*rtl:end:ignore*/
}

.mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary, .mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-evolution-chip .mdc-evolution-chip__text-label {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-evolution-chip .mdc-evolution-chip__icon--primary {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-evolution-chip .mdc-evolution-chip__checkmark {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-evolution-chip .mdc-evolution-chip__icon--trailing {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-evolution-chip .mdc-evolution-chip__text-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}
.mdc-evolution-chip.mdc-evolution-chip--selectable:not(.mdc-evolution-chip--with-primary-icon) {
  --mdc-chip-graphic-selected-width: 20px;
}
.mdc-evolution-chip .mdc-evolution-chip__graphic {
  height: 20px;
  width: 20px;
  font-size: 20px;
}
.mdc-evolution-chip .mdc-evolution-chip__icon--primary {
  height: 20px;
  width: 20px;
  font-size: 20px;
}
.mdc-evolution-chip .mdc-evolution-chip__checkmark {
  height: 20px;
  width: 20px;
}
.mdc-evolution-chip .mdc-evolution-chip__icon--trailing {
  height: 18px;
  width: 18px;
  font-size: 18px;
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-evolution-chip .mdc-evolution-chip__action--primary:before {
    border-color: CanvasText;
  }
  .mdc-evolution-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__action--primary:before {
    border-color: GrayText;
  }
  .mdc-evolution-chip.mdc-evolution-chip--selected .mdc-evolution-chip__action--primary:before {
    border-color: CanvasText;
  }
  .mdc-evolution-chip.mdc-evolution-chip--selected.mdc-evolution-chip--disabled .mdc-evolution-chip__action--primary:before {
    border-color: GrayText;
  }
  .mdc-evolution-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__text-label {
    color: GrayText;
  }
  .mdc-evolution-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__icon--primary {
    color: GrayText;
  }
  .mdc-evolution-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__checkmark {
    color: GrayText;
  }
  .mdc-evolution-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__icon--trailing {
    color: GrayText;
  }
}
.mdc-evolution-chip .mdc-evolution-chip__focus-ring {
  display: none;
}
.mdc-evolution-chip .mdc-evolution-chip__action--primary:not(.mdc-evolution-chip__action--presentational).mdc-ripple-upgraded--background-focused .mdc-evolution-chip__focus-ring, .mdc-evolution-chip .mdc-evolution-chip__action--primary:not(.mdc-evolution-chip__action--presentational):not(.mdc-ripple-upgraded):focus .mdc-evolution-chip__focus-ring {
  z-index: 1;
  display: block;
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(
      100% + 4px
    );
  width: calc(
      100% + 4px
    );
}
@media screen and (forced-colors: active) {
  .mdc-evolution-chip .mdc-evolution-chip__action--primary:not(.mdc-evolution-chip__action--presentational).mdc-ripple-upgraded--background-focused .mdc-evolution-chip__focus-ring, .mdc-evolution-chip .mdc-evolution-chip__action--primary:not(.mdc-evolution-chip__action--presentational):not(.mdc-ripple-upgraded):focus .mdc-evolution-chip__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-evolution-chip .mdc-evolution-chip__action--primary:not(.mdc-evolution-chip__action--presentational).mdc-ripple-upgraded--background-focused .mdc-evolution-chip__focus-ring::after, .mdc-evolution-chip .mdc-evolution-chip__action--primary:not(.mdc-evolution-chip__action--presentational):not(.mdc-ripple-upgraded):focus .mdc-evolution-chip__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .mdc-evolution-chip .mdc-evolution-chip__action--primary:not(.mdc-evolution-chip__action--presentational).mdc-ripple-upgraded--background-focused .mdc-evolution-chip__focus-ring::after, .mdc-evolution-chip .mdc-evolution-chip__action--primary:not(.mdc-evolution-chip__action--presentational):not(.mdc-ripple-upgraded):focus .mdc-evolution-chip__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-evolution-chip .mdc-evolution-chip__action--trailing:not(.mdc-evolution-chip__action--presentational).mdc-ripple-upgraded--background-focused .mdc-evolution-chip__focus-ring, .mdc-evolution-chip .mdc-evolution-chip__action--trailing:not(.mdc-evolution-chip__action--presentational):not(.mdc-ripple-upgraded):focus .mdc-evolution-chip__focus-ring {
  z-index: 1;
  display: block;
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(
      100% + 4px
    );
  width: calc(
      100% + -4px
    );
}
@media screen and (forced-colors: active) {
  .mdc-evolution-chip .mdc-evolution-chip__action--trailing:not(.mdc-evolution-chip__action--presentational).mdc-ripple-upgraded--background-focused .mdc-evolution-chip__focus-ring, .mdc-evolution-chip .mdc-evolution-chip__action--trailing:not(.mdc-evolution-chip__action--presentational):not(.mdc-ripple-upgraded):focus .mdc-evolution-chip__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-evolution-chip .mdc-evolution-chip__action--trailing:not(.mdc-evolution-chip__action--presentational).mdc-ripple-upgraded--background-focused .mdc-evolution-chip__focus-ring::after, .mdc-evolution-chip .mdc-evolution-chip__action--trailing:not(.mdc-evolution-chip__action--presentational):not(.mdc-ripple-upgraded):focus .mdc-evolution-chip__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .mdc-evolution-chip .mdc-evolution-chip__action--trailing:not(.mdc-evolution-chip__action--presentational).mdc-ripple-upgraded--background-focused .mdc-evolution-chip__focus-ring::after, .mdc-evolution-chip .mdc-evolution-chip__action--trailing:not(.mdc-evolution-chip__action--presentational):not(.mdc-ripple-upgraded):focus .mdc-evolution-chip__focus-ring::after {
    border-color: CanvasText;
  }
}

.mdc-evolution-chip--filter.mdc-evolution-chip--selected {
  background-color: #cecece;
}
.mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
}
[dir=rtl] .mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic, .mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}
[dir=rtl] .mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary, .mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__graphic {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
}
[dir=rtl] .mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__graphic, .mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
}
[dir=rtl] .mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing, .mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__ripple--trailing {
  /* @noflip */ /*rtl:ignore*/
  left: 8px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__ripple--trailing, .mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__ripple--trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 8px;
  /*rtl:end:ignore*/
}

.mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary, .mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-evolution-chip--with-avatar.mdc-evolution-chip--selectable:not(.mdc-evolution-chip--with-primary-icon) {
  --mdc-chip-graphic-selected-width: 24px;
}
.mdc-evolution-chip--with-avatar .mdc-evolution-chip__graphic {
  height: 24px;
  width: 24px;
  font-size: 24px;
}
.mdc-evolution-chip--with-avatar .mdc-evolution-chip__icon--primary {
  height: 24px;
  width: 24px;
  font-size: 24px;
}

.mdc-evolution-chip__action--primary .mdc-evolution-chip__ripple::before, .mdc-evolution-chip__action--primary .mdc-evolution-chip__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}
.mdc-evolution-chip__action--primary:hover .mdc-evolution-chip__ripple::before, .mdc-evolution-chip__action--primary.mdc-ripple-surface--hover .mdc-evolution-chip__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-evolution-chip__action--primary.mdc-ripple-upgraded--background-focused .mdc-evolution-chip__ripple::before, .mdc-evolution-chip__action--primary:not(.mdc-ripple-upgraded):focus .mdc-evolution-chip__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-evolution-chip__action--primary:not(.mdc-ripple-upgraded) .mdc-evolution-chip__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-evolution-chip__action--primary:not(.mdc-ripple-upgraded):active .mdc-evolution-chip__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-evolution-chip__action--primary.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-evolution-chip__action--trailing .mdc-evolution-chip__ripple::before, .mdc-evolution-chip__action--trailing .mdc-evolution-chip__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}
.mdc-evolution-chip__action--trailing:hover .mdc-evolution-chip__ripple::before, .mdc-evolution-chip__action--trailing.mdc-ripple-surface--hover .mdc-evolution-chip__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-evolution-chip__action--trailing.mdc-ripple-upgraded--background-focused .mdc-evolution-chip__ripple::before, .mdc-evolution-chip__action--trailing:not(.mdc-ripple-upgraded):focus .mdc-evolution-chip__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-evolution-chip__action--trailing:not(.mdc-ripple-upgraded) .mdc-evolution-chip__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-evolution-chip__action--trailing:not(.mdc-ripple-upgraded):active .mdc-evolution-chip__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-evolution-chip__action--trailing.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-evolution-chip__ripple--trailing {
  height: 18px;
  width: 18px;
}

.mdc-evolution-chip__action {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mdc-evolution-chip__action .mdc-evolution-chip__ripple::before,
.mdc-evolution-chip__action .mdc-evolution-chip__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-evolution-chip__action .mdc-evolution-chip__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-evolution-chip__action .mdc-evolution-chip__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-evolution-chip__action.mdc-ripple-upgraded .mdc-evolution-chip__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-evolution-chip__action.mdc-ripple-upgraded .mdc-evolution-chip__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-evolution-chip__action.mdc-ripple-upgraded--unbounded .mdc-evolution-chip__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-evolution-chip__action.mdc-ripple-upgraded--foreground-activation .mdc-evolution-chip__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-evolution-chip__action.mdc-ripple-upgraded--foreground-deactivation .mdc-evolution-chip__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-evolution-chip__action .mdc-evolution-chip__ripple::before,
.mdc-evolution-chip__action .mdc-evolution-chip__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-evolution-chip__action.mdc-ripple-upgraded .mdc-evolution-chip__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-evolution-chip__ripple {
  position: absolute;
  box-sizing: content-box;
  overflow: hidden;
  pointer-events: none;
}

.mdc-evolution-chip__ripple--primary {
  height: 100%;
  width: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}

.mdc-evolution-chip__ripple--trailing {
  top: 50%;
  transform: translateY(-50%);
}

.mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mdc-data-table {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  -webkit-overflow-scrolling: touch;
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
}
.mdc-data-table .mdc-data-table__header-cell:first-child {
  border-top-left-radius: 4px;
  /* @alternate */
  border-top-left-radius: var(--mdc-shape-medium, 4px);
}
[dir=rtl] .mdc-data-table .mdc-data-table__header-cell:first-child, .mdc-data-table .mdc-data-table__header-cell:first-child[dir=rtl] {
  /*rtl:begin:ignore*/
  border-top-right-radius: 4px;
  /* @alternate */
  border-top-right-radius: var(--mdc-shape-medium, 4px);
  border-top-left-radius: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table .mdc-data-table__header-cell:last-child {
  border-top-right-radius: 4px;
  /* @alternate */
  border-top-right-radius: var(--mdc-shape-medium, 4px);
}
[dir=rtl] .mdc-data-table .mdc-data-table__header-cell:last-child, .mdc-data-table .mdc-data-table__header-cell:last-child[dir=rtl] {
  /*rtl:begin:ignore*/
  border-top-left-radius: 4px;
  /* @alternate */
  border-top-left-radius: var(--mdc-shape-medium, 4px);
  border-top-right-radius: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:first-child {
  border-bottom-left-radius: 4px;
  /* @alternate */
  border-bottom-left-radius: var(--mdc-shape-medium, 4px);
}
[dir=rtl] .mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:first-child, .mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:first-child[dir=rtl] {
  /*rtl:begin:ignore*/
  border-bottom-right-radius: 4px;
  /* @alternate */
  border-bottom-right-radius: var(--mdc-shape-medium, 4px);
  border-bottom-left-radius: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:last-child {
  border-bottom-right-radius: 4px;
  /* @alternate */
  border-bottom-right-radius: var(--mdc-shape-medium, 4px);
}
[dir=rtl] .mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:last-child, .mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:last-child[dir=rtl] {
  /*rtl:begin:ignore*/
  border-bottom-left-radius: 4px;
  /* @alternate */
  border-bottom-left-radius: var(--mdc-shape-medium, 4px);
  border-bottom-right-radius: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table__row {
  background-color: inherit;
}

.mdc-data-table__header-cell {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-data-table__row--selected {
  background-color: rgba(55, 71, 79, 0.04);
}

.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.mdc-data-table__pagination {
  border-top-width: 1px;
  border-top-style: solid;
}

.mdc-data-table__row:last-child .mdc-data-table__cell {
  border-bottom: none;
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__pagination-total,
.mdc-data-table__pagination-rows-per-page-label,
.mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__row {
  height: 52px;
}

.mdc-data-table__pagination {
  min-height: 52px;
}

.mdc-data-table__header-row {
  height: 56px;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0 16px 0 16px;
}

.mdc-data-table__header-cell--checkbox,
.mdc-data-table__cell--checkbox {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-data-table__header-cell--checkbox, [dir=rtl] .mdc-data-table__cell--checkbox, .mdc-data-table__header-cell--checkbox[dir=rtl], .mdc-data-table__cell--checkbox[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-data-table__table-container {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  width: 100%;
}

.mdc-data-table__table {
  min-width: 100%;
  border: 0;
  white-space: nowrap;
  border-spacing: 0;
  /**
   * With table-layout:fixed, table and column widths are defined by the width
   * of the first row of cells. Cells in subsequent rows do not affect column
   * widths. This results in a predictable table layout and may also speed up
   * rendering.
   */
  table-layout: fixed;
}

.mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  box-sizing: border-box;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}
[dir=rtl] .mdc-data-table__cell, .mdc-data-table__cell[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-data-table__cell--numeric {
  text-align: right;
}
[dir=rtl] .mdc-data-table__cell--numeric, .mdc-data-table__cell--numeric[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
  /*rtl:end:ignore*/
}

.mdc-data-table__cell--checkbox {
  width: 1px;
}

.mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
}
[dir=rtl] .mdc-data-table__header-cell, .mdc-data-table__header-cell[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-data-table__header-cell--checkbox {
  width: 1px;
}

.mdc-data-table__header-cell--numeric {
  text-align: right;
}
[dir=rtl] .mdc-data-table__header-cell--numeric, .mdc-data-table__header-cell--numeric[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
  /*rtl:end:ignore*/
}

.mdc-data-table__sort-icon-button {
  width: 28px;
  height: 28px;
  padding: 2px;
  transform: rotate(0.0001deg);
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  transition: transform 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
}
.mdc-data-table__sort-icon-button .mdc-icon-button__focus-ring {
  display: none;
}
.mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  display: block;
  max-height: 28px;
  max-width: 28px;
}
@media screen and (forced-colors: active) {
  .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    pointer-events: none;
    border: 2px solid transparent;
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    border-color: CanvasText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    content: "";
    border: 2px solid transparent;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-data-table__sort-icon-button.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 28px;
  height: 28px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mdc-data-table__sort-icon-button.mdc-icon-button--reduced-size.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-data-table__sort-icon-button.mdc-icon-button--reduced-size:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  max-height: 28px;
  max-width: 28px;
}
.mdc-data-table__sort-icon-button .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 28px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 28px;
  transform: translate(-50%, -50%);
}
[dir=rtl] .mdc-data-table__sort-icon-button, .mdc-data-table__sort-icon-button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-data-table__header-cell--numeric .mdc-data-table__sort-icon-button {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
}
[dir=rtl] .mdc-data-table__header-cell--numeric .mdc-data-table__sort-icon-button, .mdc-data-table__header-cell--numeric .mdc-data-table__sort-icon-button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table__header-cell--sorted-descending .mdc-data-table__sort-icon-button {
  transform: rotate(-180deg);
}
.mdc-data-table__sort-icon-button:focus, .mdc-data-table__header-cell:hover .mdc-data-table__sort-icon-button, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button {
  opacity: 1;
}

.mdc-data-table__header-cell-wrapper {
  align-items: center;
  display: inline-flex;
  vertical-align: middle;
}

.mdc-data-table__header-cell--with-sort {
  cursor: pointer;
}

.mdc-data-table__sort-status-label {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; /* added line */
  width: 1px;
}

.mdc-data-table--sticky-header .mdc-data-table__header-cell {
  position: sticky;
  top: 0;
  z-index: 1;
}

.mdc-data-table__sort-icon-button {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-data-table__sort-icon-button .mdc-icon-button__ripple::before, .mdc-data-table__sort-icon-button .mdc-icon-button__ripple::after {
  background-color: rgba(0, 0, 0, 0.6);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.6));
}
.mdc-data-table__sort-icon-button:hover .mdc-icon-button__ripple::before, .mdc-data-table__sort-icon-button.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-data-table__sort-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button .mdc-icon-button__ripple::before, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button .mdc-icon-button__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:hover .mdc-icon-button__ripple::before, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-data-table__progress-indicator {
  display: none;
  position: absolute;
  width: 100%;
}
.mdc-data-table--in-progress .mdc-data-table__progress-indicator {
  display: block;
}

.mdc-data-table__scrim {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  height: 100%;
  opacity: 0.32;
  position: absolute;
  top: 0;
  width: 100%;
}

.mdc-data-table__pagination {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

.mdc-data-table__pagination-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
[dir=rtl] .mdc-data-table__pagination-trailing, .mdc-data-table__pagination-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-data-table__pagination-navigation {
  align-items: center;
  display: flex;
}

.mdc-data-table__pagination-button {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
}
[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: rotate(180deg);
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-data-table__pagination-button, .mdc-data-table__pagination-button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table__pagination-total {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 14px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 36px;
  white-space: nowrap;
}
[dir=rtl] .mdc-data-table__pagination-total, .mdc-data-table__pagination-total[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 36px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 14px;
  /*rtl:end:ignore*/
}

.mdc-data-table__pagination-rows-per-page {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 22px;
  align-items: center;
  display: inline-flex;
}
[dir=rtl] .mdc-data-table__pagination-rows-per-page, .mdc-data-table__pagination-rows-per-page[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 22px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table__pagination-rows-per-page-label {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  white-space: nowrap;
}
[dir=rtl] .mdc-data-table__pagination-rows-per-page-label, .mdc-data-table__pagination-rows-per-page-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table__pagination-rows-per-page-select {
  min-width: 80px;
  /* @alternate */
  min-width: var(--mdc-menu-min-width, 80px);
  margin: 8px 0;
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor {
  width: 100%;
  min-width: 80px;
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor {
  height: 36px;
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-27.25px) scale(1);
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-24.75px) scale(0.75);
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-36px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-36px {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__dropdown-icon {
  width: 20px;
  height: 20px;
}
.mdc-data-table__pagination-rows-per-page-select.mdc-select--outlined .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 56px);
}
.mdc-data-table__pagination-rows-per-page-select .mdc-list-item.mdc-list-item--with-one-line {
  height: 36px;
}

.mdc-data-table__header-row-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::before, .mdc-data-table__header-row-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::after,
.mdc-data-table__row-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #37474f));
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--selected:hover .mdc-checkbox__ripple::before, .mdc-data-table__header-row-checkbox.mdc-checkbox--selected.mdc-ripple-surface--hover .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected:hover .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected.mdc-ripple-surface--hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-data-table__header-row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after,
.mdc-data-table__row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after,
.mdc-data-table__row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded,
.mdc-data-table__row-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-data-table__header-row-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-data-table__header-row-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::after,
.mdc-data-table__row-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #37474f));
}
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
  background-color: transparent;
}
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
  border-color: #37474f;
  /* @alternate */
  border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #37474f));
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #37474f));
}
@keyframes mdc-checkbox-fade-in-background-8A000000FF37474F00000000FF37474F {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
  50% {
    border-color: #37474f;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #37474f));
    background-color: #37474f;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #37474f));
  }
}
@keyframes mdc-checkbox-fade-out-background-8A000000FF37474F00000000FF37474F {
  0%, 80% {
    border-color: #37474f;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #37474f));
    background-color: #37474f;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #37474f));
  }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-data-table__header-row-checkbox.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-in-background-8A000000FF37474F00000000FF37474F;
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-data-table__header-row-checkbox.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-out-background-8A000000FF37474F00000000FF37474F;
}

.mdc-dialog .mdc-dialog__surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}
.mdc-dialog .mdc-dialog__scrim {
  background-color: rgba(0, 0, 0, 0.32);
}
.mdc-dialog .mdc-dialog__surface-scrim {
  background-color: rgba(0, 0, 0, 0.32);
}
.mdc-dialog .mdc-dialog__title {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-dialog .mdc-dialog__content {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-dialog .mdc-dialog__close {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-dialog .mdc-dialog__close .mdc-icon-button__ripple::before, .mdc-dialog .mdc-dialog__close .mdc-icon-button__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}
.mdc-dialog .mdc-dialog__close:hover .mdc-icon-button__ripple::before, .mdc-dialog .mdc-dialog__close.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-dialog .mdc-dialog__close.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, .mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-dialog .mdc-dialog__close.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title, .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__actions, .mdc-dialog.mdc-dialog--scrollable.mdc-dialog-scroll-divider-footer .mdc-dialog__actions {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 0;
}
.mdc-dialog.mdc-dialog-scroll-divider-header.mdc-dialog--fullscreen .mdc-dialog__header {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mdc-dialog .mdc-dialog__surface {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
}

.mdc-dialog__surface {
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mdc-dialog__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
}

.mdc-dialog__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: 0.03125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
}

.mdc-dialog__title-icon {
  /** Hook for theming API. */
}

.mdc-dialog,
.mdc-dialog__scrim {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.mdc-dialog {
  display: none;
  z-index: 7;
  /* @alternate */
  z-index: var(--mdc-dialog-z-index, 7);
}
.mdc-dialog .mdc-dialog__content {
  padding: 20px 24px 20px 24px;
}
.mdc-dialog .mdc-dialog__surface {
  min-width: 280px;
}
@media (max-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: calc(100vw - 32px);
  }
}
@media (min-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: 560px;
  }
}
.mdc-dialog .mdc-dialog__surface {
  max-height: calc(100% - 32px);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mdc-dialog .mdc-dialog__container {
    /* stylelint-disable */
    /* stylelint-enable*/
  }
}
.mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
  max-width: none;
}
@media (max-width: 960px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: 560px;
    width: 560px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
@media (max-width: 720px) and (max-width: 672px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    width: calc(100vw - 112px);
  }
}
@media (max-width: 720px) and (min-width: 672px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    width: 560px;
  }
}
@media (max-width: 720px) and (max-height: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: calc(100vh - 160px);
  }
}
@media (max-width: 720px) and (min-height: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: 560px;
  }
}
@media (max-width: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
@media (max-width: 720px) and (max-height: 400px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    width: 100vw;
    border-radius: 0;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    order: -1;
    left: -12px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__header {
    padding: 0 16px 9px;
    justify-content: flex-start;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__title {
    margin-left: calc(16px - 2 * 12px);
  }
}
@media (max-width: 600px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    width: 100vw;
    border-radius: 0;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    order: -1;
    left: -12px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__header {
    padding: 0 16px 9px;
    justify-content: flex-start;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__title {
    margin-left: calc(16px - 2 * 12px);
  }
}
@media (min-width: 960px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    width: calc(100vw - 400px);
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
.mdc-dialog.mdc-dialog__scrim--hidden .mdc-dialog__scrim {
  opacity: 0;
}

.mdc-dialog__scrim {
  opacity: 0;
  z-index: -1;
}

.mdc-dialog__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  height: 100%;
  transform: scale(0.8);
  opacity: 0;
  pointer-events: none;
}

.mdc-dialog__surface {
  /* @alternate */
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  pointer-events: auto;
  overflow-y: auto;
}
.mdc-dialog__surface .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}
[dir=rtl] .mdc-dialog__surface, .mdc-dialog__surface[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-dialog__surface {
    outline: 2px solid windowText;
  }
}
.mdc-dialog__surface::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 2px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-dialog__surface::before {
    border-color: CanvasText;
  }
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .mdc-dialog__surface::before {
    content: none;
  }
}

.mdc-dialog__title {
  display: block;
  margin-top: 0;
  /* @alternate */
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0 0 1px;
  padding: 0 24px 9px;
}
.mdc-dialog__title::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
[dir=rtl] .mdc-dialog__title, .mdc-dialog__title[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-dialog--scrollable .mdc-dialog__title {
  margin-bottom: 1px;
  padding-bottom: 15px;
}

.mdc-dialog--fullscreen .mdc-dialog__header {
  align-items: baseline;
  border-bottom: 1px solid transparent;
  display: inline-flex;
  justify-content: space-between;
  padding: 0 24px 9px;
  z-index: 1;
}
@media screen and (forced-colors: active) {
  .mdc-dialog--fullscreen .mdc-dialog__header {
    border-bottom-color: CanvasText;
  }
}
.mdc-dialog--fullscreen .mdc-dialog__header .mdc-dialog__close {
  right: -12px;
}
.mdc-dialog--fullscreen .mdc-dialog__title {
  margin-bottom: 0;
  padding: 0;
  border-bottom: 0;
}
.mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__title {
  border-bottom: 0;
  margin-bottom: 0;
}
.mdc-dialog--fullscreen .mdc-dialog__close {
  top: 5px;
}
.mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__actions {
  border-top: 1px solid transparent;
}
@media screen and (forced-colors: active) {
  .mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__actions {
    border-top-color: CanvasText;
  }
}

.mdc-dialog__content {
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  overflow: auto;
}
.mdc-dialog__content > :first-child {
  margin-top: 0;
}
.mdc-dialog__content > :last-child {
  margin-bottom: 0;
}

.mdc-dialog__title + .mdc-dialog__content,
.mdc-dialog__header + .mdc-dialog__content {
  padding-top: 0;
}

.mdc-dialog--scrollable .mdc-dialog__title + .mdc-dialog__content {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mdc-dialog__content .mdc-deprecated-list:first-child:last-child {
  padding: 6px 0 0;
}

.mdc-dialog--scrollable .mdc-dialog__content .mdc-deprecated-list:first-child:last-child {
  padding: 0;
}

.mdc-dialog__actions {
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  min-height: 52px;
  margin: 0;
  padding: 8px;
  border-top: 1px solid transparent;
}
@media screen and (forced-colors: active) {
  .mdc-dialog__actions {
    border-top-color: CanvasText;
  }
}
.mdc-dialog--stacked .mdc-dialog__actions {
  flex-direction: column;
  align-items: flex-end;
}

.mdc-dialog__button {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  max-width: 100%;
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
}
[dir=rtl] .mdc-dialog__button, .mdc-dialog__button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-dialog__button:first-child {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}
[dir=rtl] .mdc-dialog__button:first-child, .mdc-dialog__button:first-child[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-dialog__button, .mdc-dialog__button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
  /*rtl:end:ignore*/
}

.mdc-dialog--stacked .mdc-dialog__button:not(:first-child) {
  margin-top: 12px;
}

.mdc-dialog--open,
.mdc-dialog--opening,
.mdc-dialog--closing {
  display: flex;
}

.mdc-dialog--opening .mdc-dialog__scrim {
  transition: opacity 150ms linear;
}
.mdc-dialog--opening .mdc-dialog__container {
  transition: opacity 75ms linear, transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-dialog--closing .mdc-dialog__scrim,
.mdc-dialog--closing .mdc-dialog__container {
  transition: opacity 75ms linear;
}
.mdc-dialog--closing .mdc-dialog__container {
  transform: none;
}

.mdc-dialog--open .mdc-dialog__scrim {
  opacity: 1;
}
.mdc-dialog--open .mdc-dialog__container {
  transform: none;
  opacity: 1;
}
.mdc-dialog--open.mdc-dialog__surface-scrim--shown .mdc-dialog__surface-scrim {
  opacity: 1;
  z-index: 1;
}
.mdc-dialog--open.mdc-dialog__surface-scrim--hiding .mdc-dialog__surface-scrim {
  transition: opacity 75ms linear;
}
.mdc-dialog--open.mdc-dialog__surface-scrim--showing .mdc-dialog__surface-scrim {
  transition: opacity 150ms linear;
}

.mdc-dialog__surface-scrim {
  display: none;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
.mdc-dialog__surface-scrim--shown .mdc-dialog__surface-scrim, .mdc-dialog__surface-scrim--showing .mdc-dialog__surface-scrim, .mdc-dialog__surface-scrim--hiding .mdc-dialog__surface-scrim {
  display: block;
}

.mdc-dialog-scroll-lock {
  overflow: hidden;
}

.mdc-dialog--no-content-padding .mdc-dialog__content {
  padding: 0;
}

.mdc-dialog--sheet .mdc-dialog__close {
  right: 12px;
  top: 9px;
  position: absolute;
  z-index: 1;
}

.mdc-fab {
  /* @alternate */
  position: relative;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  padding: 0;
  border: none;
  fill: currentColor;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  overflow: visible;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), opacity 15ms linear 30ms, transform 270ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.mdc-fab .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}
.mdc-fab::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mdc-fab:hover {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-fab.mdc-ripple-upgraded--background-focused, .mdc-fab:not(.mdc-ripple-upgraded):focus {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-fab .mdc-fab__focus-ring {
  position: absolute;
}
.mdc-fab.mdc-ripple-upgraded--background-focused .mdc-fab__focus-ring, .mdc-fab:not(.mdc-ripple-upgraded):focus .mdc-fab__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(
      100% + 4px
    );
  width: calc(
      100% + 4px
    );
}
@media screen and (forced-colors: active) {
  .mdc-fab.mdc-ripple-upgraded--background-focused .mdc-fab__focus-ring, .mdc-fab:not(.mdc-ripple-upgraded):focus .mdc-fab__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-fab.mdc-ripple-upgraded--background-focused .mdc-fab__focus-ring::after, .mdc-fab:not(.mdc-ripple-upgraded):focus .mdc-fab__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .mdc-fab.mdc-ripple-upgraded--background-focused .mdc-fab__focus-ring::after, .mdc-fab:not(.mdc-ripple-upgraded):focus .mdc-fab__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-fab:active, .mdc-fab:focus:active {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mdc-fab:active, .mdc-fab:focus {
  outline: none;
}
.mdc-fab:hover {
  cursor: pointer;
}
.mdc-fab > svg {
  width: 100%;
}

.mdc-fab--mini {
  width: 40px;
  height: 40px;
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
  border-radius: 24px;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 20px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 20px;
  width: auto;
  max-width: 100%;
  height: 48px;
  /* @alternate */
  line-height: normal;
}
.mdc-fab--extended .mdc-fab__ripple {
  border-radius: 24px;
}
.mdc-fab--extended .mdc-fab__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: calc(12px - 20px);
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
}
[dir=rtl] .mdc-fab--extended .mdc-fab__icon, .mdc-fab--extended .mdc-fab__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: calc(12px - 20px);
  /*rtl:end:ignore*/
}

.mdc-fab--extended .mdc-fab__label + .mdc-fab__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: calc(12px - 20px);
}
[dir=rtl] .mdc-fab--extended .mdc-fab__label + .mdc-fab__icon, .mdc-fab--extended .mdc-fab__label + .mdc-fab__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: calc(12px - 20px);
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-fab--touch {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mdc-fab--touch .mdc-fab__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mdc-fab::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-fab::before {
    border-color: CanvasText;
  }
}

.mdc-fab__label {
  justify-content: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: visible;
}

.mdc-fab__icon {
  transition: transform 180ms 90ms cubic-bezier(0, 0, 0.2, 1);
  fill: currentColor;
  will-change: transform;
}

.mdc-fab .mdc-fab__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.mdc-fab--exited {
  transform: scale(0);
  opacity: 0;
  transition: opacity 15ms linear 150ms, transform 180ms 0ms cubic-bezier(0.4, 0, 1, 1);
}
.mdc-fab--exited .mdc-fab__icon {
  transform: scale(0);
  transition: transform 135ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-fab {
  background-color: #ffc107;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #ffc107);
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mdc-fab .mdc-fab__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}
.mdc-fab, .mdc-fab:not(:disabled) .mdc-fab__icon, .mdc-fab:not(:disabled) .mdc-fab__label, .mdc-fab:disabled .mdc-fab__icon, .mdc-fab:disabled .mdc-fab__label {
  color: #263238;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #263238);
}
.mdc-fab:not(.mdc-fab--extended) {
  border-radius: 50%;
}
.mdc-fab:not(.mdc-fab--extended) .mdc-fab__ripple {
  border-radius: 50%;
}

.mdc-fab {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-fab .mdc-fab__ripple::before,
.mdc-fab .mdc-fab__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-fab .mdc-fab__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-fab .mdc-fab__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-fab.mdc-ripple-upgraded .mdc-fab__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-fab.mdc-ripple-upgraded .mdc-fab__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-fab.mdc-ripple-upgraded--unbounded .mdc-fab__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-fab.mdc-ripple-upgraded--foreground-activation .mdc-fab__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-fab.mdc-ripple-upgraded--foreground-deactivation .mdc-fab__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-fab .mdc-fab__ripple::before,
.mdc-fab .mdc-fab__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-fab.mdc-ripple-upgraded .mdc-fab__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-fab .mdc-fab__ripple::before, .mdc-fab .mdc-fab__ripple::after {
  background-color: #263238;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-secondary, #263238));
}
.mdc-fab:hover .mdc-fab__ripple::before, .mdc-fab.mdc-ripple-surface--hover .mdc-fab__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-fab.mdc-ripple-upgraded--background-focused .mdc-fab__ripple::before, .mdc-fab:not(.mdc-ripple-upgraded):focus .mdc-fab__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-fab:not(.mdc-ripple-upgraded) .mdc-fab__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-fab:not(.mdc-ripple-upgraded):active .mdc-fab__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-fab.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-fab .mdc-fab__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}
.mdc-fab {
  z-index: 0;
}
.mdc-fab .mdc-fab__ripple::before,
.mdc-fab .mdc-fab__ripple::after {
  z-index: -1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, -1);
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}
.mdc-form-field > label {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  order: 0;
}
[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: auto;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-form-field--nowrap > label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mdc-form-field--align-end > label {
  /* @noflip */ /*rtl:ignore*/
  margin-left: auto;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 4px;
  order: -1;
}
[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: auto;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-form-field--space-between {
  justify-content: space-between;
}
.mdc-form-field--space-between > label {
  margin: 0;
}
[dir=rtl] .mdc-form-field--space-between > label, .mdc-form-field--space-between > label[dir=rtl] {
  /*rtl:begin:ignore*/
  margin: 0;
  /*rtl:end:ignore*/
}

.mdc-text-field-helper-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  opacity: 0;
  will-change: opacity;
  transition: opacity 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-text-field-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial;
}

.mdc-text-field__icon {
  align-self: center;
  cursor: pointer;
}
.mdc-text-field__icon:not([tabindex]), .mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}
.mdc-text-field__icon svg {
  display: block;
}

.mdc-text-field__icon--leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}
[dir=rtl] .mdc-text-field__icon--leading, .mdc-text-field__icon--leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-text-field__icon--trailing {
  padding: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
}
[dir=rtl] .mdc-text-field__icon--trailing, .mdc-text-field__icon--trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mdc-deprecated-list:focus {
  outline: none;
}

.mdc-deprecated-list-item {
  height: 48px;
}

.mdc-deprecated-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-deprecated-list-item__graphic {
  background-color: transparent;
}

.mdc-deprecated-list-item__graphic {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-deprecated-list-item__meta {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-deprecated-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__text {
  opacity: 0.38;
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__text,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__primary-text,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__secondary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-deprecated-list-item--selected,
.mdc-deprecated-list-item--activated {
  color: #37474f;
  /* @alternate */
  color: var(--mdc-theme-primary, #37474f);
}
.mdc-deprecated-list-item--selected .mdc-deprecated-list-item__graphic,
.mdc-deprecated-list-item--activated .mdc-deprecated-list-item__graphic {
  color: #37474f;
  /* @alternate */
  color: var(--mdc-theme-primary, #37474f);
}

.mdc-deprecated-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.812rem;
}

.mdc-deprecated-list-item__wrapper {
  display: block;
}

.mdc-deprecated-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 48px;
}
.mdc-deprecated-list-item:focus {
  outline: none;
}
.mdc-deprecated-list-item:not(.mdc-deprecated-list-item--selected):focus::before, .mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-deprecated-list-item:not(.mdc-deprecated-list-item--selected):focus::before, .mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused::before {
    border-color: CanvasText;
  }
}
.mdc-deprecated-list-item.mdc-deprecated-list-item--selected::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px double transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-deprecated-list-item.mdc-deprecated-list-item--selected::before {
    border-color: CanvasText;
  }
}
[dir=rtl] .mdc-deprecated-list-item, .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-item, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 72px;
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-item, .mdc-deprecated-list--image-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 72px;
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-item, .mdc-deprecated-list--video-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 20px;
  height: 20px;
}
[dir=rtl] .mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list-item__graphic {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  object-fit: cover;
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 32px;
  width: 24px;
  height: 24px;
}
[dir=rtl] .mdc-deprecated-list-item__graphic, .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 32px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 32px;
  width: 24px;
  height: 24px;
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 32px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 40px;
  height: 40px;
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 56px;
  height: 56px;
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 100px;
  height: 56px;
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--video-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-item__graphic {
  display: inline-flex;
}

.mdc-deprecated-list-item__meta {
  /* @noflip */ /*rtl:ignore*/
  margin-left: auto;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}
.mdc-deprecated-list-item__meta:not(.material-icons) {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}
.mdc-deprecated-list-item[dir=rtl] .mdc-deprecated-list-item__meta, [dir=rtl] .mdc-deprecated-list-item .mdc-deprecated-list-item__meta {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: auto;
}

.mdc-deprecated-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-deprecated-list-item__text[for] {
  pointer-events: none;
}

.mdc-deprecated-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-deprecated-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-deprecated-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-deprecated-list--video-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-deprecated-list--video-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-deprecated-list--video-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-deprecated-list--dense .mdc-deprecated-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-deprecated-list--dense .mdc-deprecated-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-deprecated-list--dense .mdc-deprecated-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-deprecated-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-deprecated-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.mdc-deprecated-list--dense .mdc-deprecated-list-item__secondary-text {
  font-size: inherit;
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item {
  height: 40px;
}

.mdc-deprecated-list--two-line .mdc-deprecated-list-item__text {
  align-self: flex-start;
}

.mdc-deprecated-list--two-line .mdc-deprecated-list-item {
  height: 64px;
}
.mdc-deprecated-list--two-line.mdc-deprecated-list--video-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--image-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--avatar-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--icon-list .mdc-deprecated-list-item {
  height: 72px;
}
.mdc-deprecated-list--two-line.mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-deprecated-list--two-line.mdc-deprecated-list--dense .mdc-deprecated-list-item,
.mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item {
  height: 60px;
}

.mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 36px;
  height: 36px;
}
[dir=rtl] .mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item {
  cursor: pointer;
}

a.mdc-deprecated-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-deprecated-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.mdc-deprecated-list-divider {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-deprecated-list-divider--padded {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list-divider--padded, .mdc-deprecated-list-divider--padded[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list-divider--inset {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-deprecated-list-divider--inset, .mdc-deprecated-list-divider--inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list-divider--inset.mdc-deprecated-list-divider--padded {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-deprecated-list-divider--inset.mdc-deprecated-list-divider--padded, .mdc-deprecated-list-divider--inset.mdc-deprecated-list-divider--padded[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 88px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 88px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 88px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 104px);
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 88px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 116px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 116px);
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 116px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 116px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 132px);
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 116px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 0px);
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list-group .mdc-deprecated-list {
  padding: 0;
}

.mdc-deprecated-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin: calc((3rem - 1.5rem) / 2) 16px;
}

.mdc-list-item__primary-text {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: #37474f;
  /* @alternate */
  color: var(--mdc-theme-primary, #37474f);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #37474f;
  /* @alternate */
  color: var(--mdc-theme-primary, #37474f);
}

.mdc-deprecated-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-list-divider::after {
    content: "";
    display: block;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: white;
  }
}
.mdc-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
}
.mdc-list:focus {
  outline: none;
}

.mdc-list-item__wrapper {
  display: block;
}

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  align-items: stretch;
  cursor: pointer;
}
.mdc-list-item:focus {
  outline: none;
}
.mdc-list-item.mdc-list-item--with-one-line {
  height: 48px;
}
.mdc-list-item.mdc-list-item--with-two-lines {
  height: 64px;
}
.mdc-list-item.mdc-list-item--with-three-lines {
  height: 88px;
}
.mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__start {
  align-self: center;
  margin-top: 0;
}
.mdc-list-item.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}
.mdc-list-item.mdc-list-item--with-three-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}
.mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__end {
  align-self: center;
  margin-top: 0;
}
.mdc-list-item.mdc-list-item--with-two-lines .mdc-list-item__end {
  align-self: center;
  margin-top: 0;
}
.mdc-list-item.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 16px;
}
.mdc-list-item.mdc-list-item--disabled, .mdc-list-item.mdc-list-item--non-interactive {
  cursor: auto;
}
.mdc-list-item:not(.mdc-list-item--selected):focus::before, .mdc-list-item.mdc-ripple-upgraded--background-focused::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-list-item:not(.mdc-list-item--selected):focus::before, .mdc-list-item.mdc-ripple-upgraded--background-focused::before {
    border-color: CanvasText;
  }
}
.mdc-list-item.mdc-list-item--selected::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px double transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-list-item.mdc-list-item--selected::before {
    border-color: CanvasText;
  }
}
.mdc-list-item.mdc-list-item--selected:focus::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-list-item.mdc-list-item--selected:focus::before {
    border-color: CanvasText;
  }
}

a.mdc-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-list-item__start {
  fill: currentColor;
  flex-shrink: 0;
  pointer-events: none;
}

.mdc-list-item__end {
  flex-shrink: 0;
  pointer-events: none;
}

.mdc-list-item__content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
  flex: 1;
  pointer-events: none;
}
.mdc-list-item--with-two-lines .mdc-list-item__content, .mdc-list-item--with-three-lines .mdc-list-item__content {
  align-self: stretch;
}
.mdc-list-item__content[for] {
  pointer-events: none;
}

.mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.mdc-list-item--with-two-lines .mdc-list-item__primary-text, .mdc-list-item--with-three-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before, .mdc-list-item--with-three-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after, .mdc-list-item--with-three-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-three-lines .mdc-list-item__secondary-text {
  white-space: normal;
  line-height: 20px;
}
.mdc-list-item--with-overline .mdc-list-item__secondary-text {
  white-space: nowrap;
  line-height: auto;
}

.mdc-list-item__overline-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 0.75rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 500);
  letter-spacing: 0.1666666667em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, 0.1666666667em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, uppercase);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-three-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-three-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-three-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-avatar.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-avatar.mdc-list-item, .mdc-list-item--with-leading-avatar.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-avatar .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-leading-avatar .mdc-list-item__start, .mdc-list-item--with-leading-avatar .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-avatar .mdc-list-item__start {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines {
  height: 72px;
}
.mdc-list-item--with-leading-avatar .mdc-list-item__start {
  border-radius: 50%;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  width: 24px;
  height: 24px;
}
.mdc-list-item--with-leading-icon.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-icon.mdc-list-item, .mdc-list-item--with-leading-icon.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 32px;
}
[dir=rtl] .mdc-list-item--with-leading-icon .mdc-list-item__start, .mdc-list-item--with-leading-icon .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 32px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-thumbnail.mdc-list-item, .mdc-list-item--with-leading-thumbnail.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-thumbnail .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-leading-thumbnail .mdc-list-item__start, .mdc-list-item--with-leading-thumbnail .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-thumbnail .mdc-list-item__start {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-image.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-image.mdc-list-item, .mdc-list-item--with-leading-image.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-image .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-leading-image .mdc-list-item__start, .mdc-list-item--with-leading-image .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-image .mdc-list-item__start {
  width: 56px;
  height: 56px;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-one-line {
  height: 72px;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}
.mdc-list-item--with-leading-video.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-video.mdc-list-item, .mdc-list-item--with-leading-video.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-video .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-leading-video .mdc-list-item__start, .mdc-list-item--with-leading-video .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-video .mdc-list-item__start {
  width: 100px;
  height: 56px;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-one-line {
  height: 72px;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-checkbox.mdc-list-item, .mdc-list-item--with-leading-checkbox.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
}
[dir=rtl] .mdc-list-item--with-leading-checkbox .mdc-list-item__start, .mdc-list-item--with-leading-checkbox .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-radio.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-radio.mdc-list-item, .mdc-list-item--with-leading-radio.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-radio .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
}
[dir=rtl] .mdc-list-item--with-leading-radio .mdc-list-item__start, .mdc-list-item--with-leading-radio .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-radio .mdc-list-item__start {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-switch.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-switch.mdc-list-item, .mdc-list-item--with-leading-switch.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-switch .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-leading-switch .mdc-list-item__start, .mdc-list-item--with-leading-switch .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-switch .mdc-list-item__start {
  width: 36px;
  height: 20px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-trailing-icon.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-list-item--with-trailing-icon.mdc-list-item, .mdc-list-item--with-trailing-icon.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-trailing-icon .mdc-list-item__end, .mdc-list-item--with-trailing-icon .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  width: 24px;
  height: 24px;
}

.mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 0;
}
.mdc-list-item--with-trailing-meta.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 0;
}
.mdc-list-item--with-trailing-meta.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-list-item--with-trailing-meta.mdc-list-item, .mdc-list-item--with-trailing-meta.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 28px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-trailing-meta .mdc-list-item__end, .mdc-list-item--with-trailing-meta .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 28px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-trailing-meta.mdc-list-item--with-three-lines .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-trailing-meta.mdc-list-item--with-three-lines .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}

.mdc-list-item--with-trailing-checkbox.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-list-item--with-trailing-checkbox.mdc-list-item, .mdc-list-item--with-trailing-checkbox.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-checkbox .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}
[dir=rtl] .mdc-list-item--with-trailing-checkbox .mdc-list-item__end, .mdc-list-item--with-trailing-checkbox .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-checkbox .mdc-list-item__end {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-trailing-checkbox.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-list-item--with-trailing-radio.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-list-item--with-trailing-radio.mdc-list-item, .mdc-list-item--with-trailing-radio.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-radio .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}
[dir=rtl] .mdc-list-item--with-trailing-radio .mdc-list-item__end, .mdc-list-item--with-trailing-radio .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-radio .mdc-list-item__end {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-trailing-radio.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-list-item--with-trailing-switch.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-list-item--with-trailing-switch.mdc-list-item, .mdc-list-item--with-trailing-switch.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-switch .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-trailing-switch .mdc-list-item__end, .mdc-list-item--with-trailing-switch .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-switch .mdc-list-item__end {
  width: 36px;
  height: 20px;
}
.mdc-list-item--with-trailing-switch.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-list-item--with-overline.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-overline.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-overline.mdc-list-item--with-three-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-overline.mdc-list-item--with-three-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}
[dir=rtl] .mdc-list-item, .mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-group .mdc-deprecated-list {
  padding: 0;
}

.mdc-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin: calc((3rem - 1.5rem) / 2) 16px;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mdc-list-divider {
  height: 1px;
}

.mdc-list-divider {
  padding: 0;
  background-clip: content-box;
}

.mdc-list-divider.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-text.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-icon.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-image.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-avatar.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-switch.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-radio.mdc-list-divider--with-leading-inset {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-divider.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-text.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-icon.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-image.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-switch.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-radio.mdc-list-divider--with-leading-inset, .mdc-list-divider.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-text.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-icon.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-image.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-switch.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-radio.mdc-list-divider--with-leading-inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-divider.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-text.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-icon.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-image.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-avatar.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-switch.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-radio.mdc-list-divider--with-trailing-inset {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}
[dir=rtl] .mdc-list-divider.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-text.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-icon.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-image.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-switch.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-radio.mdc-list-divider--with-trailing-inset, .mdc-list-divider.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-text.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-icon.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-image.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-switch.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-radio.mdc-list-divider--with-trailing-inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-divider--with-leading-video.mdc-list-divider--with-leading-inset {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-divider--with-leading-video.mdc-list-divider--with-leading-inset, .mdc-list-divider--with-leading-video.mdc-list-divider--with-leading-inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0px;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-list-divider, .mdc-list-divider[dir=rtl] {
  /*rtl:begin:ignore*/
  padding: 0;
  /*rtl:end:ignore*/
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--unbounded .mdc-deprecated-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-activation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:hover .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:hover .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-deprecated-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-deprecated-list-item__ripple::after {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #37474f));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:hover .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-list-item__ripple::after {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #37474f));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:hover .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::after {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #37474f));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:hover .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-list-item__ripple::after {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #37474f));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:hover .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-deprecated-list-item--disabled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-deprecated-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, .mdc-deprecated-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-deprecated-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

:not(.mdc-list-item--disabled).mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
:not(.mdc-list-item--disabled).mdc-list-item:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::after {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #37474f));
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::after {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #37474f));
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-list-item--disabled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-list-item--disabled .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-list-item--disabled .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-text-field--filled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-text-field--filled .mdc-text-field__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-text-field--filled .mdc-text-field__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-text-field--filled.mdc-ripple-upgraded--unbounded .mdc-text-field__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-text-field--filled.mdc-ripple-upgraded--foreground-activation .mdc-text-field__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-text-field--filled.mdc-ripple-upgraded--foreground-deactivation .mdc-text-field__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-text-field__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-text-field {
  border-top-left-radius: 4px;
  /* @alternate */
  border-top-left-radius: var(--mdc-shape-small, 4px);
  border-top-right-radius: 4px;
  /* @alternate */
  border-top-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  display: inline-flex;
  align-items: baseline;
  padding: 0 16px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  /* @alternate */
  will-change: opacity, transform, color;
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
}
.mdc-text-field .mdc-text-field__input {
  caret-color: #37474f;
  /* @alternate */
  caret-color: var(--mdc-theme-primary, #37474f);
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field .mdc-floating-label {
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  height: 28px;
  transition: opacity 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  min-width: 0;
  border: none;
  border-radius: 0;
  background: none;
  appearance: none;
  padding: 0;
}
.mdc-text-field__input::-ms-clear {
  display: none;
}
.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: none;
}
.mdc-text-field__input:focus {
  outline: none;
}
.mdc-text-field__input:invalid {
  box-shadow: none;
}
@media all {
  .mdc-text-field__input::placeholder {
    transition: opacity 67ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }
}
@media all {
  .mdc-text-field__input:-ms-input-placeholder {
    transition: opacity 67ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }
}
@media all {
  .mdc-text-field--no-label .mdc-text-field__input::placeholder, .mdc-text-field--focused .mdc-text-field__input::placeholder {
    transition-delay: 40ms;
    transition-duration: 110ms;
    opacity: 1;
  }
}
@media all {
  .mdc-text-field--no-label .mdc-text-field__input:-ms-input-placeholder, .mdc-text-field--focused .mdc-text-field__input:-ms-input-placeholder {
    transition-delay: 40ms;
    transition-duration: 110ms;
    opacity: 1;
  }
}

.mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  height: 28px;
  transition: opacity 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  white-space: nowrap;
}
.mdc-text-field--label-floating .mdc-text-field__affix, .mdc-text-field--no-label .mdc-text-field__affix {
  opacity: 1;
}
@supports (-webkit-hyphens: none) {
  .mdc-text-field--outlined .mdc-text-field__affix {
    align-items: center;
    align-self: center;
    display: inline-flex;
    height: 100%;
  }
}

.mdc-text-field__affix--prefix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 2px;
}
[dir=rtl] .mdc-text-field__affix--prefix, .mdc-text-field__affix--prefix[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 2px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-text-field--end-aligned .mdc-text-field__affix--prefix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__affix--prefix, .mdc-text-field--end-aligned .mdc-text-field__affix--prefix[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-text-field__affix--suffix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-text-field__affix--suffix, .mdc-text-field__affix--suffix[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-text-field--end-aligned .mdc-text-field__affix--suffix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 2px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__affix--suffix, .mdc-text-field--end-aligned .mdc-text-field__affix--suffix[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 2px;
  /*rtl:end:ignore*/
}

.mdc-text-field--filled {
  height: 56px;
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-text-field--filled:hover .mdc-text-field__ripple::before, .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-text-field--filled::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: #37474f;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-primary, #37474f);
}
.mdc-text-field--filled .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 16px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .mdc-text-field--filled .mdc-floating-label, .mdc-text-field--filled .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 16px;
  /*rtl:end:ignore*/
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}
.mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input {
  height: 100%;
}
.mdc-text-field--filled.mdc-text-field--no-label .mdc-floating-label {
  display: none;
}
.mdc-text-field--filled.mdc-text-field--no-label::before {
  display: none;
}
@supports (-webkit-hyphens: none) {
  .mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__affix {
    align-items: center;
    align-self: center;
    display: inline-flex;
    height: 100%;
  }
}

.mdc-text-field--outlined {
  height: 56px;
  overflow: visible;
}
.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}
.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}
.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
}
.mdc-text-field--outlined .mdc-text-field__input {
  height: 100%;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #37474f;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #37474f);
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
}
[dir=rtl] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    width: max(12px, var(--mdc-shape-small, 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__notch {
    max-width: calc(100% - max(12px, var(--mdc-shape-small, 4px)) * 2);
  }
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
}
[dir=rtl] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  .mdc-text-field--outlined {
    /* @noflip */ /*rtl:ignore*/
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined {
    /* @noflip */ /*rtl:ignore*/
    padding-right: max(16px, var(--mdc-shape-small, 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined + .mdc-text-field-helper-line {
    /* @noflip */ /*rtl:ignore*/
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined + .mdc-text-field-helper-line {
    /* @noflip */ /*rtl:ignore*/
    padding-right: max(16px, var(--mdc-shape-small, 4px));
  }
}
.mdc-text-field--outlined.mdc-text-field--with-leading-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined.mdc-text-field--with-leading-icon {
    /* @noflip */ /*rtl:ignore*/
    padding-right: max(16px, var(--mdc-shape-small, 4px));
  }
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}
@supports (top: max(0%)) {
  [dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
    /* @noflip */ /*rtl:ignore*/
    padding-left: max(16px, var(--mdc-shape-small, 4px));
  }
}

.mdc-text-field--outlined.mdc-text-field--with-trailing-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined.mdc-text-field--with-trailing-icon {
    /* @noflip */ /*rtl:ignore*/
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /*rtl:end:ignore*/
}
@supports (top: max(0%)) {
  [dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
    /* @noflip */ /*rtl:ignore*/
    padding-right: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}

.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 1px;
}
.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: transparent;
  /* @alternate */
  background-color: var(--mdc-ripple-color, transparent);
}
.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 4px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 4px;
  /*rtl:end:ignore*/
}

.mdc-text-field--outlined .mdc-text-field__input {
  display: flex;
  border: none !important;
  background-color: transparent;
}
.mdc-text-field--outlined .mdc-notched-outline {
  z-index: 1;
}

.mdc-text-field--textarea {
  flex-direction: column;
  align-items: center;
  width: auto;
  height: auto;
  padding: 0;
  transition: none;
}
.mdc-text-field--textarea .mdc-floating-label {
  top: 19px;
}
.mdc-text-field--textarea .mdc-floating-label:not(.mdc-floating-label--float-above) {
  transform: none;
}
.mdc-text-field--textarea .mdc-text-field__input {
  flex-grow: 1;
  height: auto;
  min-height: 1.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  resize: none;
  padding: 0 16px;
  line-height: 1.5rem;
}
.mdc-text-field--textarea.mdc-text-field--filled::before {
  display: none;
}
.mdc-text-field--textarea.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-10.25px) scale(0.75);
}
.mdc-text-field--textarea.mdc-text-field--filled .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-textarea-filled 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-textarea-filled {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-10.25px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-10.25px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-10.25px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-10.25px) scale(0.75);
  }
}
.mdc-text-field--textarea.mdc-text-field--filled .mdc-text-field__input {
  margin-top: 23px;
  margin-bottom: 9px;
}
.mdc-text-field--textarea.mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 0;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-27.25px) scale(1);
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--textarea.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-24.75px) scale(0.75);
}
.mdc-text-field--textarea.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-textarea-outlined 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-textarea-outlined {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-text-field__input {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label {
  top: 18px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field__input {
  margin-bottom: 2px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter {
  align-self: flex-end;
  padding: 0 16px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter::after {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: -16px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter::before {
  display: none;
}

.mdc-text-field__resizer {
  align-self: stretch;
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
  max-width: 100%;
  min-height: 56px;
  min-width: fit-content;
  /* @alternate */
  min-width: -moz-available;
  /* @alternate */
  min-width: -webkit-fill-available;
  overflow: hidden;
  resize: both;
}
.mdc-text-field--filled .mdc-text-field__resizer {
  transform: translateY(-1px);
}
.mdc-text-field--filled .mdc-text-field__resizer .mdc-text-field__input,
.mdc-text-field--filled .mdc-text-field__resizer .mdc-text-field-character-counter {
  transform: translateY(1px);
}
.mdc-text-field--outlined .mdc-text-field__resizer {
  /* @noflip */ /*rtl:ignore*/
  transform: translateX(-1px) translateY(-1px);
}
[dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer, .mdc-text-field--outlined .mdc-text-field__resizer[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateX(1px) translateY(-1px);
  /*rtl:end:ignore*/
}

.mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input,
.mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter {
  /* @noflip */ /*rtl:ignore*/
  transform: translateX(1px) translateY(1px);
}
[dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input, [dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter, .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input[dir=rtl], .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateX(-1px) translateY(1px);
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}
[dir=rtl] .mdc-text-field--with-leading-icon, .mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label {
  max-width: calc(100% - 48px);
  /* @noflip */ /*rtl:ignore*/
  left: 48px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 48px;
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 36px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 36px;
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-37.25px) translateX(-32px) scale(1);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-37.25px) translateX(32px) scale(1);
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-34.75px) translateX(-32px) scale(0.75);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, [dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl], .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-34.75px) translateX(32px) scale(0.75);
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
}

.mdc-text-field--with-trailing-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-text-field--with-trailing-icon, .mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label {
  max-width: calc(100% - 64px);
}
.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}
.mdc-text-field--with-trailing-icon.mdc-text-field--outlined :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label {
  max-width: calc(100% - 96px);
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 96px / 0.75);
}

.mdc-text-field-helper-line {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.mdc-text-field + .mdc-text-field-helper-line {
  padding-right: 16px;
  padding-left: 16px;
}

.mdc-form-field > .mdc-text-field + label {
  align-self: flex-start;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(55, 71, 79, 0.87);
}
.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--focused .mdc-notched-outline__trailing {
  border-width: 2px;
}
.mdc-text-field--focused + .mdc-text-field-helper-line .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
  opacity: 1;
}
.mdc-text-field--focused.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 2px;
}
.mdc-text-field--focused.mdc-text-field--outlined.mdc-text-field--textarea .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 0;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: #b00020;
  /* @alternate */
  caret-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  opacity: 1;
}

.mdc-text-field--disabled {
  pointer-events: none;
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field-character-counter,
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-text-field--disabled .mdc-text-field__input {
    background-color: Window;
  }
  .mdc-text-field--disabled .mdc-floating-label {
    z-index: 1;
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  cursor: default;
}
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
.mdc-text-field--disabled.mdc-text-field--filled .mdc-text-field__ripple {
  display: none;
}
.mdc-text-field--disabled .mdc-text-field__input {
  pointer-events: auto;
}

.mdc-text-field--end-aligned .mdc-text-field__input {
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--ltr-text, .mdc-text-field--ltr-text[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__input,
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__input,
.mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix {
  /* @noflip */ /*rtl:ignore*/
  direction: ltr;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--prefix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 2px;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--suffix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__icon--leading, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__icon--leading {
  order: 1;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--suffix {
  order: 2;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__input, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__input {
  order: 3;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--prefix {
  order: 4;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__icon--trailing, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__icon--trailing {
  order: 5;
}

[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__input {
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
}
[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__affix--prefix {
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}
[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__affix--suffix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 2px;
}

.mdc-text-field-helper-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  opacity: 0;
  will-change: opacity;
  transition: opacity 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-text-field-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial;
}

.mdc-text-field-character-counter {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  /* @noflip */ /*rtl:ignore*/
  margin-left: auto;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  white-space: nowrap;
}
.mdc-text-field-character-counter::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}
[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: auto;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-text-field__icon {
  align-self: center;
  cursor: pointer;
}
.mdc-text-field__icon:not([tabindex]), .mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}
.mdc-text-field__icon svg {
  display: block;
}

.mdc-text-field__icon--leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}
[dir=rtl] .mdc-text-field__icon--leading, .mdc-text-field__icon--leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-text-field__icon--trailing {
  padding: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
}
[dir=rtl] .mdc-text-field__icon--trailing, .mdc-text-field__icon--trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
  /*rtl:end:ignore*/
}

.mdc-button {
  /* @alternate */
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: visible;
  vertical-align: middle;
  background: transparent;
}

.mdc-button .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}

.mdc-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mdc-button:active {
  outline: none;
}

.mdc-button:hover {
  cursor: pointer;
}

.mdc-button:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-button .mdc-button__icon { /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  display: inline-block;
  position: relative;
  vertical-align: top;
}

[dir=rtl] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-button .mdc-button__label {
  position: relative;
}

.mdc-button .mdc-button__focus-ring {
  display: none;
}

@media screen and (forced-colors: active) {
  .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__focus-ring, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__focus-ring {
    pointer-events: none;
    border: 2px solid transparent;
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: calc(
      100% + 4px
    );
    width: calc(
      100% + 4px
    );
    display: block;
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__focus-ring, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__focus-ring {
    border-color: CanvasText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__focus-ring::after, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__focus-ring::after {
    content: "";
    border: 2px solid transparent;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__focus-ring::after, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-button .mdc-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.mdc-button__label + .mdc-button__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}

[dir=rtl] .mdc-button__label + .mdc-button__icon, .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

svg.mdc-button__icon {
  fill: currentColor;
}

.mdc-button--touch {
  margin-top: 6px;
  margin-bottom: 6px;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
}

.mdc-button {
  padding: 0 8px 0 8px;
}

.mdc-button--unelevated {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0 16px 0 16px;
}

.mdc-button--unelevated.mdc-button--icon-trailing {
  padding: 0 12px 0 16px;
}

.mdc-button--unelevated.mdc-button--icon-leading {
  padding: 0 16px 0 12px;
}

.mdc-button--raised {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0 16px 0 16px;
}

.mdc-button--raised.mdc-button--icon-trailing {
  padding: 0 12px 0 16px;
}

.mdc-button--raised.mdc-button--icon-leading {
  padding: 0 16px 0 12px;
}

.mdc-button--outlined {
  border-style: solid;
  transition: border 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-button--outlined .mdc-button__ripple {
  border-style: solid;
  border-color: transparent;
}

.mdc-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-button .mdc-button__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-button .mdc-button__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-button.mdc-ripple-upgraded--unbounded .mdc-button__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-button.mdc-ripple-upgraded--foreground-activation .mdc-button__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-button.mdc-ripple-upgraded--foreground-deactivation .mdc-button__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-button__ripple {
  position: absolute;
  box-sizing: content-box;
  overflow: hidden;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.mdc-button {
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-text-button-label-text-font, var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-text-button-label-text-size, var(--mdc-typography-button-font-size, 0.875rem));
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-text-button-label-text-tracking, var(--mdc-typography-button-letter-spacing, 0.0892857143em));
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-text-button-label-text-weight, var(--mdc-typography-button-font-weight, 500));
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-text-button-label-text-transform, var(--mdc-typography-button-text-transform, uppercase));
  height: 36px;
  /* @alternate */
  height: var(--mdc-text-button-container-height, 36px);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-text-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button:not(:disabled) {
  color: #37474f;
  /* @alternate */
  color: var(--mdc-text-button-label-text-color, var(--mdc-theme-primary, #37474f));
}

.mdc-button:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-text-button-disabled-label-text-color, rgba(0, 0, 0, 0.38));
}

.mdc-button .mdc-button__icon {
  font-size: 1.125rem;
  /* @alternate */
  font-size: var(--mdc-text-button-with-icon-icon-size, 1.125rem);
  width: 1.125rem;
  /* @alternate */
  width: var(--mdc-text-button-with-icon-icon-size, 1.125rem);
  height: 1.125rem;
  /* @alternate */
  height: var(--mdc-text-button-with-icon-icon-size, 1.125rem);
}

.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-text-button-hover-state-layer-color, var(--mdc-theme-primary, #37474f));
}

.mdc-button:hover .mdc-button__ripple::before, .mdc-button.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-text-button-hover-state-layer-opacity, 0.04);
}

.mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-text-button-focus-state-layer-opacity, 0.12);
}

.mdc-button:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-button:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-text-button-pressed-state-layer-opacity, 0.12);
}

.mdc-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-text-button-pressed-state-layer-opacity, 0.12);
}

.mdc-button .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-text-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button--unelevated {
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-filled-button-label-text-font, var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-filled-button-label-text-size, var(--mdc-typography-button-font-size, 0.875rem));
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-filled-button-label-text-tracking, var(--mdc-typography-button-letter-spacing, 0.0892857143em));
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-filled-button-label-text-weight, var(--mdc-typography-button-font-weight, 500));
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-filled-button-label-text-transform, var(--mdc-typography-button-text-transform, uppercase));
  height: 36px;
  /* @alternate */
  height: var(--mdc-filled-button-container-height, 36px);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-filled-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button--unelevated:not(:disabled) {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-filled-button-container-color, var(--mdc-theme-primary, #37474f));
}

.mdc-button--unelevated:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  /* @alternate */
  background-color: var(--mdc-filled-button-disabled-container-color, rgba(0, 0, 0, 0.12));
}

.mdc-button--unelevated:not(:disabled) {
  color: #fafafa;
  /* @alternate */
  color: var(--mdc-filled-button-label-text-color, var(--mdc-theme-on-primary, #fafafa));
}

.mdc-button--unelevated:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-filled-button-disabled-label-text-color, rgba(0, 0, 0, 0.38));
}

.mdc-button--unelevated .mdc-button__icon {
  font-size: 1.125rem;
  /* @alternate */
  font-size: var(--mdc-filled-button-with-icon-icon-size, 1.125rem);
  width: 1.125rem;
  /* @alternate */
  width: var(--mdc-filled-button-with-icon-icon-size, 1.125rem);
  height: 1.125rem;
  /* @alternate */
  height: var(--mdc-filled-button-with-icon-icon-size, 1.125rem);
}

.mdc-button--unelevated .mdc-button__ripple::before,
.mdc-button--unelevated .mdc-button__ripple::after {
  background-color: #fafafa;
  /* @alternate */
  background-color: var(--mdc-filled-button-hover-state-layer-color, var(--mdc-theme-on-primary, #fafafa));
}

.mdc-button--unelevated:hover .mdc-button__ripple::before, .mdc-button--unelevated.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-filled-button-hover-state-layer-opacity, 0.08);
}

.mdc-button--unelevated.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--unelevated:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-filled-button-focus-state-layer-opacity, 0.24);
}

.mdc-button--unelevated:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-button--unelevated:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-filled-button-pressed-state-layer-opacity, 0.24);
}

.mdc-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-filled-button-pressed-state-layer-opacity, 0.24);
}

.mdc-button--unelevated .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-filled-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button--raised {
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-protected-button-label-text-font, var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-protected-button-label-text-size, var(--mdc-typography-button-font-size, 0.875rem));
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-protected-button-label-text-tracking, var(--mdc-typography-button-letter-spacing, 0.0892857143em));
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-protected-button-label-text-weight, var(--mdc-typography-button-font-weight, 500));
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-protected-button-label-text-transform, var(--mdc-typography-button-text-transform, uppercase));
  height: 36px;
  /* @alternate */
  height: var(--mdc-protected-button-container-height, 36px);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-protected-button-container-shape, var(--mdc-shape-small, 4px));
  --mdc-elevation-box-shadow-for-gss:0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-protected-button-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}

.mdc-button--raised:not(:disabled) {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-protected-button-container-color, var(--mdc-theme-primary, #37474f));
}

.mdc-button--raised:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  /* @alternate */
  background-color: var(--mdc-protected-button-disabled-container-color, rgba(0, 0, 0, 0.12));
}

.mdc-button--raised:not(:disabled) {
  color: #fafafa;
  /* @alternate */
  color: var(--mdc-protected-button-label-text-color, var(--mdc-theme-on-primary, #fafafa));
}

.mdc-button--raised:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-protected-button-disabled-label-text-color, rgba(0, 0, 0, 0.38));
}

.mdc-button--raised .mdc-button__icon {
  font-size: 1.125rem;
  /* @alternate */
  font-size: var(--mdc-protected-button-with-icon-icon-size, 1.125rem);
  width: 1.125rem;
  /* @alternate */
  width: var(--mdc-protected-button-with-icon-icon-size, 1.125rem);
  height: 1.125rem;
  /* @alternate */
  height: var(--mdc-protected-button-with-icon-icon-size, 1.125rem);
}

.mdc-button--raised .mdc-button__ripple::before,
.mdc-button--raised .mdc-button__ripple::after {
  background-color: #fafafa;
  /* @alternate */
  background-color: var(--mdc-protected-button-hover-state-layer-color, var(--mdc-theme-on-primary, #fafafa));
}

.mdc-button--raised:hover .mdc-button__ripple::before, .mdc-button--raised.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-protected-button-hover-state-layer-opacity, 0.08);
}

.mdc-button--raised.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--raised:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-protected-button-focus-state-layer-opacity, 0.24);
}

.mdc-button--raised:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-button--raised:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-protected-button-pressed-state-layer-opacity, 0.24);
}

.mdc-button--raised.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-protected-button-pressed-state-layer-opacity, 0.24);
}

.mdc-button--raised .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-protected-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button--raised.mdc-ripple-upgraded--background-focused, .mdc-button--raised:not(.mdc-ripple-upgraded):focus {
  --mdc-elevation-box-shadow-for-gss:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-protected-button-focus-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}

.mdc-button--raised:hover {
  --mdc-elevation-box-shadow-for-gss:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-protected-button-hover-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}

.mdc-button--raised:not(:disabled):active {
  --mdc-elevation-box-shadow-for-gss:0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-protected-button-pressed-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}

.mdc-button--raised:disabled {
  --mdc-elevation-box-shadow-for-gss:0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-protected-button-disabled-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}

.mdc-button--outlined {
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-outlined-button-label-text-font, var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-outlined-button-label-text-size, var(--mdc-typography-button-font-size, 0.875rem));
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-outlined-button-label-text-tracking, var(--mdc-typography-button-letter-spacing, 0.0892857143em));
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-outlined-button-label-text-weight, var(--mdc-typography-button-font-weight, 500));
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-outlined-button-label-text-transform, var(--mdc-typography-button-text-transform, uppercase));
  height: 36px;
  /* @alternate */
  height: var(--mdc-outlined-button-container-height, 36px);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-outlined-button-container-shape, var(--mdc-shape-small, 4px));
  padding: 0 15px 0 15px;
  border-width: 1px;
  /* @alternate */
  border-width: var(--mdc-outlined-button-outline-width, 1px);
}

.mdc-button--outlined:not(:disabled) {
  color: #37474f;
  /* @alternate */
  color: var(--mdc-outlined-button-label-text-color, var(--mdc-theme-primary, #37474f));
}

.mdc-button--outlined:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-outlined-button-disabled-label-text-color, rgba(0, 0, 0, 0.38));
}

.mdc-button--outlined .mdc-button__icon {
  font-size: 1.125rem;
  /* @alternate */
  font-size: var(--mdc-outlined-button-with-icon-icon-size, 1.125rem);
  width: 1.125rem;
  /* @alternate */
  width: var(--mdc-outlined-button-with-icon-icon-size, 1.125rem);
  height: 1.125rem;
  /* @alternate */
  height: var(--mdc-outlined-button-with-icon-icon-size, 1.125rem);
}

.mdc-button--outlined .mdc-button__ripple::before,
.mdc-button--outlined .mdc-button__ripple::after {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-outlined-button-hover-state-layer-color, var(--mdc-theme-primary, #37474f));
}

.mdc-button--outlined:hover .mdc-button__ripple::before, .mdc-button--outlined.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-outlined-button-hover-state-layer-opacity, 0.04);
}

.mdc-button--outlined.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--outlined:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-outlined-button-focus-state-layer-opacity, 0.12);
}

.mdc-button--outlined:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-button--outlined:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-outlined-button-pressed-state-layer-opacity, 0.12);
}

.mdc-button--outlined.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-outlined-button-pressed-state-layer-opacity, 0.12);
}

.mdc-button--outlined .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-outlined-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button--outlined:not(:disabled) {
  border-color: rgba(0, 0, 0, 0.12);
  /* @alternate */
  border-color: var(--mdc-outlined-button-outline-color, rgba(0, 0, 0, 0.12));
}

.mdc-button--outlined:disabled {
  border-color: rgba(0, 0, 0, 0.12);
  /* @alternate */
  border-color: var(--mdc-outlined-button-disabled-outline-color, rgba(0, 0, 0, 0.12));
}

.mdc-button--outlined.mdc-button--icon-trailing {
  padding: 0 11px 0 15px;
}

.mdc-button--outlined.mdc-button--icon-leading {
  padding: 0 15px 0 11px;
}

.mdc-button--outlined .mdc-button__ripple {
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  border-width: 1px;
  /* @alternate */
  border-width: var(--mdc-outlined-button-outline-width, 1px);
}

.mdc-button--outlined .mdc-button__touch {
  left: calc(-1 * 1px);
  /* @alternate */
  left: calc(-1 * var(--mdc-outlined-button-outline-width, 1px));
  width: calc(100% + 2 * 1px);
  /* @alternate */
  width: calc(100% + 2 * var(--mdc-outlined-button-outline-width, 1px));
}

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: -4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}

[dir=rtl] .mdc-button--raised .mdc-button__icon, [dir=rtl] .mdc-button--unelevated .mdc-button__icon, [dir=rtl] .mdc-button--outlined .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir=rtl], .mdc-button--unelevated .mdc-button__icon[dir=rtl], .mdc-button--outlined .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: -4px;
  /*rtl:end:ignore*/
}

.mdc-button--raised .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: -4px;
}

[dir=rtl] .mdc-button--raised .mdc-button__label + .mdc-button__icon, [dir=rtl] .mdc-button--unelevated .mdc-button__label + .mdc-button__icon, [dir=rtl] .mdc-button--outlined .mdc-button__label + .mdc-button__icon, .mdc-button--raised .mdc-button__label + .mdc-button__icon[dir=rtl], .mdc-button--unelevated .mdc-button__label + .mdc-button__icon[dir=rtl], .mdc-button--outlined .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: -4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-drawer {
  border-color: rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: var(--mdc-shape-large, 0);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: var(--mdc-shape-large, 0);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
  z-index: 6;
  width: 256px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 100%;
  /* @noflip */ /*rtl:ignore*/
  border-right-width: 1px;
  /* @noflip */ /*rtl:ignore*/
  border-right-style: solid;
  overflow: hidden;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-drawer .mdc-drawer__title {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-drawer .mdc-deprecated-list-group__subheader {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-drawer .mdc-drawer__subtitle {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-drawer .mdc-deprecated-list-item__graphic {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-drawer .mdc-deprecated-list-item {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-drawer .mdc-deprecated-list-item--activated .mdc-deprecated-list-item__graphic {
  color: #37474f;
}

.mdc-drawer .mdc-deprecated-list-item--activated {
  color: rgba(55, 71, 79, 0.87);
}

[dir=rtl] .mdc-drawer, .mdc-drawer[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: var(--mdc-shape-large, 0);
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: var(--mdc-shape-large, 0);
  /*rtl:end:ignore*/
}

.mdc-drawer .mdc-deprecated-list-item {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 256px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}

[dir=rtl] .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content, .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 256px;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-drawer, .mdc-drawer[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-right-width: 0;
  /* @noflip */ /*rtl:ignore*/
  border-left-width: 1px;
  /* @noflip */ /*rtl:ignore*/
  border-right-style: none;
  /* @noflip */ /*rtl:ignore*/
  border-left-style: solid;
  /*rtl:end:ignore*/
}

.mdc-drawer .mdc-deprecated-list-item {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
  height: calc(48px - 2 * 4px);
  margin: 8px 8px;
  padding: 0 8px;
}

.mdc-drawer .mdc-deprecated-list-item:nth-child(1) {
  margin-top: 2px;
}

.mdc-drawer .mdc-deprecated-list-item:nth-last-child(1) {
  margin-bottom: 0;
}

.mdc-drawer .mdc-deprecated-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  padding: 0 16px;
}

.mdc-drawer .mdc-deprecated-list-group__subheader::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}

.mdc-drawer .mdc-deprecated-list-divider {
  margin: 3px 0 4px;
}

.mdc-drawer .mdc-deprecated-list-item__text,
.mdc-drawer .mdc-deprecated-list-item__graphic {
  pointer-events: none;
}

.mdc-drawer--animate {
  transform: translateX(-100%);
}

[dir=rtl] .mdc-drawer--animate, .mdc-drawer--animate[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateX(100%);
  /*rtl:end:ignore*/
}

.mdc-drawer--opening {
  transform: translateX(0);
  transition-duration: 250ms;
}

[dir=rtl] .mdc-drawer--opening, .mdc-drawer--opening[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateX(0);
  /*rtl:end:ignore*/
}

.mdc-drawer--closing {
  transform: translateX(-100%);
  transition-duration: 200ms;
}

[dir=rtl] .mdc-drawer--closing, .mdc-drawer--closing[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateX(100%);
  /*rtl:end:ignore*/
}

.mdc-drawer__header {
  flex-shrink: 0;
  box-sizing: border-box;
  min-height: 64px;
  padding: 0 16px 4px;
}

.mdc-drawer__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-drawer__title::before {
  display: inline-block;
  width: 0;
  height: 36px;
  content: "";
  vertical-align: 0;
}

.mdc-drawer__title::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-drawer__subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: 0;
}

.mdc-drawer__subtitle::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-drawer__content {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mdc-drawer--dismissible {
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
  display: none;
  position: absolute;
}

[dir=rtl] .mdc-drawer--dismissible, .mdc-drawer--dismissible[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /*rtl:end:ignore*/
}

.mdc-drawer--dismissible.mdc-drawer--open {
  display: flex;
}

.mdc-drawer-app-content {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  position: relative;
}

[dir=rtl] .mdc-drawer-app-content, .mdc-drawer-app-content[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-drawer--modal {
  /* @alternate */
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
  display: none;
  position: fixed;
}

.mdc-drawer--modal + .mdc-drawer-scrim {
  background-color: rgba(0, 0, 0, 0.32);
}

[dir=rtl] .mdc-drawer--modal, .mdc-drawer--modal[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /*rtl:end:ignore*/
}

.mdc-drawer--modal.mdc-drawer--open {
  display: flex;
}

.mdc-drawer-scrim {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-drawer--open + .mdc-drawer-scrim {
  display: block;
}

.mdc-drawer--animate + .mdc-drawer-scrim {
  opacity: 0;
}

.mdc-drawer--opening + .mdc-drawer-scrim {
  transition-duration: 250ms;
  opacity: 1;
}

.mdc-drawer--closing + .mdc-drawer-scrim {
  transition-duration: 200ms;
  opacity: 0;
}

.mdc-elevation--z0 {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z1 {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z2 {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z3 {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z4 {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z5 {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z6 {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z7 {
  /* @alternate */
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z8 {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z9 {
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z10 {
  /* @alternate */
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z11 {
  /* @alternate */
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z12 {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z13 {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z14 {
  /* @alternate */
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z15 {
  /* @alternate */
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z16 {
  /* @alternate */
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z17 {
  /* @alternate */
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z18 {
  /* @alternate */
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z19 {
  /* @alternate */
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z20 {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z21 {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z22 {
  /* @alternate */
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z23 {
  /* @alternate */
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z24 {
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mdc-elevation-transition {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
}

.mdc-icon-button {
  font-size: 24px;
  width: 48px;
  height: 48px;
  padding: 12px;
}

.mdc-icon-button .mdc-icon-button__focus-ring {
  display: none;
}

.mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  display: block;
  max-height: 48px;
  max-width: 48px;
}

@media screen and (forced-colors: active) {
  .mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    pointer-events: none;
    border: 2px solid transparent;
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    border-color: CanvasText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    content: "";
    border: 2px solid transparent;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-icon-button.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}

.mdc-icon-button.mdc-icon-button--reduced-size.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button.mdc-icon-button--reduced-size:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}

.mdc-icon-button .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mdc-icon-button:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
}

.mdc-icon-button svg,
.mdc-icon-button img {
  width: 24px;
  height: 24px;
}

.mdc-icon-button {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  z-index: 0;
  overflow: visible;
}

.mdc-icon-button .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mdc-icon-button:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-icon-button--display-flex {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.mdc-icon-button__icon {
  display: inline-block;
}

.mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: none;
}

.mdc-icon-button--on .mdc-icon-button__icon {
  display: none;
}

.mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: inline-block;
}

.mdc-icon-button__link {
  height: 100%;
  left: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-icon-button .mdc-icon-button__ripple::before,
.mdc-icon-button .mdc-icon-button__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-icon-button .mdc-icon-button__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-icon-button .mdc-icon-button__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-icon-button.mdc-ripple-upgraded--unbounded .mdc-icon-button__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-icon-button.mdc-ripple-upgraded--foreground-activation .mdc-icon-button__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation .mdc-icon-button__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-icon-button .mdc-icon-button__ripple::before,
.mdc-icon-button .mdc-icon-button__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}

.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::before,
.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-icon-button .mdc-icon-button__ripple::before, .mdc-icon-button .mdc-icon-button__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

.mdc-icon-button:hover .mdc-icon-button__ripple::before, .mdc-icon-button.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-icon-button:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-icon-button:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-icon-button .mdc-icon-button__ripple {
  height: 100%;
  left: 0px;
  pointer-events: none;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: -1;
}

:root {
  --mdc-layout-grid-margin-desktop: 24px;
  --mdc-layout-grid-gutter-desktop: 24px;
  --mdc-layout-grid-column-width-desktop: 72px;
  --mdc-layout-grid-margin-tablet: 16px;
  --mdc-layout-grid-gutter-tablet: 16px;
  --mdc-layout-grid-column-width-tablet: 72px;
  --mdc-layout-grid-margin-phone: 16px;
  --mdc-layout-grid-gutter-phone: 16px;
  --mdc-layout-grid-column-width-phone: 72px;
}

@media (min-width: 840px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 24px;
    padding: var(--mdc-layout-grid-margin-desktop, 24px);
  }
}
@media (min-width: 600px) and (max-width: 839px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-tablet, 16px);
  }
}
@media (max-width: 599px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-phone, 16px);
  }
}
@media (min-width: 840px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 24px;
      grid-gap: var(--mdc-layout-grid-gutter-desktop, 24px);
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }
}
@media (min-width: 600px) and (max-width: 839px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-tablet, 16px);
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
  }
}
@media (max-width: 599px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-phone, 16px);
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}
@media (min-width: 840px) {
  .mdc-layout-grid__cell {
    width: calc(33.3333333333% - 24px);
    width: calc(33.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
    box-sizing: border-box;
    margin: 12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-desktop {
    width: calc(8.3333333333% - 24px);
    width: calc(8.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-desktop {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-desktop {
    width: calc(16.6666666667% - 24px);
    width: calc(16.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-desktop {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-desktop {
    width: calc(25% - 24px);
    width: calc(25% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-desktop {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-desktop {
    width: calc(33.3333333333% - 24px);
    width: calc(33.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-desktop {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-desktop {
    width: calc(41.6666666667% - 24px);
    width: calc(41.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-desktop {
      width: auto;
      grid-column-end: span 5;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-desktop {
    width: calc(50% - 24px);
    width: calc(50% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-desktop {
      width: auto;
      grid-column-end: span 6;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-desktop {
    width: calc(58.3333333333% - 24px);
    width: calc(58.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-desktop {
      width: auto;
      grid-column-end: span 7;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-desktop {
    width: calc(66.6666666667% - 24px);
    width: calc(66.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-desktop {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-desktop {
    width: calc(75% - 24px);
    width: calc(75% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-desktop {
      width: auto;
      grid-column-end: span 9;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-desktop {
    width: calc(83.3333333333% - 24px);
    width: calc(83.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-desktop {
      width: auto;
      grid-column-end: span 10;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-desktop {
    width: calc(91.6666666667% - 24px);
    width: calc(91.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-desktop {
      width: auto;
      grid-column-end: span 11;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-desktop {
    width: calc(100% - 24px);
    width: calc(100% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-desktop {
      width: auto;
      grid-column-end: span 12;
    }
  }
}
@media (min-width: 600px) and (max-width: 839px) {
  .mdc-layout-grid__cell {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-tablet {
    width: calc(12.5% - 16px);
    width: calc(12.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-tablet {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-tablet {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-tablet {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-tablet {
    width: calc(37.5% - 16px);
    width: calc(37.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-tablet {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-tablet {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-tablet {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-tablet {
    width: calc(62.5% - 16px);
    width: calc(62.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-tablet {
      width: auto;
      grid-column-end: span 5;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-tablet {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-tablet {
      width: auto;
      grid-column-end: span 6;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-tablet {
    width: calc(87.5% - 16px);
    width: calc(87.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-tablet {
      width: auto;
      grid-column-end: span 7;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
}
@media (max-width: 599px) {
  .mdc-layout-grid__cell {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-phone {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-phone {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-phone {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-phone {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-phone {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-phone {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
}
.mdc-layout-grid__cell--order-1 {
  order: 1;
}

.mdc-layout-grid__cell--order-2 {
  order: 2;
}

.mdc-layout-grid__cell--order-3 {
  order: 3;
}

.mdc-layout-grid__cell--order-4 {
  order: 4;
}

.mdc-layout-grid__cell--order-5 {
  order: 5;
}

.mdc-layout-grid__cell--order-6 {
  order: 6;
}

.mdc-layout-grid__cell--order-7 {
  order: 7;
}

.mdc-layout-grid__cell--order-8 {
  order: 8;
}

.mdc-layout-grid__cell--order-9 {
  order: 9;
}

.mdc-layout-grid__cell--order-10 {
  order: 10;
}

.mdc-layout-grid__cell--order-11 {
  order: 11;
}

.mdc-layout-grid__cell--order-12 {
  order: 12;
}

.mdc-layout-grid__cell--align-top {
  align-self: flex-start;
}

@supports (display: grid) {
  .mdc-layout-grid__cell--align-top {
    align-self: start;
  }
}
.mdc-layout-grid__cell--align-middle {
  align-self: center;
}

.mdc-layout-grid__cell--align-bottom {
  align-self: flex-end;
}

@supports (display: grid) {
  .mdc-layout-grid__cell--align-bottom {
    align-self: end;
  }
}
@media (min-width: 840px) {
  .mdc-layout-grid--fixed-column-width {
    width: 1176px;
    width: calc(
    var(--mdc-layout-grid-column-width-desktop, 72px) * 12 +
      var(--mdc-layout-grid-gutter-desktop, 24px) * 11 +
      var(--mdc-layout-grid-margin-desktop, 24px) * 2
  );
  }
}
@media (min-width: 600px) and (max-width: 839px) {
  .mdc-layout-grid--fixed-column-width {
    width: 720px;
    width: calc(
    var(--mdc-layout-grid-column-width-tablet, 72px) * 8 +
      var(--mdc-layout-grid-gutter-tablet, 16px) * 7 +
      var(--mdc-layout-grid-margin-tablet, 16px) * 2
  );
  }
}
@media (max-width: 599px) {
  .mdc-layout-grid--fixed-column-width {
    width: 368px;
    width: calc(
    var(--mdc-layout-grid-column-width-phone, 72px) * 4 +
      var(--mdc-layout-grid-gutter-phone, 16px) * 3 +
      var(--mdc-layout-grid-margin-phone, 16px) * 2
  );
  }
}
.mdc-layout-grid--align-left {
  margin-right: auto;
  margin-left: 0;
}

.mdc-layout-grid--align-right {
  margin-right: 0;
  margin-left: auto;
}

@keyframes mdc-linear-progress-primary-indeterminate-translate {
  0% {
    transform: translateX(0);
  }
  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(0);
  }
  59.15% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(83.67142%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-primary-half, 83.67142%));
  }
  100% {
    transform: translateX(200.611057%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-primary-full, 200.611057%));
  }
}
@keyframes mdc-linear-progress-primary-indeterminate-scale {
  0% {
    transform: scaleX(0.08);
  }
  36.65% {
    animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
    transform: scaleX(0.08);
  }
  69.15% {
    animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    transform: scaleX(0.661479);
  }
  100% {
    transform: scaleX(0.08);
  }
}
@keyframes mdc-linear-progress-secondary-indeterminate-translate {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    transform: translateX(0);
  }
  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: translateX(37.651913%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-quarter, 37.651913%));
  }
  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: translateX(84.386165%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-half, 84.386165%));
  }
  100% {
    transform: translateX(160.277782%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-full, 160.277782%));
  }
}
@keyframes mdc-linear-progress-secondary-indeterminate-scale {
  0% {
    animation-timing-function: cubic-bezier(0.205028, 0.057051, 0.57661, 0.453971);
    transform: scaleX(0.08);
  }
  19.15% {
    animation-timing-function: cubic-bezier(0.152313, 0.196432, 0.648374, 1.004315);
    transform: scaleX(0.457104);
  }
  44.15% {
    animation-timing-function: cubic-bezier(0.257759, -0.003163, 0.211762, 1.38179);
    transform: scaleX(0.72796);
  }
  100% {
    transform: scaleX(0.08);
  }
}
@keyframes mdc-linear-progress-buffering {
  from {
    transform: rotate(180deg) translateX(-10px);
  }
}
@keyframes mdc-linear-progress-primary-indeterminate-translate-reverse {
  0% {
    transform: translateX(0);
  }
  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(0);
  }
  59.15% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(-83.67142%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-primary-half-neg, -83.67142%));
  }
  100% {
    transform: translateX(-200.611057%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-primary-full-neg, -200.611057%));
  }
}
@keyframes mdc-linear-progress-secondary-indeterminate-translate-reverse {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    transform: translateX(0);
  }
  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: translateX(-37.651913%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-quarter-neg, -37.651913%));
  }
  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: translateX(-84.386165%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-half-neg, -84.386165%));
  }
  100% {
    transform: translateX(-160.277782%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-full-neg, -160.277782%));
  }
}
@keyframes mdc-linear-progress-buffering-reverse {
  from {
    transform: translateX(-10px);
  }
}
.mdc-linear-progress {
  position: relative;
  width: 100%;
  transform: translateZ(0);
  outline: 1px solid transparent;
  overflow: hidden;
  transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

@media screen and (forced-colors: active) {
  .mdc-linear-progress {
    outline-color: CanvasText;
  }
}
.mdc-linear-progress__bar {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: none;
  transform-origin: top left;
  transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-linear-progress__bar-inner {
  display: inline-block;
  position: absolute;
  width: 100%;
  animation: none;
  border-top-style: solid;
}

.mdc-linear-progress__buffer {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
}

.mdc-linear-progress__buffer-dots {
  background-repeat: repeat-x;
  flex: auto;
  transform: rotate(180deg);
  animation: mdc-linear-progress-buffering 250ms infinite linear;
}

.mdc-linear-progress__buffer-bar {
  flex: 0 1 100%;
  transition: flex-basis 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-linear-progress__primary-bar {
  transform: scaleX(0);
}

.mdc-linear-progress__secondary-bar {
  display: none;
}

.mdc-linear-progress--indeterminate .mdc-linear-progress__bar {
  transition: none;
}

.mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar {
  left: -145.166611%;
}

.mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar {
  left: -54.888891%;
  display: block;
}

.mdc-linear-progress--indeterminate.mdc-linear-progress--animation-ready .mdc-linear-progress__primary-bar {
  animation: mdc-linear-progress-primary-indeterminate-translate 2s infinite linear;
}

.mdc-linear-progress--indeterminate.mdc-linear-progress--animation-ready .mdc-linear-progress__primary-bar > .mdc-linear-progress__bar-inner {
  animation: mdc-linear-progress-primary-indeterminate-scale 2s infinite linear;
}

.mdc-linear-progress--indeterminate.mdc-linear-progress--animation-ready .mdc-linear-progress__secondary-bar {
  animation: mdc-linear-progress-secondary-indeterminate-translate 2s infinite linear;
}

.mdc-linear-progress--indeterminate.mdc-linear-progress--animation-ready .mdc-linear-progress__secondary-bar > .mdc-linear-progress__bar-inner {
  animation: mdc-linear-progress-secondary-indeterminate-scale 2s infinite linear;
}

[dir=rtl] .mdc-linear-progress, .mdc-linear-progress[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-linear-progress:not([dir=ltr]) .mdc-linear-progress__bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]) .mdc-linear-progress__bar {
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /* @noflip */ /*rtl:ignore*/
  -webkit-transform-origin: center right;
  /* @noflip */ /*rtl:ignore*/
  transform-origin: center right;
}

[dir=rtl] .mdc-linear-progress:not([dir=ltr]).mdc-linear-progress--animation-ready .mdc-linear-progress__primary-bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]).mdc-linear-progress--animation-ready .mdc-linear-progress__primary-bar {
  animation-name: mdc-linear-progress-primary-indeterminate-translate-reverse;
}

[dir=rtl] .mdc-linear-progress:not([dir=ltr]).mdc-linear-progress--animation-ready .mdc-linear-progress__secondary-bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]).mdc-linear-progress--animation-ready .mdc-linear-progress__secondary-bar {
  animation-name: mdc-linear-progress-secondary-indeterminate-translate-reverse;
}

[dir=rtl] .mdc-linear-progress:not([dir=ltr]) .mdc-linear-progress__buffer-dots, .mdc-linear-progress[dir=rtl]:not([dir=ltr]) .mdc-linear-progress__buffer-dots {
  animation: mdc-linear-progress-buffering-reverse 250ms infinite linear;
  transform: rotate(0);
}

[dir=rtl] .mdc-linear-progress:not([dir=ltr]).mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]).mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar {
  /* @noflip */ /*rtl:ignore*/
  right: -145.166611%;
  /* @noflip */ /*rtl:ignore*/
  left: auto;
}

[dir=rtl] .mdc-linear-progress:not([dir=ltr]).mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]).mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar {
  /* @noflip */ /*rtl:ignore*/
  right: -54.888891%;
  /* @noflip */ /*rtl:ignore*/
  left: auto;
}

.mdc-linear-progress--closed {
  opacity: 0;
}

.mdc-linear-progress--closed-animation-off .mdc-linear-progress__buffer-dots {
  animation: none;
}

.mdc-linear-progress--closed-animation-off.mdc-linear-progress--indeterminate .mdc-linear-progress__bar,
.mdc-linear-progress--closed-animation-off.mdc-linear-progress--indeterminate .mdc-linear-progress__bar .mdc-linear-progress__bar-inner {
  animation: none;
}

.mdc-linear-progress__bar-inner {
  border-color: #37474f;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #37474f);
}

.mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='%23e6e6e6'/%3E%3C/svg%3E");
}

.mdc-linear-progress__buffer-bar {
  background-color: #e6e6e6;
}

.mdc-linear-progress {
  height: 4px;
}

.mdc-linear-progress__bar-inner {
  border-top-width: 4px;
}

.mdc-linear-progress__buffer-dots {
  background-size: 10px 4px;
}

.mdc-radio {
  padding: calc((40px - 20px) / 2);
}

.mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #ffc107;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #ffc107);
}

.mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #ffc107;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #ffc107);
}

.mdc-radio [aria-disabled=true] .mdc-radio__native-control:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
.mdc-radio .mdc-radio__native-control:disabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}

.mdc-radio [aria-disabled=true] .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle,
.mdc-radio .mdc-radio__native-control:disabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}

.mdc-radio [aria-disabled=true] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__inner-circle,
.mdc-radio .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: rgba(0, 0, 0, 0.38);
}

.mdc-radio .mdc-radio__background::before {
  background-color: #ffc107;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #ffc107);
}

.mdc-radio .mdc-radio__background::before {
  top: calc(-1 * (40px - 20px) / 2);
  left: calc(-1 * (40px - 20px) / 2);
  width: 40px;
  height: 40px;
}

.mdc-radio .mdc-radio__native-control {
  top: calc((40px - 40px) / 2);
  right: calc((40px - 40px) / 2);
  left: calc((40px - 40px) / 2);
  width: 40px;
  height: 40px;
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-radio.mdc-radio--disabled [aria-disabled=true] .mdc-radio__native-control:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
  .mdc-radio.mdc-radio--disabled .mdc-radio__native-control:disabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: GrayText;
  }
  .mdc-radio.mdc-radio--disabled [aria-disabled=true] .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle,
  .mdc-radio.mdc-radio--disabled .mdc-radio__native-control:disabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: GrayText;
  }
  .mdc-radio.mdc-radio--disabled [aria-disabled=true] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__inner-circle,
  .mdc-radio.mdc-radio--disabled .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
    border-color: GrayText;
  }
}
.mdc-radio {
  display: inline-block;
  position: relative;
  flex: 0 0 auto;
  box-sizing: content-box;
  width: 20px;
  height: 20px;
  cursor: pointer;
  /* @alternate */
  will-change: opacity, transform, border-color, color;
}

.mdc-radio__background {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
}

.mdc-radio__background::before {
  position: absolute;
  transform: scale(0, 0);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
  transition: opacity 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-radio__outer-circle {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  transition: border-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-radio__inner-circle {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transform: scale(0, 0);
  border-width: 10px;
  border-style: solid;
  border-radius: 50%;
  transition: transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-radio__native-control {
  position: absolute;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;
  z-index: 1;
}

.mdc-radio--touch {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}

.mdc-radio--touch .mdc-radio__native-control {
  top: calc((40px - 48px) / 2);
  right: calc((40px - 48px) / 2);
  left: calc((40px - 48px) / 2);
  width: 48px;
  height: 48px;
}

.mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__focus-ring, .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}

@media screen and (forced-colors: active) {
  .mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__focus-ring, .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__focus-ring::after, .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}

@media screen and (forced-colors: active) {
  .mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__focus-ring::after, .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-radio__native-control:checked + .mdc-radio__background,
.mdc-radio__native-control:disabled + .mdc-radio__background {
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle,
.mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle {
  transition: border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle,
.mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
  transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle {
  transform: scale(0.5);
  transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio__native-control:disabled + .mdc-radio__background,
[aria-disabled=true] .mdc-radio__native-control + .mdc-radio__background {
  cursor: default;
}

.mdc-radio__native-control:focus + .mdc-radio__background::before {
  transform: scale(1);
  opacity: 0.12;
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-radio .mdc-radio__ripple::before,
.mdc-radio .mdc-radio__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-radio .mdc-radio__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-radio .mdc-radio__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-radio.mdc-ripple-upgraded--unbounded .mdc-radio__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-radio.mdc-ripple-upgraded--foreground-activation .mdc-radio__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-radio.mdc-ripple-upgraded--foreground-deactivation .mdc-radio__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-radio .mdc-radio__ripple::before,
.mdc-radio .mdc-radio__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}

.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::before,
.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-radio .mdc-radio__ripple::before, .mdc-radio .mdc-radio__ripple::after {
  background-color: #ffc107;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #ffc107));
}

.mdc-radio:hover .mdc-radio__ripple::before, .mdc-radio.mdc-ripple-surface--hover .mdc-radio__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}

.mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__ripple::before, .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}

.mdc-radio:not(.mdc-ripple-upgraded) .mdc-radio__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-radio:not(.mdc-ripple-upgraded):active .mdc-radio__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-radio.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-radio.mdc-ripple-upgraded .mdc-radio__background::before, .mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__background::before {
  content: none;
}

.mdc-radio__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-ripple-surface {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  position: relative;
  outline: none;
  overflow: hidden;
}

.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-ripple-surface::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-ripple-surface::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-ripple-surface.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-ripple-surface.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-ripple-surface.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-ripple-surface.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-ripple-surface.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-ripple-surface.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-ripple-surface[data-mdc-ripple-is-unbounded],
.mdc-ripple-upgraded--unbounded {
  overflow: visible;
}

.mdc-ripple-surface[data-mdc-ripple-is-unbounded]::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded]::after,
.mdc-ripple-upgraded--unbounded::before,
.mdc-ripple-upgraded--unbounded::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}

.mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after,
.mdc-ripple-upgraded--unbounded.mdc-ripple-upgraded::before,
.mdc-ripple-upgraded--unbounded.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after,
.mdc-ripple-upgraded--unbounded.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

.mdc-ripple-surface:hover::before, .mdc-ripple-surface.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-ripple-surface.mdc-ripple-upgraded--background-focused::before, .mdc-ripple-surface:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-ripple-surface:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-ripple-surface:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-ripple-surface.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-snackbar {
  z-index: 8;
  margin: 8px;
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  pointer-events: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mdc-snackbar__surface {
  background-color: #333333;
}

.mdc-snackbar__label {
  color: rgba(255, 255, 255, 0.87);
}

.mdc-snackbar__surface {
  min-width: 344px;
}

@media (max-width: 480px), (max-width: 344px) {
  .mdc-snackbar__surface {
    min-width: 100%;
  }
}
.mdc-snackbar__surface {
  max-width: 672px;
}

.mdc-snackbar__surface {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mdc-snackbar__surface {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-snackbar--opening,
.mdc-snackbar--open,
.mdc-snackbar--closing {
  display: flex;
}

.mdc-snackbar--open .mdc-snackbar__label,
.mdc-snackbar--open .mdc-snackbar__actions {
  visibility: visible;
}

.mdc-snackbar--leading {
  justify-content: flex-start;
}

.mdc-snackbar--stacked .mdc-snackbar__label {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  padding-bottom: 12px;
}

[dir=rtl] .mdc-snackbar--stacked .mdc-snackbar__label, .mdc-snackbar--stacked .mdc-snackbar__label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-snackbar--stacked .mdc-snackbar__surface {
  flex-direction: column;
  align-items: flex-start;
}

.mdc-snackbar--stacked .mdc-snackbar__actions {
  align-self: flex-end;
  margin-bottom: 8px;
}

.mdc-snackbar__surface {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  transform: scale(0.8);
  opacity: 0;
}

.mdc-snackbar__surface::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-snackbar__surface::before {
    border-color: CanvasText;
  }
}
[dir=rtl] .mdc-snackbar__surface, .mdc-snackbar__surface[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-snackbar--open .mdc-snackbar__surface {
  transform: scale(1);
  opacity: 1;
  pointer-events: auto;
  transition: opacity 150ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-snackbar--closing .mdc-snackbar__surface {
  transform: scale(1);
  transition: opacity 75ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-snackbar__label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  visibility: hidden;
  padding-top: 14px;
  padding-bottom: 14px;
}

[dir=rtl] .mdc-snackbar__label, .mdc-snackbar__label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-snackbar__label::before {
  display: inline;
  content: attr(data-mdc-snackbar-label-text);
}

.mdc-snackbar__actions {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  box-sizing: border-box;
  visibility: hidden;
}

.mdc-snackbar__action:not(:disabled) {
  color: #bb86fc;
}

.mdc-snackbar__action .mdc-button__ripple::before, .mdc-snackbar__action .mdc-button__ripple::after {
  background-color: #bb86fc;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #bb86fc);
}

.mdc-snackbar__action:hover .mdc-button__ripple::before, .mdc-snackbar__action.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}

.mdc-snackbar__action.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-snackbar__action:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}

.mdc-snackbar__action:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-snackbar__action:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-snackbar__action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-snackbar__dismiss {
  color: rgba(255, 255, 255, 0.87);
}

.mdc-snackbar__dismiss .mdc-icon-button__ripple::before, .mdc-snackbar__dismiss .mdc-icon-button__ripple::after {
  background-color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(255, 255, 255, 0.87));
}

.mdc-snackbar__dismiss:hover .mdc-icon-button__ripple::before, .mdc-snackbar__dismiss.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}

.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, .mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}

.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-snackbar__dismiss.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-snackbar__dismiss.mdc-snackbar__dismiss {
  width: 36px;
  height: 36px;
  padding: 6px;
  font-size: 18px;
}

.mdc-snackbar__dismiss.mdc-snackbar__dismiss .mdc-icon-button__focus-ring {
  display: none;
}

.mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-snackbar__dismiss.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  display: block;
  max-height: 36px;
  max-width: 36px;
}

@media screen and (forced-colors: active) {
  .mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-snackbar__dismiss.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    pointer-events: none;
    border: 2px solid transparent;
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-snackbar__dismiss.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    border-color: CanvasText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-snackbar__dismiss.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    content: "";
    border: 2px solid transparent;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-snackbar__dismiss.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 36px;
  height: 36px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}

.mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-icon-button--reduced-size.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-icon-button--reduced-size:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  max-height: 36px;
  max-width: 36px;
}

.mdc-snackbar__dismiss.mdc-snackbar__dismiss .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 36px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 36px;
  transform: translate(-50%, -50%);
}

.mdc-snackbar__action + .mdc-snackbar__dismiss {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}

[dir=rtl] .mdc-snackbar__action + .mdc-snackbar__dismiss, .mdc-snackbar__action + .mdc-snackbar__dismiss[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-bar {
  width: 100%;
}

.mdc-tab {
  height: 48px;
}

.mdc-tab--stacked {
  height: 72px;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-color: #37474f;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #37474f);
}

.mdc-tab-indicator .mdc-tab-indicator__content--icon {
  color: #ffc107;
  /* @alternate */
  color: var(--mdc-theme-secondary, #ffc107);
}

.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-top-width: 2px;
}

.mdc-tab-indicator .mdc-tab-indicator__content--icon {
  height: 34px;
  font-size: 34px;
}

.mdc-tab-indicator {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.mdc-tab-indicator__content {
  transform-origin: left;
  opacity: 0;
}

.mdc-tab-indicator__content--underline {
  align-self: flex-end;
  box-sizing: border-box;
  width: 100%;
  border-top-style: solid;
}

.mdc-tab-indicator__content--icon {
  align-self: center;
  margin: 0 auto;
}

.mdc-tab-indicator--active .mdc-tab-indicator__content {
  opacity: 1;
}

.mdc-tab-indicator .mdc-tab-indicator__content {
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-tab-indicator--no-transition .mdc-tab-indicator__content {
  transition: none;
}

.mdc-tab-indicator--fade .mdc-tab-indicator__content {
  transition: 150ms opacity linear;
}

.mdc-tab-indicator--active.mdc-tab-indicator--fade .mdc-tab-indicator__content {
  transition-delay: 100ms;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-scroller {
  overflow-y: hidden;
}

.mdc-tab-scroller.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-content {
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-tab-scroller__test {
  position: absolute;
  top: -9999px;
  width: 100px;
  height: 100px;
  overflow-x: scroll;
}

.mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: touch;
  display: flex;
  overflow-x: hidden;
}

.mdc-tab-scroller__scroll-area::-webkit-scrollbar,
.mdc-tab-scroller__test::-webkit-scrollbar {
  display: none;
}

.mdc-tab-scroller__scroll-area--scroll {
  overflow-x: scroll;
}

.mdc-tab-scroller__scroll-content {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  transform: none;
  will-change: transform;
}

.mdc-tab-scroller--align-start .mdc-tab-scroller__scroll-content {
  justify-content: flex-start;
}

.mdc-tab-scroller--align-end .mdc-tab-scroller__scroll-content {
  justify-content: flex-end;
}

.mdc-tab-scroller--align-center .mdc-tab-scroller__scroll-content {
  justify-content: center;
}

.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: auto;
}

.mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
  position: relative;
}

.mdc-tab .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-tab .mdc-tab__icon {
  color: rgba(0, 0, 0, 0.54);
  fill: currentColor;
}

.mdc-tab.mdc-ripple-upgraded--background-focused.mdc-tab--active .mdc-tab__focus-ring, .mdc-tab:not(.mdc-ripple-upgraded):focus.mdc-tab--active .mdc-tab__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 8px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(
      100% + -12px
    );
  width: calc(
      100% + -8px
    );
  margin-top: -2px;
  z-index: 2;
}

@media screen and (forced-colors: active) {
  .mdc-tab.mdc-ripple-upgraded--background-focused.mdc-tab--active .mdc-tab__focus-ring, .mdc-tab:not(.mdc-ripple-upgraded):focus.mdc-tab--active .mdc-tab__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-tab.mdc-ripple-upgraded--background-focused.mdc-tab--active .mdc-tab__focus-ring::after, .mdc-tab:not(.mdc-ripple-upgraded):focus.mdc-tab--active .mdc-tab__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 10px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}

@media screen and (forced-colors: active) {
  .mdc-tab.mdc-ripple-upgraded--background-focused.mdc-tab--active .mdc-tab__focus-ring::after, .mdc-tab:not(.mdc-ripple-upgraded):focus.mdc-tab--active .mdc-tab__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__focus-ring, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 8px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(
      100% + -8px
    );
  width: calc(
      100% + -8px
    );
  z-index: 2;
}

@media screen and (forced-colors: active) {
  .mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__focus-ring, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__focus-ring::after, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 10px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}

@media screen and (forced-colors: active) {
  .mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__focus-ring::after, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-tab__content {
  position: relative;
}

.mdc-tab__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.mdc-tab--active .mdc-tab__text-label {
  color: #37474f;
  /* @alternate */
  color: var(--mdc-theme-primary, #37474f);
}

.mdc-tab--active .mdc-tab__icon {
  color: #37474f;
  /* @alternate */
  color: var(--mdc-theme-primary, #37474f);
  fill: currentColor;
}

.mdc-tab {
  background: none;
}

.mdc-tab {
  min-width: 90px;
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  outline: none;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 1;
}

.mdc-tab::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mdc-tab--min-width {
  flex: 0 1 auto;
}

.mdc-tab__content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  pointer-events: none;
}

.mdc-tab__text-label {
  transition: 150ms color linear;
  display: inline-block;
  line-height: 1;
  z-index: 2;
}

.mdc-tab__icon {
  transition: 150ms color linear;
  z-index: 2;
}

.mdc-tab--stacked .mdc-tab__content {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mdc-tab--stacked .mdc-tab__text-label {
  padding-top: 6px;
  padding-bottom: 4px;
}

.mdc-tab--active .mdc-tab__text-label,
.mdc-tab--active .mdc-tab__icon {
  transition-delay: 100ms;
}

.mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label, .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-tab {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mdc-tab .mdc-tab__ripple::before,
.mdc-tab .mdc-tab__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-tab .mdc-tab__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-tab .mdc-tab__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-tab.mdc-ripple-upgraded--unbounded .mdc-tab__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-tab.mdc-ripple-upgraded--foreground-activation .mdc-tab__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-tab.mdc-ripple-upgraded--foreground-deactivation .mdc-tab__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-tab .mdc-tab__ripple::before,
.mdc-tab .mdc-tab__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-tab .mdc-tab__ripple::before, .mdc-tab .mdc-tab__ripple::after {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #37474f));
}

.mdc-tab:hover .mdc-tab__ripple::before, .mdc-tab.mdc-ripple-surface--hover .mdc-tab__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__ripple::before, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-tab:not(.mdc-ripple-upgraded) .mdc-tab__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-tab:not(.mdc-ripple-upgraded):active .mdc-tab__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-tab.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-tab__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  will-change: transform, opacity;
}

.mdc-top-app-bar {
  background-color: #37474f;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #37474f);
  color: white;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  z-index: 4;
}

.mdc-top-app-bar .mdc-top-app-bar__action-item,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
  color: #fafafa;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fafafa);
}

.mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
  background-color: #fafafa;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-primary, #fafafa));
}

.mdc-top-app-bar .mdc-top-app-bar__action-item:hover::before, .mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-surface--hover::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:hover::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-surface--hover::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}

.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before, .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}

.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-top-app-bar__row {
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 64px;
}

.mdc-top-app-bar__section {
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  min-width: 0;
  padding: 8px 12px;
  z-index: 1;
}

.mdc-top-app-bar__section--align-start {
  justify-content: flex-start;
  order: -1;
}

.mdc-top-app-bar__section--align-end {
  justify-content: flex-end;
  order: 1;
}

.mdc-top-app-bar__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  /* @noflip */ /*rtl:ignore*/
  padding-left: 20px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
}

[dir=rtl] .mdc-top-app-bar__title, .mdc-top-app-bar__title[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 20px;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--short-collapsed {
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 24px;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
}

[dir=rtl] .mdc-top-app-bar--short-collapsed, .mdc-top-app-bar--short-collapsed[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 24px;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--short {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  right: auto;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  width: 100%;
  transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

[dir=rtl] .mdc-top-app-bar--short, .mdc-top-app-bar--short[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /* @noflip */ /*rtl:ignore*/
  left: auto;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--short .mdc-top-app-bar__row {
  height: 56px;
}

.mdc-top-app-bar--short .mdc-top-app-bar__section {
  padding: 4px;
}

.mdc-top-app-bar--short .mdc-top-app-bar__title {
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}

.mdc-top-app-bar--short-collapsed {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 56px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__title {
  display: none;
}

.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__action-item {
  transition: padding 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item {
  width: 112px;
}

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}

[dir=rtl] .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--dense .mdc-top-app-bar__row {
  height: 48px;
}

.mdc-top-app-bar--dense .mdc-top-app-bar__section {
  padding: 0 4px;
}

.mdc-top-app-bar--dense .mdc-top-app-bar__title {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-top-app-bar--dense .mdc-top-app-bar__title, .mdc-top-app-bar--dense .mdc-top-app-bar__title[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 128px;
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  align-self: flex-end;
  padding-bottom: 2px;
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__action-item,
.mdc-top-app-bar--prominent .mdc-top-app-bar__navigation-icon {
  align-self: flex-start;
}

.mdc-top-app-bar--fixed {
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--fixed-scrolled {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 96px;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__section {
  padding: 0 12px;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 20px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  padding-bottom: 9px;
}

[dir=rtl] .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title, .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 20px;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: 64px;
}

.mdc-top-app-bar--dense-fixed-adjust {
  padding-top: 48px;
}

.mdc-top-app-bar--short-fixed-adjust {
  padding-top: 56px;
}

.mdc-top-app-bar--prominent-fixed-adjust {
  padding-top: 128px;
}

.mdc-top-app-bar--dense-prominent-fixed-adjust {
  padding-top: 96px;
}

@media (max-width: 599px) {
  .mdc-top-app-bar__row {
    height: 56px;
  }
  .mdc-top-app-bar__section {
    padding: 4px;
  }
  .mdc-top-app-bar--short {
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .mdc-top-app-bar--short-collapsed {
    transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end {
    /* @noflip */ /*rtl:ignore*/
    padding-left: 0;
    /* @noflip */ /*rtl:ignore*/
    padding-right: 12px;
  }
  [dir=rtl] .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end[dir=rtl] {
    /*rtl:begin:ignore*/
    /* @noflip */ /*rtl:ignore*/
    padding-left: 12px;
    /* @noflip */ /*rtl:ignore*/
    padding-right: 0;
    /*rtl:end:ignore*/
  }
  .mdc-top-app-bar--prominent .mdc-top-app-bar__title {
    padding-bottom: 6px;
  }
  .mdc-top-app-bar--fixed-adjust {
    padding-top: 56px;
  }
}
.mdc-typography {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-font-family, Roboto, sans-serif);
}

.mdc-typography--headline1 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 6rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline1-font-size, 6rem);
  line-height: 6rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline1-line-height, 6rem);
  font-weight: 300;
  /* @alternate */
  font-weight: var(--mdc-typography-headline1-font-weight, 300);
  letter-spacing: -0.015625em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline1-letter-spacing, -0.015625em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline1-text-transform, inherit);
}

.mdc-typography--headline2 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 3.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline2-font-size, 3.75rem);
  line-height: 3.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline2-line-height, 3.75rem);
  font-weight: 300;
  /* @alternate */
  font-weight: var(--mdc-typography-headline2-font-weight, 300);
  letter-spacing: -0.0083333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline2-letter-spacing, -0.0083333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline2-text-transform, inherit);
}

.mdc-typography--headline3 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline3-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 3rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline3-font-size, 3rem);
  line-height: 3.125rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline3-line-height, 3.125rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline3-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline3-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline3-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline3-text-transform, inherit);
}

.mdc-typography--headline4 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline4-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 2.125rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline4-font-size, 2.125rem);
  line-height: 2.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline4-line-height, 2.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline4-font-weight, 400);
  letter-spacing: 0.0073529412em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline4-letter-spacing, 0.0073529412em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline4-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline4-text-transform, inherit);
}

.mdc-typography--headline5 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline5-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.5rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline5-font-size, 1.5rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline5-line-height, 2rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline5-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline5-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline5-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline5-text-transform, inherit);
}

.mdc-typography--headline6 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
}

.mdc-typography--subtitle1 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
}

.mdc-typography--subtitle2 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
}

.mdc-typography--body1 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: 0.03125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
}

.mdc-typography--body2 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mdc-typography--caption {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}

.mdc-typography--button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
}

.mdc-typography--overline {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 0.75rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 500);
  letter-spacing: 0.1666666667em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, 0.1666666667em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, uppercase);
}

.mdc-evolution-chip--dense {
  height: 24px;
  height: 24px;
}
.mdc-evolution-chip--dense .mdc-evolution-chip__action-touch {
  display: none;
}

.mdc-button--dense {
  height: 28px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 10px 0 10px;
}
.mdc-button--dense .mdc-button__touch {
  height: 100%;
}

.mdc-text-field--dense {
  height: 30px;
}

.mdc-text-field--fullwidth {
  width: 100%;
  padding-left: 6px;
  padding-right: 6px;
}

.mdc-deprecated-list-item__graphic .material-icons {
  width: 24px;
}

.mdc-top-app-bar__section .mdc-button:disabled {
  color: rgba(255, 255, 255, 0.23);
}

input.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: initial;
}

input.mdc-text-field__input[type=date]::-webkit-input-placeholder {
  visibility: initial;
}

.mdc-data-table__row-checkbox {
  padding: calc((32px - 18px) / 2);
  /* @alternate */
  padding: calc((var(--mdc-checkbox-ripple-size, 32px) - 18px) / 2);
  margin: calc((32px - 32px) / 2);
  /* @alternate */
  margin: calc((var(--mdc-checkbox-touch-target-size, 32px) - 32px) / 2);
}
.mdc-data-table__row-checkbox .mdc-checkbox__background {
  top: calc((32px - 18px) / 2);
  /* @alternate */
  top: calc((var(--mdc-checkbox-ripple-size, 32px) - 18px) / 2);
  left: calc((32px - 18px) / 2);
  /* @alternate */
  left: calc((var(--mdc-checkbox-ripple-size, 32px) - 18px) / 2);
}
.mdc-data-table__row-checkbox .mdc-checkbox__native-control {
  top: calc((32px - 32px) / 2);
  /* @alternate */
  top: calc((32px - var(--mdc-checkbox-touch-target-size, 32px)) / 2);
  right: calc((32px - 32px) / 2);
  /* @alternate */
  right: calc((32px - var(--mdc-checkbox-touch-target-size, 32px)) / 2);
  left: calc((32px - 32px) / 2);
  /* @alternate */
  left: calc((32px - var(--mdc-checkbox-touch-target-size, 32px)) / 2);
  width: 32px;
  /* @alternate */
  width: var(--mdc-checkbox-touch-target-size, 32px);
  height: 32px;
  /* @alternate */
  height: var(--mdc-checkbox-touch-target-size, 32px);
}
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: #7b7b7b;
  /* @alternate */
  border-color: var(--mdc-checkbox-unchecked-color, #7b7b7b);
  background-color: #fafafa;
}
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
  border-color: #7b7b7b;
  /* @alternate */
  border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #7b7b7b));
  background-color: #7b7b7b;
  /* @alternate */
  background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #7b7b7b));
}
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #ffffff;
  /* @alternate */
  color: var(--mdc-checkbox-ink-color, #ffffff);
}
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #ffffff;
  /* @alternate */
  border-color: var(--mdc-checkbox-ink-color, #ffffff);
}
.mdc-data-table__row-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: #dedede;
  /* @alternate */
  border-color: var(--mdc-checkbox-disabled-color, #dedede);
  background-color: #fafafa;
}
.mdc-data-table__row-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control[data-indeterminate=true][disabled] ~ .mdc-checkbox__background {
  border-color: #dedede;
  background-color: #dedede;
  /* @alternate */
  background-color: var(--mdc-checkbox-disabled-color, #dedede);
}
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #b0b0b0;
  /* @alternate */
  color: var(--mdc-checkbox-ink-color, #b0b0b0);
}
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #b0b0b0;
  /* @alternate */
  border-color: var(--mdc-checkbox-ink-color, #b0b0b0);
}

.mdc-data-table__header-row-checkbox {
  padding: calc((32px - 18px) / 2);
  /* @alternate */
  padding: calc((var(--mdc-checkbox-ripple-size, 32px) - 18px) / 2);
  margin: calc((32px - 32px) / 2);
  /* @alternate */
  margin: calc((var(--mdc-checkbox-touch-target-size, 32px) - 32px) / 2);
}
.mdc-data-table__header-row-checkbox .mdc-checkbox__background {
  top: calc((32px - 18px) / 2);
  /* @alternate */
  top: calc((var(--mdc-checkbox-ripple-size, 32px) - 18px) / 2);
  left: calc((32px - 18px) / 2);
  /* @alternate */
  left: calc((var(--mdc-checkbox-ripple-size, 32px) - 18px) / 2);
}
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control {
  top: calc((32px - 32px) / 2);
  /* @alternate */
  top: calc((32px - var(--mdc-checkbox-touch-target-size, 32px)) / 2);
  right: calc((32px - 32px) / 2);
  /* @alternate */
  right: calc((32px - var(--mdc-checkbox-touch-target-size, 32px)) / 2);
  left: calc((32px - 32px) / 2);
  /* @alternate */
  left: calc((32px - var(--mdc-checkbox-touch-target-size, 32px)) / 2);
  width: 32px;
  /* @alternate */
  width: var(--mdc-checkbox-touch-target-size, 32px);
  height: 32px;
  /* @alternate */
  height: var(--mdc-checkbox-touch-target-size, 32px);
}
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: #7b7b7b;
  /* @alternate */
  border-color: var(--mdc-checkbox-unchecked-color, #7b7b7b);
  background-color: #fafafa;
}
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
  border-color: #7b7b7b;
  /* @alternate */
  border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #7b7b7b));
  background-color: #7b7b7b;
  /* @alternate */
  background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #7b7b7b));
}
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #ffffff;
  /* @alternate */
  color: var(--mdc-checkbox-ink-color, #ffffff);
}
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #ffffff;
  /* @alternate */
  border-color: var(--mdc-checkbox-ink-color, #ffffff);
}
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: #dedede;
  /* @alternate */
  border-color: var(--mdc-checkbox-disabled-color, #dedede);
  background-color: #fafafa;
}
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control[data-indeterminate=true][disabled] ~ .mdc-checkbox__background {
  border-color: #dedede;
  background-color: #dedede;
  /* @alternate */
  background-color: var(--mdc-checkbox-disabled-color, #dedede);
}
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #b0b0b0;
  /* @alternate */
  color: var(--mdc-checkbox-ink-color, #b0b0b0);
}
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #b0b0b0;
  /* @alternate */
  border-color: var(--mdc-checkbox-ink-color, #b0b0b0);
}

.mdc-data-tabe--dense .mdc-data-table__row {
  height: 44px;
}
.mdc-data-tabe--dense .mdc-data-table__pagination {
  min-height: 44px;
}
.mdc-data-tabe--dense .mdc-data-table__header-row {
  height: 48px;
}
.mdc-data-tabe--dense .mdc-data-table__cell,
.mdc-data-tabe--dense .mdc-data-table__header-cell {
  padding: 0 4px 0 16px;
}
.mdc-data-tabe--dense .mdc-data-table__header-cell--checkbox,
.mdc-data-tabe--dense .mdc-data-table__cell--checkbox {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-data-tabe--dense .mdc-data-table__header-cell--checkbox, [dir=rtl] .mdc-data-tabe--dense .mdc-data-table__cell--checkbox, .mdc-data-tabe--dense .mdc-data-table__header-cell--checkbox[dir=rtl], .mdc-data-tabe--dense .mdc-data-table__cell--checkbox[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-data-tabe--dense .mdc-data-table__cell {
  font-size: small;
}

.mdc-data-table__pagination-total b, .mdc-data-table__pagination-total strong {
  font-weight: normal;
}

.timeline-vline {
  width: 10px;
  margin-top: 3px;
  margin-bottom: 8px;
  border-right-width: 4px;
  border-right-style: dotted;
  border-right-color: #606060;
}

.timeline-abbr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.timeline-abbr-line {
  flex-grow: 0.5;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.24);
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  height: 6px;
  cursor: pointer;
}

.timeline-abbr-circle {
  border: 1px solid rgba(0, 0, 0, 0.24);
  background-color: #EFEFEF;
  border-radius: 100%;
  color: #666;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  width: 32px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
}

.timeline-message-footer {
  border-top: 1px dotted #D5D5D5;
}

.timeline-email .timeline-vline {
  border-right-color: #3572B0;
}
.timeline-email .timeline-icon {
  color: #3572B0;
}

.timeline-survey .timeline-vline {
  border-right-color: #C54034;
}
.timeline-survey .timeline-icon {
  color: #C54034;
}

.timeline-enquiry .timeline-vline {
  border-right-color: #48AADF;
}
.timeline-enquiry .timeline-icon {
  color: #48AADF;
}

.timeline-response .timeline-vline {
  border-right-color: #67AB49;
}
.timeline-response .timeline-icon {
  color: #67AB49;
}

.timeline-comment .timeline-vline {
  border-right-color: #67AB49;
}
.timeline-comment .timeline-icon {
  color: #67AB49;
}

.timeline-task .timeline-vline {
  border-right-color: #606060;
}
.timeline-task .timeline-icon {
  color: #606060;
}

.timeline-activity {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.timeline-activity.source-closed .timeline-icon {
  color: #67AB49;
}
.timeline-activity.source-opened .timeline-icon {
  color: #C54034;
}
.timeline-activity.source-assigned .timeline-icon {
  color: #E04A00;
}

.timeline-actions {
  float: right;
}
.timeline-actions a {
  text-align: center;
  display: block;
  padding-bottom: 14px;
  color: #606060;
  cursor: pointer;
  cursor: hand;
}
.timeline-actions a:hover {
  text-decoration: none;
  cursor: pointer;
  cursor: hand;
}

.body-part-full {
  padding-top: 8px;
  min-height: 36px;
}
.body-part-full .message-body {
  min-height: 30px;
  overflow: auto;
  display: inline;
  width: 100%;
}

.body-part-excerpt {
  padding-top: 8px;
  min-height: 36px;
}

.shrink .body-part-excerpt {
  display: block;
  color: #616161;
}
.shrink .body-part-full {
  display: none;
}

.expand .body-part-excerpt {
  display: none;
}
.expand .body-part-full {
  display: block;
}
.expand .crm-message--head {
  cursor: pointer;
  cursor: hand;
}

.expand-body {
  cursor: pointer;
  cursor: hand;
}

.message-body img {
  max-width: 100% !important;
  overflow: auto;
}
.message-body pre {
  margin: 0;
  border: none;
  background: none;
  font-size: 0.8em;
}
.message-body blockquote {
  padding: 2px 0 2px 2px;
  margin: 2px 0 2px 3px;
  border-left: 2px solid #EEEEEE;
}
.message-body blockquote.auto-level-1 {
  padding: 5px 0 5px 8px;
  margin: 10px 0 10px 3px;
  border-left: 2px solid #909090;
}
.message-body blockquote.auto-level-2 {
  padding: 3px 0 3px 5px;
  margin: 5px 0 5px 3px;
  border-left: 2px solid #468847;
}
.message-body blockquote.auto-level-3 {
  padding: 0 0 0 5px;
  margin: 3px 0 3px 5px;
  border-left: 2px solid #3a87ad;
}
.message-body blockquote p {
  font-size: inherit;
}

.crm-inbox--item {
  cursor: pointer;
}
.crm-inbox--item:hover {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #f8f8f8;
}

.crm-inbox--item__support {
  padding: 16px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.crm-inbox--item__support:hover {
  border-bottom: 1px solid transparent;
}

.crm-message {
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;
}

.crm-message--main {
  max-width: calc(100% - 32px);
}

.crm-message--head {
  max-width: calc(100% - 180px);
}

.crm-log .timeline-vline,
.crm-log .timeline-actions {
  display: none;
}

.shrunk {
  display: none;
}

table.crm-table {
  border: none;
  width: 100%;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
}
table.crm-table tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
table.crm-table tbody tr:hover {
  background-color: #f6f6f6;
}
table.crm-table tbody.border {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
table.crm-table td, table.crm-table th {
  border: none;
  padding: 16px 16px;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 2px;
}
table.crm-table th {
  color: #606060;
  font-weight: 500;
  font-size: 12px;
}
table.crm-table td {
  color: #202020;
  font-size: 14px;
}
table.crm-table .r-border {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
table.crm-table td.n-cell, table.crm-table th.n-cell {
  text-align: right;
}
table.crm-table td.i-cell {
  padding-top: 0;
  padding-bottom: 0;
}
table.crm-table tr.inactive td {
  color: #666;
}
table.crm-table tr.separator {
  border-bottom: 2px solid rgba(0, 0, 0, 0.22);
}
table.crm-table tr.separator th, table.crm-table tr.separator td {
  padding: 12px 16px 4px;
}

.crm-no-border-at-end > tr:last-child {
  border-bottom: none;
}

.crm-card--header {
  height: 64px;
  padding: 0 16px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.crm-card--header-small {
  height: 56px;
}

.crm-card--header-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
}

.crm-card--content {
  padding: 0 16px;
  margin-bottom: 8px;
}

.crm-table--footer {
  height: 64px;
  padding: 0 16px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.crm-table--footer-nav {
  margin-left: 10px;
}
.crm-table--footer-nav a, .crm-table--footer-nav a:visited {
  color: #202020;
  text-decoration: none;
}

.crm-table--footer-meta {
  font-size: 0.85rem;
  box-sizing: border-box;
  display: flex;
  vertical-align: middle;
  align-items: center;
  color: #666;
  margin-left: 30px;
}

.crm-table--page-numbers {
  padding-right: 44px;
}

table.crm-table--dense td, table.crm-table--dense th {
  border: none;
  padding: 0;
  border-radius: 0;
}
table.crm-table--dense td {
  padding: 4px 2px;
}
table.crm-table--dense td .material-icons {
  font-size: 20px;
}
table.crm-table--dense td a {
  display: block;
  text-align: center;
  padding: 2px 0;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 12px;
  color: #000;
}
table.crm-table--dense td a.crm-partial--edit-lang {
  color: #000;
  font-weight: 600;
}
table.crm-table--dense td a.crm-partial--add-lang {
  color: #707070;
  font-weight: normal;
}
table.crm-table--dense td a.crm-partial--add-product {
  color: #999;
}
table.crm-table--dense .r-border {
  padding-left: 16px;
}
table.crm-table--dense tbody a:hover {
  background-color: #e6e6e6;
}

table.crm-table--issue {
  border: none;
  width: 100%;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
}
table.crm-table--issue tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
table.crm-table--issue td, table.crm-table--issue th {
  border: none;
  display: table-cell;
  text-align: left;
  padding: 8px;
  vertical-align: middle;
  border-radius: 0;
}
table.crm-table--issue th {
  color: #606060;
  font-weight: 500;
  font-size: 12px;
}
table.crm-table--issue td {
  color: #202020;
  font-size: 14px;
}
table.crm-table--issue td .material-icons {
  font-size: 20px;
}

th.crm-rotated-cell {
  height: 100px;
  white-space: nowrap;
}
th.crm-rotated-cell > div {
  transform: translate(23px, 29px) rotate(315deg);
  width: 43px;
}
th.crm-rotated-cell > div > span {
  border-bottom: 1px solid #c0c0c0;
  padding: 5px 4px;
}

thead.crm-table-fixed--head {
  height: 100px;
  display: block;
  padding-left: 272px;
  border-bottom: 2px solid #c0c0c0;
}
thead.crm-table-fixed--head td {
  height: 100px;
  width: 40px;
}

tbody.crm-table-fixed--body {
  display: block;
  overflow-y: auto;
  height: calc(100vh - 195px);
}
tbody.crm-table-fixed--body td {
  width: 38px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
tbody.crm-table-fixed--body td.crm-table-fixed--first-column {
  width: 250px;
}

.crm-tooltip {
  position: relative;
  display: inline-block;
}

.crm-tooltip-entry {
  padding-bottom: 6px;
}

.crm-tooltip-entry-name {
  font-weight: 500;
  padding-right: 4px;
}

.crm-tooltip-entry-name::after {
  content: ":";
}

.crm-tooltip .crm-tooltiptext {
  visibility: hidden;
  width: 280px;
  background-color: #f0f0f0;
  color: #202020;
  border-radius: 2px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -140px;
}

.crm-tooltip:hover .crm-tooltiptext {
  visibility: visible;
}

.crm-chip-category-ro {
  letter-spacing: -0.025rem;
  white-space: nowrap;
  font-size: 0.7rem;
  height: 1rem;
  line-height: 1rem;
  padding: 2px 6px;
  margin: 2px;
  border-radius: 10px;
  background-color: #e0e0e0;
}

.crm-category-grid {
  width: 540px;
  display: grid;
  row-gap: 0.25rem;
  column-gap: 0.5rem;
  grid-template-rows: repeat(9, 1fr);
  grid-auto-flow: column;
}

.crm-category-grid-cell {
  width: auto;
}

.crm-menu-anchor--top-right {
  position: absolute;
  top: 48px;
  right: 0px;
}

.crm-notification-action-menu {
  width: 400px;
  max-height: 600px !important;
}

.crm-error-section {
  background-color: #ffc107;
  color: #263238;
  padding: 8px 14px;
  border-radius: 2px;
}
.crm-error-section a, .crm-error-section button {
  color: #263238;
}

.crm-notice-section {
  background-color: #fafafa;
  color: #263238;
  padding: 8px 14px;
  border-radius: 2px;
}
.crm-notice-section a, .crm-notice-section button {
  color: #263238;
}

.crm-icon-title {
  height: 32px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
}

.crm-drawer {
  width: 54px;
  position: fixed;
  z-index: 3;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-transition: width 200ms ease-in-out;
  -moz-transition: width 200ms ease-in-out;
  -o-transition: width 200ms ease-in-out;
  transition: width 200ms ease-in-out;
}
.crm-drawer .mdc-deprecated-list-item__graphic {
  margin-right: 18px;
}

.crm-drawer:hover {
  width: 200px;
  overflow-y: auto;
}

.crm-drawer-adjust {
  margin-left: 54px;
}

.crm-top-bar-title {
  font-size: 1.25rem;
  font-weight: 300;
  max-width: 600px;
  max-width: 55vw;
  margin-right: 48px;
}

.crm-tab-bar {
  margin-bottom: 8px;
}
.crm-tab-bar .mdc-tab-indicator__content--underline {
  height: 3px;
}

.crm-main {
  background-color: whitesmoke;
  min-height: 800px;
  min-height: calc(100vh - 48px);
}

.crm-red {
  color: #DA4F49 !important;
}

.crm-grey {
  color: #616161 !important;
}

.crm-green {
  color: #19A974 !important;
}

.crm-student-not-active {
  background-color: #e8e8e8;
}

.crm-menu-count {
  display: inline-block;
  padding-left: 10px;
}

.crm-help--title {
  margin: 0px 0 8px;
  padding-bottom: 2px;
  padding-top: 8px;
  border-bottom: 1px solid #999;
}

.crm-modal-drawer {
  padding: 18px 24px;
  width: 480px;
  left: initial;
  right: 0;
  border-right-width: 0;
  border-left-width: 1px;
  border-right-style: none;
  border-left-style: solid;
}
.crm-modal-drawer.mdc-drawer--animate {
  transform: translateX(100%);
}
.crm-modal-drawer.mdc-drawer--opening {
  transition-duration: 350ms;
  transform: translateX(0);
}
.crm-modal-drawer.mdc-drawer--closing {
  transition-duration: 300ms;
  transform: translateX(100%);
}

.crm-notification--footer {
  padding-left: 30px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.crm-notification--list .mdc-deprecated-list-item {
  font-size: 0.8rem;
  padding: 0 12px 0 6px;
}
.crm-notification--list .mdc-deprecated-list-item__graphic {
  margin-right: 4px !important;
}
.crm-notification--list .mdc-deprecated-list-item__secondary-text {
  font-size: 0.75rem;
}

.crm-suppression-progress {
  margin-top: 24px;
  display: none;
}

.crm-search-field {
  height: 40px;
  min-width: 300px;
}

.crm-search-field__icon {
  right: 0px !important;
  bottom: 8px !important;
}

.crm-search-field__input {
  font-size: 0.85rem;
  font-weight: 500;
}
.crm-search-field__input::-webkit-input-placeholder {
  font-weight: 400;
}
.crm-search-field__input::-moz-placeholder {
  font-weight: 400;
}
.crm-search-field__input:-ms-input-placeholder {
  font-weight: 400;
}
.crm-search-field__input:-moz-placeholder {
  font-weight: 400;
}
.crm-search-field__input::placeholder {
  font-weight: 400;
}

.crm-global-search__form {
  margin: 0 24px;
  margin-right: 32px;
}

.crm-global-search__input {
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 14rem;
  padding: 4px 12px;
  background: rgba(255, 255, 255, 0.1);
  color: #fafafa;
}
.crm-global-search__input:focus {
  background: rgba(255, 255, 255, 0.15);
  outline: none;
}
.crm-global-search__input:invalid {
  box-shadow: none;
}
.crm-global-search__input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.crm-global-search__input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.crm-global-search__input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.crm-global-search__input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.crm-global-search__input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.crm-grid-row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  height: 60px;
  vertical-align: middle;
  align-items: center;
}

.crm-grid--gutter-48 {
  --mdc-layout-grid-gutter-desktop: 48px;
}

.crm-form--header {
  height: 48px;
  padding: 0 16px;
  border-bottom: 2px solid #eee;
  margin-bottom: 12px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.crm-form--content {
  padding: 0 16px;
  margin-bottom: 10px;
}
.crm-form--content .mdc-text-field, .crm-form--content .mdc-select,
.crm-form--content input[type=text], .crm-form--content select {
  width: 100%;
}

.crm-form--row {
  padding-top: 24px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}

.crm-form--footer {
  height: 64px;
  margin-top: 24px;
  padding: 0 8px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.crm-form--footer > .mdc-button {
  margin-right: 24px;
}

.crm-form--footer-even {
  height: 50px;
  margin-top: 10px;
  padding: 0 30px;
  justify-content: space-evenly;
}

.crm-form--buttons {
  height: 56px;
  margin-top: 8px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.crm-form--buttons > .mdc-button {
  margin-right: 16px;
}

.crm-form--hint {
  padding: 4px 14px;
}

.crm-form--errors {
  padding: 4px 14px;
  background-color: #ffc107;
  color: #263238;
}

.crm-checkbox-list {
  display: flex;
  flex-direction: column;
}
.crm-checkbox-list > .mdc-form-field {
  display: flex;
}

.crm-a {
  text-decoration: none;
}
.crm-a:link, .crm-a:visited {
  text-decoration: none;
  color: #37474f;
}
.crm-a:hover {
  color: #37474f;
  text-decoration: underline;
}
.crm-a:active {
  color: #37474f;
  text-decoration: none;
}

.crm-label--error {
  color: #DA4F49 !important;
}

.crm-error--message {
  color: #DA4F49 !important;
}

.crm-menu-items a {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  text-decoration: none;
}

.crm-learner-list-courses {
  font-size: 0.75rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
  margin-bottom: 8px;
}

.crm-learners-filtered-list {
  padding: 0 4px 8px 8px;
}

.crm-scroll-to-top {
  position: fixed;
  bottom: 24px;
  display: none;
}

.crm-side-form {
  overflow: hidden;
}

.crm-login-form {
  border-radius: 4px;
  padding: 0 40px 40px;
  width: 320px;
}

.crm-fixed--span-3 {
  background-color: #ffffff;
  min-width: 210px;
  width: 280px;
  width: calc(25vw - 44px);
  min-height: 320px;
  max-height: 600px;
  max-height: calc(100vh - 96px);
  overflow-y: scroll;
}

.crm-fixed--span-4 {
  background-color: #ffffff;
  min-width: 240px;
  width: 340px;
  width: calc(33vw - 50px);
  min-height: 320px;
  max-height: 600px;
  max-height: calc(100vh - 96px);
  overflow-y: inherit;
}

.crm-hero {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 560px;
  padding: 24px;
}

.crm-empty-side-card-hint {
  padding: 10px 100px 30px;
}

.crm-email-preview--html,
.crm-email-preview--text,
.crm-email-preview--source {
  padding: 0 16px;
  margin-top: 8px;
  margin-top: 16px;
}

.crm-tabs .crm-tab {
  display: none;
}
.crm-tabs[data-active="0"] .crm-tab--0 {
  display: inherit;
}
.crm-tabs[data-active="1"] .crm-tab--1 {
  display: inherit;
}
.crm-tabs[data-active="2"] .crm-tab--2 {
  display: inherit;
}
.crm-tabs[data-active="3"] .crm-tab--3 {
  display: inherit;
}

.crm-email-preview--text {
  white-space: pre-wrap;
}

.crm-email-preview--source {
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 0.875rem;
}

.crm-fold .crm-foldable {
  display: none !important;
}

.crm-color-muted {
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.crm-color-grey {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-text-field--textarea .crm-template-textarea {
  font-size: 0.85rem;
  line-height: 1.4rem;
}

.crm-variant-variations {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.crm-variant-variations > .crm-variant-variation:last-child {
  margin-left: 12px;
}
.crm-variant-variations > .crm-variant-variation:first-child {
  margin-right: 12px;
}

.crm-variant-variation {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}

.crm-email-events dt {
  margin-top: 10px;
  margin-bottom: 4px;
}

.crm-email-event-detail {
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 400;
  padding-left: 16px;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.crm-uniform-color a {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  text-decoration: none;
}

.crm-clickable [data-path] {
  cursor: pointer;
}

.crm-border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.crm-issue-closed {
  background-color: #eee;
}

.crm-fab-action {
  background-color: #eee;
  color: #999;
}

.crm-attachment {
  padding: 4px 0;
}
.crm-attachment .material-icons {
  font-size: 20px;
}
.crm-attachment a {
  color: #37474f;
  text-decoration: none;
  margin: 0 4px;
}
.crm-attachment a[data-method=delete]:hover {
  background-color: #e8e8e8;
  border-radius: 2px;
}

input.crm-invisible {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 1px;
  margin: 0;
  padding: 0;
  opacity: 0;
  overflow: hidden;
}

.mdc-dialog .crm-response-preview-dialog {
  height: calc(100vh - 120px);
  width: 840px;
  margin-left: 40px;
  max-width: calc(100vw - 120px);
}
.mdc-dialog .crm-response-preview-dialog .mdc-dialog__content {
  height: calc(100% - 20px);
  overflow-y: auto;
  overflow-x: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.crm-outbox-list-editable {
  cursor: pointer;
}

.crm-outbox-list--email {
  max-width: 160px;
}

.crm-outbox-list--subject {
  max-width: 320px;
}

.crm-outbox-list--status {
  white-space: nowrap;
  max-width: 80px;
}

.crm-outbox-list--time {
  white-space: nowrap;
  max-width: 100px;
}

.crm-schedule-grid {
  display: grid;
  grid-template-columns: repeat(7, 42px);
  grid-template-rows: repeat(7, 50px);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.crm-schedule-date {
  justify-self: stretch;
  align-self: stretch;
  text-align: center;
}

.crm-schedule-date-header {
  align-self: end;
}

.crm-schedule-date-month {
  font-size: 0.75rem;
  font-weight: 400;
  opacity: 0;
}

.crm-schedule-date-month--show {
  opacity: 1;
}

.crm-schedule-date-btn {
  height: 100%;
  flex-direction: column;
  padding: 0;
  min-width: initial;
  width: 100%;
  text-transform: initial;
}
.crm-schedule-date-btn:hover .crm-schedule-date-month, .crm-schedule-date-btn:active .crm-schedule-date-month, .crm-schedule-date-btn:focus .crm-schedule-date-month {
  opacity: 1;
}

.mdc-button--unelevated .crm-schedule-date-month {
  opacity: 1;
}

.crm-schedule-date-1 {
  grid-column-start: 1;
}

.crm-schedule-date-2 {
  grid-column-start: 2;
}

.crm-schedule-date-3 {
  grid-column-start: 3;
}

.crm-schedule-date-4 {
  grid-column-start: 4;
}

.crm-schedule-date-5 {
  grid-column-start: 5;
}

.crm-schedule-date-6 {
  grid-column-start: 6;
}

.crm-schedule-date-7 {
  grid-column-start: 7;
}

@media screen and (max-width: 992px) {
  .crm-outbox-list--email {
    max-width: 80px;
  }
  .crm-outbox-list--subject {
    max-width: 160px;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.word-wrap {
  white-space: normal !important;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}

.overflow-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fixed {
  position: fixed;
}

.hidden {
  display: none !important;
}

.hide {
  display: none;
}

a.external:after {
  display: inline-block;
  text-decoration: none;
  margin-left: 2px;
  content: "*";
}

tr.inactive {
  color: #666;
}
tr.inactive a {
  color: #666;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.flex-grow {
  flex-grow: 1;
}

:root {
  --spacing-none: 0;
  --spacing-extra-small: 4px;
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 24px;
  --spacing-extra-large: 32px;
}

.pa0 {
  padding: var(--spacing-none);
}

.pa1 {
  padding: var(--spacing-extra-small);
}

.pa2 {
  padding: var(--spacing-small);
}

.pa3 {
  padding: var(--spacing-medium);
}

.pa4 {
  padding: var(--spacing-large);
}

.pa5 {
  padding: var(--spacing-extra-large);
}

.pl0 {
  padding-left: var(--spacing-none);
}

.pl1 {
  padding-left: var(--spacing-extra-small);
}

.pl2 {
  padding-left: var(--spacing-small);
}

.pl3 {
  padding-left: var(--spacing-medium);
}

.pl4 {
  padding-left: var(--spacing-large);
}

.pl5 {
  padding-left: var(--spacing-extra-large);
}

.pr0 {
  padding-right: var(--spacing-none);
}

.pr1 {
  padding-right: var(--spacing-extra-small);
}

.pr2 {
  padding-right: var(--spacing-small);
}

.pr3 {
  padding-right: var(--spacing-medium);
}

.pr4 {
  padding-right: var(--spacing-large);
}

.pr5 {
  padding-right: var(--spacing-extra-large);
}

.pb0 {
  padding-bottom: var(--spacing-none);
}

.pb1 {
  padding-bottom: var(--spacing-extra-small);
}

.pb2 {
  padding-bottom: var(--spacing-small);
}

.pb3 {
  padding-bottom: var(--spacing-medium);
}

.pb4 {
  padding-bottom: var(--spacing-large);
}

.pb5 {
  padding-bottom: var(--spacing-extra-large);
}

.pt0 {
  padding-top: var(--spacing-none);
}

.pt1 {
  padding-top: var(--spacing-extra-small);
}

.pt2 {
  padding-top: var(--spacing-small);
}

.pt3 {
  padding-top: var(--spacing-medium);
}

.pt4 {
  padding-top: var(--spacing-large);
}

.pt5 {
  padding-top: var(--spacing-extra-large);
}

.ph-m {
  padding-left: 12px;
  padding-right: 12px;
}

.ma0 {
  margin: var(--spacing-none);
}

.ma1 {
  margin: var(--spacing-extra-small);
}

.ma2 {
  margin: var(--spacing-small);
}

.ma3 {
  margin: var(--spacing-medium);
}

.ma4 {
  margin: var(--spacing-large);
}

.ma5 {
  margin: var(--spacing-extra-large);
}

.ml0 {
  margin-left: var(--spacing-none);
}

.ml1 {
  margin-left: var(--spacing-extra-small);
}

.ml2 {
  margin-left: var(--spacing-small);
}

.ml3 {
  margin-left: var(--spacing-medium);
}

.ml4 {
  margin-left: var(--spacing-large);
}

.ml5 {
  margin-left: var(--spacing-extra-large);
}

.mr0 {
  margin-right: var(--spacing-none);
}

.mr1 {
  margin-right: var(--spacing-extra-small);
}

.mr2 {
  margin-right: var(--spacing-small);
}

.mr3 {
  margin-right: var(--spacing-medium);
}

.mr4 {
  margin-right: var(--spacing-large);
}

.mr5 {
  margin-right: var(--spacing-extra-large);
}

.mb0 {
  margin-bottom: var(--spacing-none);
}

.mb1 {
  margin-bottom: var(--spacing-extra-small);
}

.mb2 {
  margin-bottom: var(--spacing-small);
}

.mb3 {
  margin-bottom: var(--spacing-medium);
}

.mb4 {
  margin-bottom: var(--spacing-large);
}

.mb5 {
  margin-bottom: var(--spacing-extra-large);
}

.mt0 {
  margin-top: var(--spacing-none);
}

.mt1 {
  margin-top: var(--spacing-extra-small);
}

.mt2 {
  margin-top: var(--spacing-small);
}

.mt3 {
  margin-top: var(--spacing-medium);
}

.mt4 {
  margin-top: var(--spacing-large);
}

.mt5 {
  margin-top: var(--spacing-extra-large);
}

.f1 {
  font-size: 3rem;
}

.f2 {
  font-size: 2.25rem;
}

.f3 {
  font-size: 1.5rem;
}

.f4 {
  font-size: 1.25rem;
}

.f5 {
  font-size: 1rem;
}

.f6 {
  font-size: 0.875rem;
}

.f7 {
  font-size: 0.75rem;
}

.fw3 {
  font-weight: 300;
}

.fw4 {
  font-weight: 400;
}

.fw5 {
  font-weight: 500;
}

.fw6 {
  font-weight: 600;
}

.tl {
  text-align: left;
}

.tr {
  text-align: right;
}

.tc {
  text-align: center;
}

.tj {
  text-align: justify;
}

.nowrap {
  white-space: nowrap;
}

.br1 {
  border-radius: 0.125rem;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33%;
}

.w-34 {
  width: 34%;
}

.w-40 {
  width: 50%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 60%;
}

.w-100 {
  width: 100%;
}

.w32 {
  width: 32px;
}

.w48 {
  width: 48px;
}

.w640 {
  width: 640px !important;
}

/* Height Scale */
.h1 {
  height: 1rem;
}

.h2 {
  height: 2rem;
}

.h3 {
  height: 4rem;
}

.h4 {
  height: 8rem;
}

.h5 {
  height: 16rem;
}

.flex-middle {
  height: 64px;
  padding: 0 8px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.flex-middle-slim {
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.flex-middle-spaced {
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-top-spaced {
  vertical-align: top;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-between;
}

.rotate-90 {
  transform: rotate(90deg);
}

.display-ib {
  display: inline-block;
}

.ttc {
  text-transform: capitalize;
}

.ttu {
  text-transform: uppercase;
}