.crm-tooltip {
  position: relative;
  display: inline-block;
}
.crm-tooltip-entry {
  padding-bottom: 6px;
}
.crm-tooltip-entry-name {
  font-weight: 500;
  padding-right: 4px;
}
.crm-tooltip-entry-name::after {
  content: ":";
}
.crm-tooltip .crm-tooltiptext {
  visibility: hidden;
  width: 280px;
  background-color: #f0f0f0;
  color: #202020;
  border-radius: 2px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -140px;
}

.crm-tooltip:hover .crm-tooltiptext {
  visibility: visible;
}
