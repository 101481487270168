$tm-frame-color: #D5D5D5;
$tm-default-color: #606060;
$tm-email-color: #3572B0;
$tm-survey-color: #C54034;
$tm-enquiry-color: #48AADF;
$tm-response-color: #67AB49;
// $tm-comment-color: #F6C342;
$tm-comment-color: #67AB49;
$tm-assigned-color: #E04A00;
$tm-closed-color: #67AB49;
$tm-opened-color: #C54034;

.timeline-vline {
  width: 10px;
  margin-top: 3px;
  margin-bottom: 8px;
  border-right-width: 4px;
  border-right-style: dotted;
  border-right-color: $tm-default-color;
}

.timeline-abbr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.timeline-abbr-line {
  flex-grow: 0.5;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.24);
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  height: 6px;
  cursor: pointer;
}
.timeline-abbr-circle {
  border: 1px solid rgba(0, 0, 0, 0.24);
  background-color: #EFEFEF;
  border-radius: 100%;
  color: #666;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  width: 32px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
}

.timeline-message-footer {
  border-top: 1px dotted #D5D5D5;
}

.timeline-email {
  .timeline-vline { border-right-color: $tm-email-color; }
  .timeline-icon { color: $tm-email-color; }
}

.timeline-survey {
  .timeline-vline { border-right-color: $tm-survey-color; }
  .timeline-icon { color: $tm-survey-color; }
}

.timeline-enquiry {
  .timeline-vline { border-right-color: $tm-enquiry-color; }
  .timeline-icon { color: $tm-enquiry-color; }
}

.timeline-response {
  .timeline-vline { border-right-color: $tm-response-color; }
  .timeline-icon { color: $tm-response-color; }
}

.timeline-comment {
  .timeline-vline { border-right-color: $tm-comment-color; }
  .timeline-icon { color: $tm-comment-color; }
}
.timeline-task {
  .timeline-vline { border-right-color: $tm-default-color; }
  .timeline-icon { color: $tm-default-color; }
}

.timeline-activity {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  &.source-closed {
    .timeline-icon { color: $tm-closed-color; }
  }
  &.source-opened {
    .timeline-icon { color: $tm-opened-color; }
  }
  &.source-assigned {
    .timeline-icon { color: $tm-assigned-color; }
  }
}

.timeline-actions {
  float: right;
  a {
    text-align: center;
    display: block;
    padding-bottom: 14px;
    color: $tm-default-color;
    cursor: pointer;
    cursor: hand;
    }
  a:hover {
    text-decoration:none;
    cursor: pointer;
    cursor: hand;
    }
}

.body-part-full {
  padding-top: 8px;
  min-height: 36px;
  .message-body {
    min-height: 30px;
    overflow: auto;
    display: inline;
    width: 100%;
  }
}

.body-part-excerpt {
  padding-top: 8px;
  min-height: 36px;
}

.shrink {
  .body-part-excerpt{
    display: block;
    color: $crm-color-grey;
  }
  .body-part-full {
    display: none;
  }
}

.expand {
  .body-part-excerpt{
    display: none;
  }
  .body-part-full {
    display: block;
  }
  .crm-message--head {
    cursor: pointer;
    cursor: hand;
  }
}

.expand-body {
  cursor: pointer;
  cursor: hand;
}

.message-body {
  img {
    max-width: 100% !important;
    overflow: auto;
    }
  pre {
    margin: 0;
    border:none;
    background:none;
    font-size: 0.8em;
    }
  blockquote {
    padding: 2px 0 2px 2px;
    margin: 2px 0 2px 3px;
    border-left: 2px solid #EEEEEE;
    }
  blockquote.auto-level-1 {
    padding: 5px 0 5px 8px;
    margin: 10px 0 10px 3px;
    border-left: 2px solid #909090;
    }
  blockquote.auto-level-2 {
    padding: 3px 0 3px 5px;
    margin: 5px 0 5px 3px;
    border-left: 2px solid #468847;
    }
  blockquote.auto-level-3 {
    padding: 0 0 0 5px;
    margin: 3px 0 3px 5px;
    border-left: 2px solid #3a87ad;
    }
  blockquote p {
    font-size: inherit;
    }
}

.crm-inbox--item {
  cursor: pointer;
  &:hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    background-color: #f8f8f8;
  }
}
.crm-inbox--item__support {
  padding: 16px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  &:hover { border-bottom: 1px solid transparent; }
}

.crm-message {
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;
}
.crm-message--main {
  max-width: calc(100% - 32px);
}
.crm-message--head {
  max-width: calc(100% - 180px);
}
.crm-log {
  .timeline-vline,
  .timeline-actions {
    display: none;
  }
}
.shrunk {
  display: none;
}
