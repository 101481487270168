ul {
  padding-left: 16px;
}

dl {
  margin: 0 0 16px;
}
dt {
  color: $crm-color-grey;
  font-size: 13px;
}
dd {
  margin: 0 0 16px;
}
dl.align {
  dt {
    float: left;
    clear: left;
    width: 60px;
    text-align: left;
    font-size: inherit;
  }
  dt::after {
    content: ":";
  }
  dd {
    margin: 0 0 0 70px;
    padding: 0 0 0.5em 0;
  }
}
dl.align-wide {
  dt { width: 140px; }
  dd { margin-left: 150px; }
}

dl.xs {
  margin: 0;
  dt {
    color: #666;
    width: 50px;
  }
  dd {
    margin: 0;
    padding: 0;
  }
}
