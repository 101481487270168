@use "@material/theme" with (
  // Blue Grey + Amber
  $primary: #37474f,
  $secondary: #ffc107,
  $on-primary: #fafafa,
  $on-secondary: #263238,
);


@use '@material/button';
@use '@material/card';
@use '@material/checkbox';
@use '@material/chips';
@use '@material/chips/chip';
@use '@material/data-table/data-table';
@use '@material/data-table/data-table-theme';
@use '@material/dialog';
@use '@material/fab';
@use '@material/floating-label/mdc-floating-label';
@use '@material/form-field';
@use '@material/line-ripple/mdc-line-ripple';
@use '@material/list';
@use '@material/list/mdc-list';
@use '@material/menu-surface/mdc-menu-surface';
@use '@material/menu/mdc-menu';
@use '@material/notched-outline/mdc-notched-outline';
@use '@material/select/styles';
@use '@material/textfield';
@use '@material/textfield/helper-text';
@use '@material/textfield/icon';

@include card.core-styles;
@include checkbox.core-styles;
@include chip.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;
@include dialog.core-styles;
@include fab.core-styles;
@include form-field.core-styles;
@include helper-text.helper-text-core-styles;
@include icon.icon-core-styles;
@include list.deprecated-core-styles;
@include textfield.core-styles;

@import '@material/button/mdc-button';
@import '@material/drawer/mdc-drawer';
@import '@material/elevation/mdc-elevation.scss';
@import '@material/icon-button/mdc-icon-button';
@import '@material/layout-grid/mdc-layout-grid';
@import '@material/linear-progress/mdc-linear-progress';
@import '@material/radio/mdc-radio';
@import '@material/ripple/mdc-ripple';
@import '@material/snackbar/mdc-snackbar';
@import '@material/tab-bar/mdc-tab-bar';
@import '@material/tab-indicator/mdc-tab-indicator';
@import '@material/tab-scroller/mdc-tab-scroller';
@import '@material/tab/mdc-tab';
@import '@material/theme/mixins';
@import '@material/top-app-bar/mdc-top-app-bar';
@import '@material/typography/mdc-typography';

.mdc-evolution-chip--dense {
  @include chips.density(-2);
  @include chips.height(24px);
}

.mdc-button--dense {
  @include button.density(-2);
  @include button.horizontal-padding(10px);
}
.mdc-text-field--dense {
  height: 30px;
}
.mdc-text-field--fullwidth {
  width: 100%;
  padding-left: 6px;
  padding-right: 6px;
}

.mdc-deprecated-list-item__graphic .material-icons {
  width: 24px;
}

.mdc-top-app-bar__section .mdc-button:disabled {
  color: rgba(255, 255, 255, .23);
}

input.mdc-text-field__input::-webkit-calendar-picker-indicator{
  display: initial;
}
input.mdc-text-field__input[type="date"]::-webkit-input-placeholder{
  visibility: initial;
}

.mdc-data-table__row-checkbox {
  @include checkbox.density(-2);
  @include checkbox.container-colors(#7b7b7b, #fafafa, #7b7b7b, #7b7b7b, false);
  @include checkbox.ink-color(#ffffff);
  @include checkbox.disabled-container-colors(#dedede, #fafafa, #dedede, #dedede);
  @include checkbox.disabled-ink-color(#b0b0b0);
}
.mdc-data-table__header-row-checkbox {
  @include checkbox.density(-2);
  @include checkbox.container-colors(#7b7b7b, #fafafa, #7b7b7b, #7b7b7b, false);
  @include checkbox.ink-color(#ffffff);
  @include checkbox.disabled-container-colors(#dedede, #fafafa, #dedede, #dedede);
  @include checkbox.disabled-ink-color(#b0b0b0);
}

.mdc-data-tabe--dense {
  @include data-table-theme.density(-2);
  @include data-table-theme.cell-padding(
    $leading-padding: 16px,
    $trailing-padding: 4px
  );
  .mdc-data-table__cell {
    font-size: small;
  }
}
.mdc-data-table__pagination-total {
  b, strong {
    font-weight: normal;
  }
}
